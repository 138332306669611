import type { Question, QuestionHierarchy } from "../../../../../../../interfaces/analysisInterfaces/analysisInterfaces"

export const returnUpdatedHierarchyQuestionsData = (questionsData: Question[], hierarchyItems?: QuestionHierarchy[] | null) => {
  const questionsDataCopy = [...questionsData]
  const data: Question[] = []
  questionsDataCopy.forEach(ques => {
    hierarchyItems?.forEach(el => {
      if (ques.title === el.id) {
        const updatedQues = { ...ques }
        updatedQues.content = el.editedText
        updatedQues.groups = el.rows ? el.rows : ques.groups
        updatedQues.subGroups = el.cols ? el.cols : ques.subGroups
        data.push(updatedQues)
      }
    })
  })
  return data
}
