import React, { useCallback, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

import initialShareFunctionalitiesValues from './initialShareFunctionalitiesValues';
import { ErrorMessage } from '../ErrorMessage/ErrorMessage';
import ShareProjectContent from './ShareProjectContent/ShareProjectContent';
import ShareProjectActionButtons from './ShareProjectActionButtons/ShareProjectActionButtons';
import { returnProjectSharingBody } from '../helpers/returnProjectSharingBody/returnProjectSharingBody';
import { fetchPostResOrJson as shareProject, fetchPatchResOrJson as updateShareProject } from '../../../services/services';

export default ({ onHide, shareDetails, projectId, reloadProject, user, projectSharingStep }) => {
  const { token } = useSelector((state) => state.tokenStateReducer);
  const dispatch = useDispatch()

  const [errorMessage, setErrorMessage] = useState('')
  const [isLoading, setIsLoading] = useState({ update: false, share: false })
  const [formData, setFormData] = useState({
    name: shareDetails.details.sharedByName ? shareDetails.details.sharedByName : user.customer,
    message: shareDetails.details.sharedByMessage ? shareDetails.details.sharedByMessage : '',
    radioButton: shareDetails.details.accessMode,
    checked: shareDetails.details.invitedUsersCanShareInternally,
    analysisTypes: shareDetails.details.shareFunctionalities ? shareDetails.details.shareFunctionalities.analysisTypes : initialShareFunctionalitiesValues.analysisTypes,
    analysisActions: shareDetails.details.shareFunctionalities ? shareDetails.details.shareFunctionalities.analysisActions : initialShareFunctionalitiesValues.analysisActions,
    projectActions: shareDetails.details.shareFunctionalities ? shareDetails.details.shareFunctionalities.projectActions : initialShareFunctionalitiesValues.projectActions
  })
  const { regularAccounts, subAccounts } = useSelector((theState) => theState.projectExternalShareStateReducer);

  useEffect(() => {
    dispatch({ type: 'UPDATE_USERS_TAB_SELECTED', payload: 0 })
    dispatch({ type: 'RESET_PROJECT_SHARING_ACCOUNTS' })
  }, [dispatch])

  const onChangeInputHandler = useCallback((e, inputType, functionalityType, functionalityId) => {
    const formDataCopy = { ...formData }
    if (inputType === 'radio') {
      formDataCopy.radioButton = e.value
    } else if (inputType === 'switch') {
      formDataCopy.checked = e.value
    } else if (inputType === 'name') {
      formDataCopy.name = e.target.value
    } else if (inputType === 'functionalitySwitch' && functionalityType && functionalityId) {
      formDataCopy[functionalityType] = { ...formDataCopy[functionalityType], [functionalityId]: e.value }
    } else {
      formDataCopy.message = e.html
    }
    setFormData(formDataCopy)
  }, [formData])

  const onShareHandler = (action) => {
    if (action === 'share') {
      const body = returnProjectSharingBody(formData, regularAccounts, subAccounts)
      setIsLoading({ ...isLoading, share: true })
      shareProject(`share/${projectId}/external-users`, token, body)
        .then(res => {
          if (res.status === 200) {
            dispatch({ type: 'UPDATE_INVALID_SHARED_USERS', payload: { invalidRegularUsers: [], invalidSubAccountUsers: [] } })
            setIsLoading({ ...isLoading, share: false })
            dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'The project has been shared successfully.' } })
            reloadProject()
            onHide()
          } else {
            setIsLoading({ ...isLoading, share: false })
            if (res.error || res.message) {
              setErrorMessage(res.error ? res.error : res.message)
              dispatch({ type: 'UPDATE_INVALID_SHARED_USERS', payload: { invalidRegularUsers: [], invalidSubAccountUsers: [] } })
            } else if (res.invalidRegularUsers || res.invalidSaUsers) {
              setErrorMessage('Some of the submitted users are invalid.')
              dispatch({ type: 'UPDATE_INVALID_SHARED_USERS', payload: { invalidRegularUsers: res.invalidRegularUsers, invalidSubAccountUsers: res.invalidSaUsers } })
              if (res.invalidRegularUsers.length > 0 && res.invalidSaUsers.length === 0) {
                dispatch({ type: 'UPDATE_USERS_TAB_SELECTED', payload: 1 })
              } else if (res.invalidRegularUsers.length === 0 && res.invalidSaUsers.length > 0) {
                dispatch({ type: 'UPDATE_USERS_TAB_SELECTED', payload: 0 })
              } else {
                dispatch({ type: 'UPDATE_USERS_TAB_SELECTED', payload: 0 })
              }
            }
          }
        })
    } else if (action === 'update') {
      setIsLoading({ ...isLoading, update: true })
      const body = returnProjectSharingBody(formData, regularAccounts, subAccounts)
      updateShareProject(`share/${projectId}/external-users`, token, body)
        .then(res => {
          if (res.status === 200) {
            dispatch({ type: 'UPDATE_INVALID_SHARED_USERS', payload: { invalidRegularUsers: [], invalidSubAccountUsers: [] } })
            setIsLoading({ ...isLoading, update: false })
            dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'The project has been updated successfully.' } })
            reloadProject()
            onHide()
          } else {
            setIsLoading({ ...isLoading, update: false })
            if (res.error || res.message) {
              dispatch({ type: 'UPDATE_INVALID_SHARED_USERS', payload: { invalidRegularUsers: [], invalidSubAccountUsers: [] } })
              setErrorMessage(res.error ? res.error : res.message)
            } else if (res.invalidRegularUsers || res.invalidSaUsers) {
              setErrorMessage('Some of the submitted users are invalid.')
              dispatch({ type: 'UPDATE_INVALID_SHARED_USERS', payload: { invalidRegularUsers: res.invalidRegularUsers, invalidSubAccountUsers: res.invalidSaUsers } })
              if (res.invalidRegularUsers.length > 0 && res.invalidSaUsers.length === 0) {
                dispatch({ type: 'UPDATE_USERS_TAB_SELECTED', payload: 1 })
              } else if (res.invalidRegularUsers.length === 0 && res.invalidSaUsers.length > 0) {
                dispatch({ type: 'UPDATE_USERS_TAB_SELECTED', payload: 0 })
              } else {
                dispatch({ type: 'UPDATE_USERS_TAB_SELECTED', payload: 0 })
              }
            }
          }
        })
    }
  }

  return (
    <Dialog
      width={800}
      onClose={onHide}
      contentStyle={{ padding: '0px' }}
      height={projectSharingStep === 'access' ? '60%' : '70%'}
      title={projectSharingStep === 'access' ? 'Setup sharing' : "Add users"}>
      <div className="mx-5">
        {
          errorMessage &&
          <ErrorMessage
            type="alert"
            errorMessage={errorMessage}
          />
        }
      </div>

      <ShareProjectContent
        formData={formData}
        projectSharingStep={projectSharingStep}
        shareDetails={shareDetails}
        reloadProject={reloadProject}
        isShared={shareDetails.isShared}
        onChangeInputHandler={onChangeInputHandler} />

      <DialogActionsBar>
        <ShareProjectActionButtons
          onHide={onHide}
          isLoading={isLoading}
          shareDetails={shareDetails}
          onShareHandler={onShareHandler} />
      </DialogActionsBar>
    </Dialog>
  )
}
