import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Splitter } from "@progress/kendo-react-layout";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { TreeView, processTreeViewItems } from "@progress/kendo-react-treeview";

import { Icon } from "../../../../../../../shared/Icon/Icon";
import { BaseDropDownList } from "../../../../../../../shared/Inputs";
import { useDebounce } from "../../../../../../../shared/customHooks/useDebounce";
import { FullHeightSpinner } from "../../../../../../../shared/FullHeightSpinner/FullHeightSpinner";
import { populateCodeMapData } from "../../../../helpers/populateCodeMapData/populateCodeMapData";
import { ExportCodeFrameModal } from "./CodingToolModals/ExportCodeFrameModal/ExportCodeFrameModal";
import { ImportCodeFrameModal } from "./CodingToolModals/ImportCodeFrameModal/ImportCodeFrameModal";
import fileManagerDownloadHandler from "../../../../../../../shared/helpers/fileManagerDownloadHandler/fileManagerDownloadHandler";
import { CodingModifyModal, CodingCategoryModal, DeletedAnswersModal, DeleteCategoryModal, SplitAnswerModal, PasteCategoriesWindow } from "./CodingToolModals/CodingToolModals";
import { addCustomDefinition, createCategory, deleteCategorisedAnswer, deleteCategory, deleteUncategorisedAnswer, editCategory, mergeCategories, moveAnswersToCategories, refreshCodeMap, reopenCategorisedAnswers, reopenCategory, sortAnswersHandler, sortCategoryHandler, splitAnswer } from "./CodingToolHelpers";
import type { RootState } from "../../../../../../../../store/reducers/rootReducer";
import { fetchGetBlob, fetchGetJson } from "../../../../../../../../services/services";

interface Props {
  handleEditTool: () => void
}

export const CodingToolTabContent = ({ handleEditTool }: Props) => {
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const dispatch = useDispatch();
  const [splitterPanes, setSplitterPanes] = useState([{ size: '40%', min: "30%", collapsible: false }, { min: "30%", collapsible: false },]);
  const { workflowData } = useSelector((theState: TODO) => theState.workflowStateReducer);
  const codeMapData = workflowData.selectedTool.selectedCodeMap ? workflowData.selectedTool.selectedCodeMap : { categories: [], rspAnswers: [], deletedAnswers: [], codedAnswers: [], filteredAnswers: [], countNumbers: { codedCount: 0, totalCount: 0, codedPercent: "0" } };
  const loadingState = workflowData.selectedTool.loadingState ? workflowData.selectedTool.loadingState : null
  const fetchData = { projectId: workflowData.data.projectId, workflowId: workflowData.data.id, toolId: workflowData.selectedTool.id };
  const [selectedCodeMap, setSelectedCodeMap] = useState({ id: "", text: "" });
  const [selectedAnswers, setSelectedAnswers] = useState<TODO[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<TODO[]>([]);
  const [moveToCategoryModal, setMoveToCategoryModal] = useState<{ show: boolean, catNumber: number[] }>({ show: false, catNumber: [] });
  const [modifyModal, setModifyModal] = useState({ show: false, type: "", editMode: false, existingValues: { code: "", text: "", itemId: "" } });
  const [showDeletedAnswersModal, setShowDeletedAnswersModal] = useState(false);
  const [deleteCategoryModal, setDeleteCategoryModal] = useState({ show: false, clickedCategory: { text: "", id: "" } });
  const [splitAnswerModal, setSplitAnswerModal] = useState({ show: false, text: "" });
  const [expandedCat, setExpandedCat] = useState({ ids: [""], idField: "id" });
  const [categorySorting, setCategorySorting] = useState({ sortBy: "", descending: false });
  const [answerSorting, setAnswerSorting] = useState({ sortBy: "", descending: false });
  const [searchAnswersValue, setSearchAnswersValue] = useState("");
  const [lastClicked, setLastClicked] = useState({ isCategory: false, index: 0 });
  const [keyboardShortcutActive, setKeyboardShortcutActive] = useState(true);
  const [pasteCategoriesWindow, setPasteCategoriesWindow] = useState(false);
  const [isDeletingCategory, setIsDeletingCategory] = useState(false);
  const [showExportCodeFrameModal, setShowExportCodeFrameModal] = useState(false);
  const [showImportCodeFrameModal, setShowImportCodeFrameModal] = useState(false);

  const openMoveToCategoryModal = useCallback((e: TODO) => {
    if (
      !Number.isNaN(Number.parseInt(e.key))
      && keyboardShortcutActive
      && selectedAnswers.length > 0
      && !moveToCategoryModal.show
      && !modifyModal.show
      && !showDeletedAnswersModal
      && !pasteCategoriesWindow
      && !splitAnswerModal.show
      && !showImportCodeFrameModal
      && !showExportCodeFrameModal
    ) {
      setMoveToCategoryModal({ show: true, catNumber: [Number.parseInt(e.key)] })
    }
  }, [showImportCodeFrameModal, showExportCodeFrameModal, moveToCategoryModal, keyboardShortcutActive, selectedAnswers, modifyModal, showDeletedAnswersModal, splitAnswerModal, pasteCategoriesWindow])

  useEffect(() => {
    document.addEventListener("keydown", openMoveToCategoryModal);
    return () => document.removeEventListener("keydown", openMoveToCategoryModal)
  }, [openMoveToCategoryModal])

  const selectCodeMapHandler = (event: TODO) => {
    setSelectedCodeMap(event.value);
    fetchGetJson(`projects/${fetchData.projectId}/workflows/${fetchData.workflowId}/tools/${fetchData.toolId}/code-maps/${event.value.id}`, token)
      .then((res: TODO) => {
        if (res.error || res.message) {
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: `An error has occured: ${res.error ? res.error : res.message}` } })
        } else {
          populateCodeMapData(res, setCategorySorting, setAnswerSorting, dispatch)
        }
        setSelectedCategories([]);
        setSelectedAnswers(["0"]);
        dispatch({ type: "SET_CODING_LOADING_STATE", payload: null })
      })
  }

  const onExpandHandler = (event: TODO) => {
    const ids = expandedCat.ids.slice();
    const index = ids.indexOf(event.item.id);
    if (index === -1) {
      ids.push(event.item.id);
    } else {
      ids.splice(index, 1);
    }
    setExpandedCat({ ids, idField: "id" });
  }

  const paneItemRenderer = (kendoEvent: TODO, isCategory: boolean) => {
    const item = kendoEvent.item;

    const reopenCategorizedHandler = (kendoIndex: string, answerData: { c: number, id: string, isCoded: boolean, t: string }, reopenFromAll: boolean) => {
      const [categoryIndex, _answerIndex] = kendoIndex.split("_")
      const clickedCategory = codeMapData.categories[categoryIndex]

      reopenCategorisedAnswers(
        { ...fetchData, codeMapId: codeMapData.id, categoryId: clickedCategory.id, answerId: answerData.id, token: token },
        codeMapData,
        // { categoryIndex: categoryIndex, answerIndex: answerIndex, answerData },
        dispatch, reopenFromAll)
    }

    const reopenCategoryHandler = (kendoIndex: string, _categoryData?: { c: number, id: string, isCoded: boolean, t: string }) => {
      const [categoryIndex] = kendoIndex.split("_")
      const clickedCategory = codeMapData.categories[categoryIndex]

      reopenCategory(
        { ...fetchData, codeMapId: codeMapData.id, categoryId: clickedCategory.id, token: token },
        codeMapData,
        // { categoryIndex: categoryIndex, categoryData },
        dispatch)
    }

    if (isCategory) {
      const isSubItem = item.hasOwnProperty("c")

      return <div className="d-flex h-100 align-items-center justify-content-between coding-right-item">
        <div className="d-flex align-items-center h-100 coding-right-container" title={item.text}>
          {
            isSubItem ? "" :
              <span
                className={`category h-100 mr-2 strong border-right medium ${item.type === 'Created' ? 'text-decoration-underline' : ''}`}>
                {item.code}
              </span>
          }
          <div className="item">
            <span className="d-flex align-items-center">
              <span className="title">{item.text} {isSubItem ? item.t : ""}</span>
              <span className="count ml-2 stronger">{`${isSubItem ? item.c : item.items ? item.items.length : 0}`}</span>
            </span>
          </div>
        </div>

        {!isSubItem ?
          <div className="catItem-options d-flex">
            <Tooltip openDelay={100} position='top' anchorElement={'target'}>
              <button type="button" onClick={() => reopenCategoryHandler(kendoEvent.itemHierarchicalIndex, item)} className="btn btn-transparent p-0" title="Reopen category"><Icon type="coding-reopen" className="pe-none" /></button>
            </Tooltip>
            <Tooltip openDelay={100} position='top' anchorElement={'target'}>
              <button type="button" onClick={() => setModifyModal({ show: true, type: "definition", editMode: false, existingValues: { code: "", text: "", itemId: item.id } })} className="btn btn-transparent p-0" title="Add definition"><Icon type="plus" className="pe-none" /></button>
            </Tooltip>
            <Tooltip openDelay={100} position='top' anchorElement={'target'}>
              <button type="button" onClick={() => setModifyModal({ show: true, type: "category", editMode: true, existingValues: { code: item.code, text: item.text, itemId: item.id } })} className="btn btn-transparent p-0" title="Edit"><Icon type="dashboard-edit" className="pe-none" /></button>
            </Tooltip>
            {item.type === "Created" && <Tooltip openDelay={100} position='auto' anchorElement={'target'}>
              <button type="button" onClick={() => setDeleteCategoryModal({ show: true, clickedCategory: item })} title="Delete category" className="btn btn-transparent p-0"><Icon type="delete-alt" className="pe-none" /></button>
            </Tooltip>}
          </div>
          :
          <div className="catItem-options d-flex">
            <Tooltip openDelay={100} position='bottom' anchorElement={'target'}>
              <button type="button" onClick={() => reopenCategorizedHandler(kendoEvent.itemHierarchicalIndex, item, false)} title="Reopen definition from this category" className="btn btn-transparent p-2">
                <i className="fas fa-lg fa-repeat-1 pe-none" />
              </button>
            </Tooltip>
            <Tooltip openDelay={100} position='bottom' anchorElement={'target'}>
              <button type="button" onClick={() => reopenCategorizedHandler(kendoEvent.itemHierarchicalIndex, item, true)} title="Reopen definition from all categories" className="btn btn-icon icon-b btn-transparent"><Icon type="coding-reopen" className="pe-none" /></button>
            </Tooltip>
            <Tooltip openDelay={100} position='auto' anchorElement={'target'}>
              <button
                type="button"
                onClick={() => {
                  const categoryId = codeMapData.categories[kendoEvent.itemHierarchicalIndex.split("_")[0]].id;
                  deleteCategorisedAnswer({ ...fetchData, codeMapId: codeMapData.id, categoryId: categoryId, answerId: item.id, token: token }, codeMapData, dispatch); setSelectedAnswers(["0"]);
                }}
                disabled={selectedAnswers.length !== 1}
                className="btn btn-icon icon-b btn-transparent">
                <Icon type="delete-alt" className="pe-none" />
              </button>
            </Tooltip>
          </div>}
      </div>
    }
    return <div className="coding-left-item">
      <div className="d-flex align-items-center coding-left-container" title={item.t}>
        <div className="item">
          <span className="d-flex align-items-center">
            <span className="title">{item.t}</span>
            <span className="count ml-2 stronger">{item.c}</span>
          </span>
        </div>
      </div>
    </div>
  }

  const paneItemSelectionHandler = (event: any, isCategory: boolean, action: string) => {
    let updatedData = !isCategory ? [...selectedAnswers] : [...selectedCategories];
    window.getSelection()?.removeAllRanges();

    switch (action) {
      case "all":
        if (!isCategory) {
          updatedData = codeMapData.filteredAnswers.map((_filteredAnswer: { id: string; t: string, c: number }, index: number) => index.toString())
        } else {
          updatedData = codeMapData.categories.map((_category: { id: string; code: number; oaToInc: []; text: string; type: string }, index: number) => index.toString())
        }
        break;
      case "clear":
        updatedData = []
        break;
      case "single":
        setLastClicked({ isCategory: isCategory, index: event.itemHierarchicalIndex })

        if (event.nativeEvent.shiftKey) {
          if (isCategory === lastClicked.isCategory && !event.itemHierarchicalIndex.includes("_")) {
            const range = [event.itemHierarchicalIndex, lastClicked.index]
            range.sort((a, b) => a - b)
            if (isCategory) {
              updatedData = codeMapData.categories.map((index: number) => index.toString()).filter((index: number) => index >= range[0] && index <= range[1] && index.toString())
            } else {
              const newSelection = codeMapData.filteredAnswers.map((index: number) => index.toString()).filter((index: number) => index >= range[0] && index <= range[1] && index.toString())
              const originalAnswers = [...selectedAnswers]
              originalAnswers.push(...newSelection)
              const sanitizedAnswers = Array.from(new Set(originalAnswers))
              updatedData = sanitizedAnswers
            }
          }
        } else if (event.nativeEvent.ctrlKey || (window.navigator.userAgent.indexOf("Mac") !== -1 && event.nativeEvent.metaKey)) {
          const itemIndex = updatedData.indexOf(event.itemHierarchicalIndex)
          if (itemIndex >= 0) {
            updatedData.splice(itemIndex, 1)
          } else {
            updatedData.push(event.itemHierarchicalIndex)
          }
        } else {
          updatedData = [event.itemHierarchicalIndex]
        }

        break;
      default:
        break;
    }

    if (!isCategory) {
      setSelectedAnswers(updatedData)
    } else {
      setSelectedCategories(updatedData)
    }
  }

  const moveToCategoryHandler = (value: number[]) => {
    if (selectedAnswers.length > 0) {
      const body: { textIds: string[], categoryCodes: number[] } = { textIds: [], categoryCodes: value }

      codeMapData.filteredAnswers.forEach((verbatim: TODO, index: number) => {
        if (selectedAnswers.includes(index.toString())) {
          body.textIds.push(verbatim.id)
        }
      })

      moveAnswersToCategories({ ...fetchData, codeMapId: codeMapData.id, token: token }, body, codeMapData, dispatch)
      setMoveToCategoryModal({ show: false, catNumber: value });
      setSelectedAnswers(["0"]);
    } else {
      dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: "Please select at least one verbatim" } })
    }
  }

  const modifyModalConfirmHandler = (values: { code?: string, text?: string }, keepModalOpen?: boolean) => {
    if (!modifyModal.editMode) {
      if (modifyModal.type === "category" && values.code && values.text) {
        if (keepModalOpen) {
          createCategory(values.code, values.text, { ...fetchData, codeMapId: codeMapData.id, token: token }, codeMapData, categorySorting, setCategorySorting, () => setModifyModal({ ...modifyModal, show: true }), dispatch);
        } else {
          createCategory(values.code, values.text, { ...fetchData, codeMapId: codeMapData.id, token: token }, codeMapData, categorySorting, setCategorySorting, () => setModifyModal({ ...modifyModal, show: false }), dispatch);
        }
      } else if (modifyModal.type === "merge" && values.code && values.text) {
        mergeCategories(values.code, values.text, { ...fetchData, codeMapId: codeMapData.id, token: token }, token, dispatch) // Needs backend, gonna be done in future implementation
      } else if (modifyModal.type === "definition" && values.text) {
        const body = values.text.split("\n")
        addCustomDefinition(body, { ...fetchData, codeMapId: codeMapData.id, categoryId: modifyModal.existingValues.itemId, token: token }, () => setModifyModal({ ...modifyModal, show: false }), codeMapData, dispatch);
      }
    } else {
      if (modifyModal.type === "category" && values.code && values.text) {
        editCategory(
          values.code,
          values.text,
          codeMapData,
          { ...fetchData, codeMapId: codeMapData.id, categoryId: modifyModal.existingValues.itemId, token: token },
          categorySorting,
          setCategorySorting,
          () => setModifyModal({ show: false, type: "", editMode: false, existingValues: { code: "", text: "", itemId: "", } }),
          dispatch,
        );
      }
    }
  }

  const onSortClick = (sortType: string, isCategory: boolean, sortDir: boolean) => {
    if (!isCategory) {
      sortAnswersHandler(sortType, codeMapData, answerSorting, setAnswerSorting, setSelectedAnswers, dispatch, sortDir, { ...fetchData, codeMapId: codeMapData.id, token: token });
    } else {
      dispatch({ type: "SET_CODING_LOADING_STATE", payload: "categories" })
      sortCategoryHandler({ ...fetchData, codeMapId: codeMapData.id, token: token }, sortType, codeMapData, categorySorting, setCategorySorting, setSelectedCategories, dispatch, sortDir);
    }
  }

  const questionItemRender = (li: TODO, itemProps: TODO) => {
    const [quesLabel, ...quesText] = itemProps.dataItem.text.split(":")

    const itemChildren = (
      <div className="d-flex w-100 align-items-center p-1">
        <span className="strong">{quesLabel}</span>
        <span>:</span>
        <span className="ml-1">{quesText}</span>
      </div>
    );
    return React.cloneElement(li, li.props, itemChildren);
  }

  const questionValueRender = (element: TODO, dataItem: { id: string, text: string }) => {
    const [_quesLabel, ...quesText] = dataItem.text.split(":")

    if (!dataItem) {
      return element;
    }
    const children = [
      <div className={`d-flex flex-column ${quesText?.length > 0 ? 'py-1' : 'py-4'} gap-sm`} key={dataItem.id}>
        <p className="m-0 font-weight-bold  ">{quesText?.length > 0 ? quesText : 'Select question'}</p>
        {quesText?.length > 0 && selectedCodeMap.id &&
          <div className="d-flex w-100 k-badges">
            <Fragment>
              <span className="badge badge-primary border-primary mr-1">{selectedCodeMap.text.split(":")[0]}</span>
              <span className="badge badge-white border mr-1">{`${codeMapData.countNumbers.codedCount}/${codeMapData.countNumbers.totalCount}`}</span>
              <span className="badge badge-white border">{`${codeMapData.countNumbers.codedPercent}% (${codeMapData.countNumbers.totalCount - codeMapData.countNumbers.codedCount})`}</span>
            </Fragment>
          </div>
        }
      </div>
    ];
    return React.cloneElement(element, { ...element.props, }, children);
  };

  const debouncedSearchAnswers = useDebounce(() => {
    if (!searchAnswersValue) {
      dispatch({ type: "RESET_FILTERED_ANSWERS" });
      setSelectedAnswers(["0"])
    } else {
      const searchResults = [...codeMapData.rspAnswers].filter((el: { t: string }) => el.t.toLowerCase().includes(searchAnswersValue.toLowerCase()))
      const updatedCodeMapData = { ...codeMapData }
      updatedCodeMapData.filteredAnswers = searchResults
      dispatch({ type: "UPDATE_SELECTED_CODEMAP", payload: updatedCodeMapData });
      setSelectedAnswers(["0"])
    }
  }, 1000)

  const exportAnswers = () => {
    fetchGetBlob(`projects/${fetchData.projectId}/workflows/${fetchData.workflowId}/tools/${fetchData.toolId}/code-maps/${codeMapData.id}/export`, token)
      .then((res: TODO) => {
        if (res && (res.error || res.message)) {
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: `Export error: ${res.error ? res.error : res.message}` } })
        } else {
          const updatedToolName = workflowData.selectedTool.name.split(' ').join('_');
          const QID = codeMapData.text.split(":")[0].trim();
          fileManagerDownloadHandler(res, { displayName: `${updatedToolName}_${QID}.xlsx` })
        }
      })
  }

  return (
    <div className="workflow-tool-coding d-flex flex-column h-100 overflow-hidden">
      <section className="workflow-tool-header h-48 border-bottom d-flex flex-shrink-0 align-items-center justify-content-between">
        <h6 className="mb-0 strong mr-2">Coding tool</h6>
        <button type="button" className="btn btn-icon icon-l btn-transparent" onClick={handleEditTool}><Icon type="dashboard-edit" className="mr-1" />Edit tool</button>
      </section>
      {!workflowData.selectedTool.input.datasetId ?
        <div className="h-100 d-flex flex-column align-items-center justify-content-center empty">
          <Icon type="coding" className="fill-muted" />
          <span className="droppable-text h4 strong">Add dataset to use coding tool</span>
        </div>
        :
        <section className="d-flex flex-column h-100" onMouseEnter={() => setKeyboardShortcutActive(true)} onMouseLeave={() => setKeyboardShortcutActive(false)}>
          {
            showImportCodeFrameModal &&
            <ImportCodeFrameModal
              onHide={() => setShowImportCodeFrameModal(false)}
              fetchData={{ ...fetchData, codeMapId: codeMapData.id }}
              setCategorySorting={setCategorySorting}
              setAnswerSorting={setAnswerSorting}
            />
          }
          {
            showExportCodeFrameModal &&
            <ExportCodeFrameModal
              onHide={() => setShowExportCodeFrameModal(false)}
              fetchData={{ ...fetchData, codeMapId: codeMapData.id }}
            />
          }
          {
            pasteCategoriesWindow &&
            <PasteCategoriesWindow
              codeMapData={codeMapData}
              fetchData={{ ...fetchData, codeMapId: codeMapData.id }}
              onHide={() => setPasteCategoriesWindow(false)}
            />
          }
          {modifyModal.show &&
            <CodingModifyModal
              type={modifyModal.type}
              editMode={modifyModal.editMode}
              existingValues={modifyModal.existingValues}
              onHide={() => setModifyModal({ show: false, type: "", editMode: false, existingValues: { code: "", text: "", itemId: "", } })}
              onConfirm={(e, keepModalOpen) => modifyModalConfirmHandler(e, keepModalOpen)}
              dispatch={dispatch} />
          }
          {moveToCategoryModal.show &&
            <CodingCategoryModal
              onHide={() => setMoveToCategoryModal({ show: false, catNumber: [] })}
              onConfirm={(value) => { moveToCategoryHandler(value); setSelectedAnswers(["0"]) }}
              codeMapData={codeMapData} />
          }
          {showDeletedAnswersModal &&
            <DeletedAnswersModal
              codeMapData={codeMapData}
              fetchData={{ ...fetchData, codeMapId: codeMapData.id }}
              onHide={() => setShowDeletedAnswersModal(false)}
              token={token}
              dispatch={dispatch} />
          }
          {deleteCategoryModal.show &&
            <DeleteCategoryModal
              isDeletingCategory={isDeletingCategory}
              onHide={() => setDeleteCategoryModal({ show: false, clickedCategory: { text: "", id: "" } })}
              onConfirm={() => deleteCategory(
                { ...fetchData, codeMapId: codeMapData.id, categoryId: deleteCategoryModal.clickedCategory.id, token: token },
                () => setDeleteCategoryModal({ show: false, clickedCategory: { text: "", id: "" } }),
                codeMapData,
                dispatch,
                (val: boolean) => setIsDeletingCategory(val)
              )}
              categoryName={deleteCategoryModal.clickedCategory.text} />
          }
          {splitAnswerModal.show &&
            <SplitAnswerModal
              onHide={() => setSplitAnswerModal({ show: false, text: "" })}
              onConfirm={modalValues => splitAnswer({ ...fetchData, codeMapId: codeMapData.id, answerId: codeMapData.filteredAnswers[selectedAnswers[0]].id, token: token },
                modalValues,
                codeMapData,
                () => setSplitAnswerModal({ show: false, text: "" }),
                dispatch)}
              value={splitAnswerModal.text} />
          }
          <div className="p-3 border-bottom">
            <BaseDropDownList
              data={workflowData.selectedTool.codeMaps}
              textField="text"
              value={selectedCodeMap}
              itemRender={questionItemRender}
              valueRender={questionValueRender}
              className="bg-white border shadow-sm"
              onChange={(e: TODO) => { dispatch({ type: "SET_CODING_LOADING_STATE", payload: "both" }); selectCodeMapHandler(e) }}
            />
          </div>
          <Splitter panes={splitterPanes} onChange={(e: TODO) => setSplitterPanes(e.newState)} style={{ border: "none", height: "100%" }}>
            <div className="pane-content d-flex flex-column h-100">
              {/* Left Pane */}
              <div className="pane-header pr-3">
                <span className="medium">Verbatims</span>
                <div className="pane-toolbar d-flex flex-wrap justify-content-between">
                  <div className="d-flex flex-wrap align-items-center">
                    {/* Commented buttons are going to be implemented in future updates */}
                    {/* <button onClick={e =>{}} className="btn-transparent"><Icon type="coding-filter" /></button> */}
                    {/* <button onClick={e =>{}} className="btn-transparent"><Icon type="download-02" /></button>  */}
                    <Tooltip openDelay={100} position='bottom' anchorElement={'target'}>
                      <button type="button" onClick={() => refreshCodeMap({ ...fetchData, codeMapId: codeMapData.id, token: token }, codeMapData, selectedCodeMap, selectCodeMapHandler, dispatch)} disabled={loadingState} title="Refresh coding tool" className="btn btn-icon icon-b btn-transparent">
                        <Icon type="coding-refresh" className="pe-none" />
                      </button>
                    </Tooltip>
                    <Tooltip openDelay={100} position='bottom' anchorElement={'target'}>
                      <button type="button" onClick={() => exportAnswers()} disabled={loadingState || !selectedCodeMap.id} title="Export answers" className="btn btn-icon icon-b btn-transparent">
                        <Icon type="export" className="pe-none" />
                      </button>
                    </Tooltip>
                    <Tooltip openDelay={100} position='bottom' anchorElement={'target'}>
                      <button type="button" title="Split answer" onClick={() => setSplitAnswerModal({ show: true, text: codeMapData.filteredAnswers[selectedAnswers[0]].t })} disabled={selectedAnswers.length !== 1 || codeMapData.filteredAnswers[selectedAnswers[0]]?.splitId} className="btn btn-icon icon-b btn-transparent">
                        <Icon type="split-answer" className="pe-none" />
                      </button>
                    </Tooltip>
                    <Tooltip openDelay={100} position='bottom' anchorElement={'target'}>
                      <button type="button" title="Delete answer"
                        onClick={() => {
                          deleteUncategorisedAnswer({ ...fetchData, codeMapId: codeMapData.id, answerId: codeMapData.filteredAnswers[selectedAnswers[0]].id, token: token }, codeMapData, dispatch);
                          setSelectedAnswers(["0"]);
                        }}
                        disabled={selectedAnswers.length !== 1}
                        className="btn btn-icon icon-b btn-transparent">
                        <Icon type="delete-alt" className="pe-none" />
                      </button>
                    </Tooltip>
                    <Tooltip openDelay={100} position='bottom' anchorElement={'target'}>
                      <button type="button" title="Select all" onClick={e => paneItemSelectionHandler(e, false, "all")} disabled={!selectedCodeMap.id} className="btn btn-icon icon-b btn-transparent"><Icon type="coding-select-all" className="pe-none" /></button>
                    </Tooltip>
                    <Tooltip openDelay={100} position='bottom' anchorElement={'target'}>
                      <button type="button" title="Clear all" onClick={e => paneItemSelectionHandler(e, false, "clear")} disabled={!selectedCodeMap.id} className="btn btn-icon icon-b btn-transparent"><Icon type="coding-clear-all" className="pe-none" /></button>
                    </Tooltip>
                  </div>
                </div>
              </div>

              <article className="d-flex flex-column overflow-auto h-100 justify-content-between">
                {/* Left Pane main */}
                {!selectedCodeMap.id ?
                  <div className="d-flex h-100 w-100 justify-content-center align-items-center text-gray strong">No question selected</div>
                  :
                  <div className="d-flex flex-column h-100">
                    <div className="coding-left-main simple-scrollbar m-3 py-3 h-100">
                      <div className="d-flex flex-column px-3 text-muted mb-2">
                        <span className="strong medium mb-3">Open text</span>
                        <input
                          className="form-control bg-white search-input"
                          placeholder="Search"
                          value={searchAnswersValue}
                          onChange={e => { setSearchAnswersValue(e.target.value); debouncedSearchAnswers() }}
                        />
                      </div>
                      <div className="d-flex align-items-center px-3 mb-2">
                        <button onClick={() => onSortClick("t", false, !answerSorting.descending)} type="button" className="btn btn-light mr-2">
                          <span>Answer text</span>
                          {
                            answerSorting.sortBy === 't' &&
                            <i className={`fas ${answerSorting.descending ? 'fa-caret-down' : 'fa-caret-up'} ml-2`} />
                          }
                        </button>
                        <button onClick={() => onSortClick("c", false, !answerSorting.descending)} type="button" className="btn btn-light">
                          <span>Answer count</span>
                          {
                            answerSorting.sortBy === 'c' &&
                            <i className={`fas ${answerSorting.descending ? 'fa-caret-down' : 'fa-caret-up'} ml-2`} />
                          }
                        </button>
                      </div>
                      {loadingState === "verbatims" || loadingState === "both" ?
                        <FullHeightSpinner /> :
                        <div className="coding-left-pane h-100 px-3">
                          <TreeView
                            data={processTreeViewItems(codeMapData.filteredAnswers, { select: selectedAnswers })}
                            checkboxes={false}
                            item={e => paneItemRenderer(e, false)}
                            onItemClick={(e: TODO) => paneItemSelectionHandler(e, false, "single")}
                            expandIcons={false}
                          />
                        </div>}
                    </div>
                    <div className="coding-left-progress progress flex-shrink-0 mx-3 mb-3">
                      {selectedCodeMap.id &&
                        //@ts-ignore
                        <div className="progress-bar" role="progressbar"
                          style={{ width: `${codeMapData.countNumbers.codedPercent}%` }}
                          aria-valuenow={codeMapData.countNumbers.codedPercent} aria-valuemin="0" aria-valuemax="100">
                          {codeMapData.countNumbers.codedPercent}%
                        </div>}
                    </div>
                  </div>}
              </article>
            </div>

            <div className="pane-content d-flex flex-column h-100">
              {/* Right Pane */}
              <div className="d-flex flex-column overflow-hidden h-100">
                <div className="pane-header pr-3 flex-shrink-0"><span className="medium">Code frame</span>
                  <div className="pane-toolbar d-flex flex-wrap flex-shrink-0 justify-content-between">
                    <div className="d-flex flex-wrap align-items-center">
                      <Tooltip openDelay={100} position='bottom' anchorElement={'target'}>
                        <button type="button" onClick={() => {
                          const highestCode = codeMapData.categories.map((el: { code: string }) => el.code).sort((a: number, b: number) => a - b)
                          setModifyModal({ show: true, type: "category", editMode: false, existingValues: { code: highestCode[highestCode.length - 1] + 1, text: "", itemId: "", } })
                        }} title="Add category" disabled={!selectedCodeMap.id} className="btn btn-transparent btn-icon icon-b"><Icon type="add-l" className="pe-none" /></button>
                      </Tooltip>
                      <Tooltip openDelay={100} position='bottom' anchorElement={'target'}>
                        <button type="button" disabled={!selectedCodeMap.id} onClick={() => setPasteCategoriesWindow(true)} title="Paste categories" className="btn btn-transparent btn-icon icon-b">
                          <Icon type="dashboard-edit" className="pe-none" />
                        </button>
                      </Tooltip>
                      <Tooltip openDelay={100} position='bottom' anchorElement={'target'}>
                        <button type="button" onClick={() => setShowImportCodeFrameModal(true)} title="Import code frame" disabled={!selectedCodeMap.id} className="btn btn-transparent btn-icon icon-b">
                          <Icon type="export" className="pe-none" />
                        </button>
                      </Tooltip>
                      <Tooltip openDelay={100} position='bottom' anchorElement={'target'}>
                        <button type="button" onClick={() => setShowExportCodeFrameModal(true)} title="Export code frame" disabled={!selectedCodeMap.id} className="btn btn-transparent btn-icon icon-b">
                          <Icon type="coding-upload" className="pe-none" />
                        </button>
                      </Tooltip>
                      {/* Thees will be implmeneted in future, need backend support */}
                      {/* <button onClick={e => setShowModifyModal({ show: true, type: "merge", editMode: false, existingValues: { code: "", text: "", itemId: "", } })} className="btn-transparent"><Icon type="coding-merge" /></button> */}
                      {/* <button onClick={e => {}} className="btn-transparent"><Icon type="coding-upload" /></button> */}
                      {/* <button onClick={e => {}} className="btn-transparent"><Icon type="survey-xml" /></button>  */}
                      <Tooltip openDelay={100} position='bottom' anchorElement={'target'}>
                        <button type="button" title="Select all" onClick={e => paneItemSelectionHandler(e, true, "all")} disabled={!selectedCodeMap.id} className="btn btn-icon icon-b btn-transparent"><Icon type="coding-select-all" className="pe-none" /></button>
                      </Tooltip>
                      <Tooltip openDelay={100} position='auto' anchorElement={'target'}>
                        <button type="button" title="Clear all" onClick={e => paneItemSelectionHandler(e, true, "clear")} disabled={!selectedCodeMap.id} className="btn btn-icon icon-b btn-transparent"><Icon type="coding-clear-all" className="pe-none" /></button>
                      </Tooltip>
                    </div >
                  </div >
                </div>

                <article className="d-flex flex-column overflow-auto h-100">
                  {/* Right Pane main */}
                  {!selectedCodeMap.id ?
                    <div className="d-flex h-100 w-100 justify-content-center align-items-center text-gray strong">No question selected</div>
                    :
                    <div className="coding-right-main simple-scrollbar m-3 py-3 h-100">
                      <div className="d-flex px-3 text-muted mb-2">
                        <span className="strong medium">Coded answers</span>
                      </div>
                      <div className="d-flex align-items-center px-3 mb-2">
                        <button onClick={() => onSortClick("code", true, !categorySorting.descending)} type="button" className="btn btn-light mr-2">
                          <span>Category code</span>
                          {
                            categorySorting.sortBy === 'code' &&
                            <i className={`fas ${categorySorting.descending ? 'fa-caret-down' : 'fa-caret-up'} ml-2`} />
                          }
                        </button>
                        <button onClick={() => onSortClick("text", true, !categorySorting.descending)} type="button" className="btn btn-light mr-2">
                          <span>Category name</span>
                          {
                            categorySorting.sortBy === 'text' &&
                            <i className={`fas ${categorySorting.descending ? 'fa-caret-down' : 'fa-caret-up'} ml-2`} />
                          }
                        </button>
                        <button onClick={() => onSortClick("oaToInc", true, !categorySorting.descending)} type="button" className="btn btn-light mr-2">
                          <span>Number of answers</span>
                          {
                            categorySorting.sortBy === 'oaToInc' &&
                            <i className={`fas ${categorySorting.descending ? 'fa-caret-down' : 'fa-caret-up'} ml-2`} />
                          }
                        </button>
                      </div>
                      {loadingState === "categories" || loadingState === "both" ?
                        <FullHeightSpinner /> :
                        <div className={`coding-right-pane h-100 ${codeMapData.deletedAnswers.length > 0 ? "border-bottom border-secondary" : ""}`}>
                          <TreeView
                            data={processTreeViewItems(codeMapData.categories, { select: selectedCategories, expand: expandedCat })}
                            checkboxes={false}
                            item={e => paneItemRenderer(e, true)}
                            onItemClick={(e: TODO) => paneItemSelectionHandler(e, true, "single")}
                            expandIcons={true}
                            onExpandChange={e => onExpandHandler(e)}
                          />
                        </div>}
                      {codeMapData.deletedAnswers.length > 0 &&
                        <button type="button" onClick={() => setShowDeletedAnswersModal(true)} className="btn btn-transparent btn-icon icon-l deleted-answers text-danger ml-3 mt-3">
                          <Icon type="delete-alt" className="pe-none fill-danger mr-1" /> {`Deleted answers (${codeMapData.deletedAnswers.length})`}
                        </button>
                      }
                    </div>}
                </article>
              </div>
            </div >
          </Splitter >
        </section >}
    </div >
  )
}
