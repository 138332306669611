import iconList from './iconList';
import customQuestionsIconList from './customQuestionsIconList';
import { returnIconSize } from './returnIconSize/returnIconSize';
import type { IconObject, IconProps } from '../../../interfaces/iconInterfaces/iconInterfaces';

const icons: IconObject = { ...iconList, ...customQuestionsIconList }

export const Icon = ({ type, fill, size, className, style }: IconProps) => {
  const iconSize = returnIconSize(icons[type], size)

  return (
    <svg role='img' width={iconSize.width} style={style} height={iconSize.height} aria-label={type} className={className} fill={fill} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d={icons[type]?.id} fill={fill} />
    </svg>
  )
}
