import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from '@progress/kendo-react-tooltip';

import { Icon } from '../../../../../../shared/Icon/Icon';
import { DropdownButton } from '../../../../../../shared/DropdownButton/DropdownButton';
import { SurveySubquestionsTabs } from '../SurveySubquestionsTabs/SurveySubquestionsTabs';
import { InProgressOverlay } from '../../../../../../shared/InProgressOverlay/InProgressOverlay';
import { ValidationErrorMessage } from '../../../helpers/ValidationErrorMesage/ValidationErrorMessage';
import { prepareLanguagesDropDown } from '../../helpers';

export const EditTabQuesElementContent = ({
  selectedLanguage, onDropdownItemClick, onLanguageChange, onSaveChanges,
  onDragEnd, onAddElement, handleAction, onAdvancedEdit, onShowScript,
}) => {
  const { token } = useSelector((state) => state.tokenStateReducer);
  const dispatch = useDispatch()
  const { theData, selectedElement } = useSelector((theState) => (theState.surveyInitialDataReducer));
  const languageList = useMemo(() => prepareLanguagesDropDown(theData.originalData?.languages), [theData.originalData.languages]);

  let headerDropdownActionItems = []
  let editTabToolbarItems = []
  if (theData?.selectedItem) {
    editTabToolbarItems = [
      { text: 'Paste pad', icon: "survey-paste-pad" },
      { text: 'Insert JavaScript', icon: "survey-js" },
      { text: 'XML Scripting', icon: "survey-xml" },
      { text: 'Insert CSS', icon: "survey-css" },
      { text: 'Edit tags', icon: "tags" },
      // ...returnElementActionData(theData.selectedItem) //this only returns a Delete item, which is not needed for now
    ]
    headerDropdownActionItems = [
      { text: theData.showLogic ? 'Turn logic off' : 'Turn logic on', icon: "survey-logic" },
      { text: theData.htmlMode ? 'Turn rich text on' : 'Turn rich text off', icon: "script" },
      { text: 'Add container text', disabled: theData.selectedItem.elements.find(el => el.type === 'qtext') ? true : false, icon: "help-article" },
    ]

  }

  const onChangeSubquestion = (e) => {
    dispatch({ type: "SURVEY_SET_SUBQUESTION_INDEX", payload: e })
    if (selectedElement?.type === 'subq') {
      dispatch({ type: 'SET_SELECTED_ELEMENT', payload: theData.selectedItem.elements.filter(el => el.type === 'subq')[e] })
    } else {
      dispatch({ type: 'SET_SELECTED_ELEMENT', payload: theData.selectedItem })
    }
  }

  return (
    <div style={{ backgroundColor: '#f3f4f4' }} className="card w-100 h-100" title={theData.selectedItem.disabled ? "This element is disabled" : undefined}>
      {theData.loadingMessage && theData.loadingState &&
        <InProgressOverlay type="overlay" theme="primary" message={theData.loadingMessage} />
      }
      <div className="card-header d-flex flex-column pr-3 pl-4 h-48 rounded-0">
        <div className="d-flex justify-content-between h-100">
          <div className="d-flex align-items-center">
            <div className="d-flex align-items-center survey-toolbar">
              {editTabToolbarItems.map((actionItem, index) => {
                return (
                  <Tooltip anchorElement="target" position="top" key={index}>
                    <button type='button' className="btn btn-transparent p-05 mr-2" title={actionItem.text} onClick={() => onDropdownItemClick({ item: actionItem })}>
                      <Icon type={actionItem.icon} className='pe-none' />
                    </button>
                  </Tooltip>
                )
              })}
              <div className='d-flex h-24 w-1 bg-light mr-2' />
              <DropdownButton
                className="p-05 btn-transparent"
                hideChevron={true}
                menuPosition="left"
                icon="more-horizontal"
                onItemClick={onDropdownItemClick}
                items={headerDropdownActionItems} />
            </div>
            <div className="d-flex align-items-center flex-wrap">
              {theData.selectedItem.tags?.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1).map((el, key) =>
                <small className="badge-tags mr-1" key={key}>{el.name}</small>
              )}
            </div>
          </div>
          <div className="d-flex align-items-center">
            <DropdownButton
              icon="globe"
              textField="label"
              className="icon-l btn-transparent"
              hideChevron={true}
              text={`${selectedLanguage?.name ?? ''} (${selectedLanguage?.code})`}
              items={languageList}
              onItemClick={(e) => onLanguageChange(e)}
            />
          </div>
        </div>
      </div>
      <div className={`card-body d-flex flex-column h-100 overflow-auto m-4 p-0 bg-white answer-layout ${theData.selectedItem.disabled ? "blur" : ""}`}>
        {theData.selectedItem.errorMessage !== "" &&
          <ValidationErrorMessage errorMessage={theData.selectedItem.errorMessage} />
        }
        {/* Question intro text (not in use for now)  */}
        {/* {theData.selectedItem.elements.filter(el => el.type === 'qtext').length !== 0 ?
          <div className={`d-flex p-4 w-100 align-items-end border-bottom ${theData.selectedItem.disabled && "blur"}`}>
            <div className="d-flex w-100">
              <div className="d-flex mb-1 align-items-center">
                {theData.optionsType && (theData.optionsType.type === 'qtext' || theData.optionsType.parentType === 'qtext') ?
                  <div ref={activeRef} className="edit-dot pulse mr-2" /> : null}
                {theData.selectedItem.elements.find(el => el.type === 'qtext').errorMessage !== "" &&
                  <ValidationErrorMessage errorMessage={theData.selectedItem.elements.find(el => el.type === 'qtext').errorMessage} />}
              </div>
              <div className={`d-flex inline-editor w-100 ${theData.selectedItem.disabled && "inline-disabled-editor"}`}>
                <InlineElementEditor
                  token={token}
                  disabled={theData.selectedItem.disabled}
                  content={theData.selectedItem.elements.find(el => el.type === 'qtext').elements.find(el => el.type === 'text').textValues[theData.editingLanguage]}
                  type='qtext'
                  index={0}
                  subQuesIndex={0}
                  onEditInline={onEditInline}
                  onInlineChange={onInlineChange}
                  editingElement={editingElement}
                  onUpdateElementTextData={onUpdateElementTextData} />
              </div>
            </div>
          </div> : null} */}
        <SurveySubquestionsTabs
          token={token}
          selectedItem={theData.selectedItem}
          theData={theData}
          selectedSubquestion={theData.selectedSubquestion}
          onChangeSubquestion={(e) => onChangeSubquestion(e)}
          onDragEnd={onDragEnd}
          onAddElement={onAddElement}
          handleAction={handleAction}
          onAdvancedEdit={onAdvancedEdit}
          editingLanguage={theData.editingLanguage}
          showLogic={theData.showLogic}
          onShowScript={onShowScript}
          onSaveChanges={onSaveChanges}
        />
      </div>
    </div>
  )
}