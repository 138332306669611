import { returnUpdatedHeaders } from "../../components/shared/helpers/returnUpdatedHeaders";
import { apiSurveyURL, subscriptionKey } from "../services";

export const getSpecificDirectoryListing = <T>(url: string, token: string, blobName: string): Promise<T> => {
  try {
    const updatedUrl = new URL(apiSurveyURL + url)
    updatedUrl.search = new URLSearchParams({ dir: blobName }).toString();

    return fetch(updatedUrl, {
      method: 'GET',
      headers: returnUpdatedHeaders(token, subscriptionKey)
    })
      .then(response => response.json())
      .then(data => {
        return data
      })
      .catch(error => {
        return error;
      })
  }
  catch (error) {
    return Promise.reject(error);
  }
}

export const fileManagerDownloadFile = async <T>(url: string, token: string, blobName: string): Promise<T> => {
  try {
    const updatedUrl = new URL(apiSurveyURL + url);
    updatedUrl.search = new URLSearchParams({ blobName: blobName }).toString();

    return fetch(updatedUrl, {
      method: 'GET',
      headers: returnUpdatedHeaders(token, subscriptionKey, 'application/octet-stream')
    })
      .then(response => {
        if (response.status === 200) {
          return response.blob()
        } else {
          return response.json()
        }
      })
      .catch(error => {
        return error;
      })
  }
  catch (error) {
    return Promise.reject(error);
  }
}
