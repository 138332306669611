import { useState } from 'react';
import Editor from "@monaco-editor/react";
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

import { Icon } from '../../../../shared/Icon/Icon';
import FileManager from '../../../../shared/FileManager/FileManager';
import type { RootState } from '../../../../../store/reducers/rootReducer';
import type { IProps, ISubQuestonElement, IShowFileManager, IRootState, EditorOptions } from '../../../../../interfaces/surveyCSSInterfaces/surveyCSSInterfaces';

const editorOptions: EditorOptions = {
  folding: false,
  glyphMargin: false,
  lineNumbers: 'off',
  overviewRulerLanes: 0,
  overviewRulerBorder: false,
  minimap: { enabled: false, },
  hideCursorInOverviewRuler: true,
}

export const SurveyCSS = ({ handleClose }: IProps) => {
  const dispatch = useDispatch()
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const { theData } = useSelector((theState: IRootState) => theState.surveyInitialDataReducer)
  const [selectedItem, setSelectedItem] = useState(JSON.parse(JSON.stringify(theData.selectedItem)))
  const [selectedSubquestion, setSelectedSubquestion] = useState<number>(0)
  const [showFileManager, setShowFileManager] = useState<IShowFileManager>({ show: false, setting: null });
  const [enableActionButtons, setEnableActionButtons] = useState(false);
  const [editorTheme, setEditorTheme] = useState<"vs-dark" | "vs-light">("vs-dark");

  const onUpdateStyle = (shouldCloseModal: boolean) => {
    dispatch({ type: 'SAVE_QUESTION' })
    dispatch({ type: 'ON_EXPAND_SUBQUESTION', payload: selectedItem })
    if (shouldCloseModal) {
      handleClose();
    }
    dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'CSS has been saved successfully' } })
  }

  const setStyle = (value: string | undefined) => {
    const updatedSelectedItem = JSON.parse(JSON.stringify(selectedItem))
    if (selectedItem.type === 'info' || selectedItem.type === 'quotastop') {
      updatedSelectedItem.style = value
    } else {
      updatedSelectedItem.elements.filter((el: ISubQuestonElement) => el.type === 'subq')[selectedSubquestion].style = value
    }
    setSelectedItem(updatedSelectedItem)
  }

  const returnStyleValue = () => {
    let value = '';
    if (selectedItem.type === 'info' || selectedItem.type === 'quotastop') {
      value = selectedItem.style || '';
    } else {
      value = selectedItem.elements.filter((el: ISubQuestonElement) => el.type === 'subq')[selectedSubquestion].style || '';
    }
    return value
  }

  const onSelectFromFileManager = (value: string) => {
    selectedItem.attributes.css = value
  }

  const handleChange = (value: string) => {
    const updatedElement = JSON.parse(JSON.stringify(theData.selectedItem))
    updatedElement.attributes.css = value
    setSelectedItem(updatedElement)
  }

  const removeCSSReference = () => {
    const updatedElement = JSON.parse(JSON.stringify(theData.selectedItem))
    updatedElement.attributes.css = ""
    setSelectedItem(updatedElement);
    if (!enableActionButtons) {
      setEnableActionButtons(true);
    }
  }

  const changeTheme = () => {
    if (editorTheme === "vs-light") {
      setEditorTheme("vs-dark");
    } else {
      setEditorTheme("vs-light");
    }
  }

  return (
    <Dialog title={"Insert CSS"} onClose={handleClose} height="90%" width="90%">
      {
        showFileManager.show &&
        <FileManager
          displayType="modal"
          type={'public'}
          onSelect={(value) => {
            onSelectFromFileManager(value);
            if (!enableActionButtons) {
              setEnableActionButtons(true)
            }
          }}
          onHide={() => setShowFileManager({ show: false, setting: null })}
          token={token}
        />
      }
      <div className="d-flex flex-column overflow-hidden h-100">
        <div className={`form-group p-3 m-0 ${selectedItem.elements.filter((el: ISubQuestonElement) => el.type === 'subq').length > 1 ? 'border-bottom' : ''}`}>
          <div className="input-group">
            <input
              name='css'
              type="text"
              className="form-control h-32"
              placeholder="Extra CSS reference"
              value={selectedItem.attributes.css || ''}
              onChange={(e) => {
                handleChange(e.target.value);
                if (!enableActionButtons) {
                  setEnableActionButtons(true)
                }
              }}
            />
            <div className="input-group-append">
              <button className="btn btn-outline-secondary p-1" type="button" onClick={() => setShowFileManager({ show: true, setting: 'css' })}>
                <Icon type="add" />
              </button>
              <button className="btn btn-outline-secondary p-1" type="button" onClick={removeCSSReference}>
                <Icon type="delete-alt" />
              </button>
            </div>
          </div>
        </div>
        {
          selectedItem.elements.filter((el: ISubQuestonElement) => el.type === 'subq').length > 1 &&
          <div>
            <div className="list-group list-group-horizontal">
              {
                selectedItem.elements.filter((el: ISubQuestonElement) => el.type === 'subq').map((el: { id: string }, key: number) =>
                  <button
                    key={el.id}
                    type="button"
                    style={{ whiteSpace: 'nowrap', outline: 'none' }}
                    onClick={() => setSelectedSubquestion(key)}
                    className={`w-auto list-group-item list-group-item-action border-0 p-3 rounded-0 ${selectedSubquestion === key ? 'bg-lightest' : ''}`}>
                    Question {key + 1}
                  </button>
                )
              }
            </div>
          </div>
        }
        <div className="d-flex flex-column w-100 h-100">
          <Editor
            defaultLanguage='css'
            theme={editorTheme}
            options={editorOptions}
            onChange={(value) => {
              setStyle(value);
              if (!enableActionButtons) {
                setEnableActionButtons(true)
              }
            }}
            value={returnStyleValue()}
          />
        </div>
      </div>
      <DialogActionsBar>
        <div className='d-flex justify-content-between align-items-center w-100'>
          <button type='button' className="btn btn-transparent btn-icon icon-l" onClick={changeTheme}>
            <Icon type="editor-theme" className="pe-none mr-1" />
            Theme
          </button>
          <div className='d-flex'>
            <button type='button' className="k-button btn-secondary" onClick={handleClose}>Close</button>
            <button disabled={!enableActionButtons} type='button' className="k-button btn-primary" onClick={() => onUpdateStyle(false)}>Save</button>
            <button disabled={!enableActionButtons} type='button' className="k-button btn-primary" onClick={() => onUpdateStyle(true)}>Save & Close</button>
          </div>
        </div>
      </DialogActionsBar>
    </Dialog>
  )
}
