import React, { useEffect, useState } from 'react';
import Editor from '@monaco-editor/react';
import { Button } from '@progress/kendo-react-buttons';
import { useDispatch, useSelector } from 'react-redux';
import { Input, Switch } from '@progress/kendo-react-inputs';

import { BaseDropDownList } from "../../../../shared/Inputs";
import type { RootState } from '../../../../../store/reducers/rootReducer';
import { useFetchEmailProviders } from '../../../../shared/customHooks/useFetchEmailProviders';
import type { EmailProvider } from '../../../../../interfaces/emailProviderInterfaces/emailProviderInterfaces';
import { fetchGetJson as getInputData, fetchPatch as updateEmailTemplate, fetchPostResOrJson as testEmailTemplate } from '../../../../../services/services';

interface InputData {
  isEnabled: boolean;
  emailProviderId: string;
  fromEmailAddress: string;
  fromEmailName: string;
  subject: string;
}

export const SharingProjectTabContent = () => {
  const dispatch = useDispatch();
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const [emailProviders, setEmailProviders] = useState<EmailProvider[]>([]);
  const [emailProviderNames, setEmailProviderNames] = useState<string[]>([]);
  const [selectedEmailProvider, setSelectedEmailProvider] = useState<string>("");
  const [inputData, setInputData] = useState<InputData>({
    isEnabled: false,
    emailProviderId: "",
    fromEmailAddress: "",
    fromEmailName: "",
    subject: ""
  });
  const [editorValue, setEditorValue] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isTesting, setIsTesting] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  useFetchEmailProviders((res: EmailProvider[]) => {
    setEmailProviders(res);
    const emailsArray = res.map((item) => item.name);
    setEmailProviderNames(["Select email provider", ...emailsArray]);
  });

  useEffect(() => {
    getInputData("customisations/projecttemplate", token)
      .then((res: TODO) => {
        if (res && !res.statusCode) {
          const newInputData = { ...res }
          setInputData({
            isEnabled: newInputData.isEnabled,
            emailProviderId: newInputData.emailProviderId,
            fromEmailAddress: newInputData.fromEmailAddress,
            fromEmailName: newInputData.fromEmailName,
            subject: newInputData.subject
          })
          setEditorValue(newInputData.message)
        } else {
          if (res.error || res.message) {
            dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error || res.message } })
          }
        }
      })
  }, [token, dispatch])

  useEffect(() => {
    if (inputData.emailProviderId) {
      const [emailProvider] = emailProviders.filter((provider) => provider.id === inputData.emailProviderId);

      if (emailProvider) {
        setSelectedEmailProvider(emailProvider.name);
      }
    } else {
      setSelectedEmailProvider("Select email provider");
    }
  }, [emailProviders, inputData.emailProviderId])

  const onChangeHandler = (e: TODO) => {
    if (e.target && e.target.name === "emailProviderId") {
      let value = "";

      if (e.target.value !== "Select email provider") {
        value = emailProviders.filter((provider) => provider.name === e.value)[0].id;
        setSelectedEmailProvider(e.value)
      } else {
        setSelectedEmailProvider("Select email provider");
      }
      setInputData({
        ...inputData,
        [e.target.name]: value
      })
    } else {
      setInputData({
        ...inputData,
        [e.target.name]: e.target.value
      })
    }
  }

  const handleEditorChange = (value: TODO) => {
    setEditorValue(value)
  }

  const handleTest = () => {
    const body = {
      emailProviderId: inputData.emailProviderId,
      fromEmailAddress: inputData.fromEmailAddress,
      fromEmailName: inputData.fromEmailName,
      isEnabled: inputData.isEnabled,
      subject: inputData.subject,
      message: editorValue,
    }
    setIsTesting(true);
    testEmailTemplate("customisations/projecttemplate/test", token, body)
      .then((res: TODO) => {
        setIsTesting(false);
        if (res.error || res.message) {
          setErrorMessage(res.error || res.message)
        } else {
          setErrorMessage("")
          if (res.status === 202) {
            dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'Test email template has been sent' } })
          }
        }
      })
  }

  const handleSave = () => {
    const body = { ...inputData, message: editorValue };
    setIsSaving(true);
    updateEmailTemplate("customisations/projecttemplate", token, body)
      .then((res: TODO) => {
        setIsSaving(false);
        if (res.ok && res.status === 200) {
          dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'The email template has been saved successfully' } })
        } else return res.json();
      })
      .then((res: TODO) => {
        if (res.error || res.message) {
          setErrorMessage(res.error || res.message)
        }
      })
  }

  const itemRender = (li: TODO) => {
    const itemChildren = <span className={li.props.children === "Select email provider" ? "strong" : ""}>{li.props.children}</span>;
    return React.cloneElement(li, li.props, itemChildren);
  };

  return (
    <div className="container-fluid py-5">
      <div className="row">
        <div className="col-md-12 col-lg-4 mb-3">
          <div className="d-flex flex-column gap-lg">
            <div className="d-flex align-items-center">
              <span className="switch-button-text flex-grow-1">Enable Template</span>
              <Switch
                onChange={onChangeHandler}
                checked={inputData.isEnabled}
                name="isEnabled"
                required
                size="small"
              />
            </div>
            <p className="text-muted mb-0">This template will be used when sharing a project externally</p>
            <BaseDropDownList
              className="w-100"
              name="emailProviderId"
              data={emailProviderNames}
              defaultValue={"Select email provider"}
              itemRender={itemRender}
              value={selectedEmailProvider}
              onChange={onChangeHandler}
            />
            <Input type="text" className="w-100" placeholder="From email address" name="fromEmailAddress" value={inputData.fromEmailAddress} onChange={onChangeHandler} />
            <Input type="text" className="w-100" placeholder="From email name" name="fromEmailName" value={inputData.fromEmailName} onChange={onChangeHandler} />
            <Input type="text" className="w-100" placeholder="Subject" name="subject" value={inputData.subject} onChange={onChangeHandler} />
            {
              errorMessage &&
              <div className="alert alert-danger shadow-sm" role="alert">
                {errorMessage}
              </div>
            }
          </div>
        </div>
        <div className="col-md-12 col-lg-8">
          <div className="d-flex flex-column gap-lg">
            <p className="m-0 text-muted">Subject and Message can contain &#91;firstname&#93;, &#91;lastname&#93; and &#91;email&#93; for substitution.</p>
            <p className="m-0">Message</p>
            <Editor
              theme="vs-dark"
              height="46vh"
              defaultLanguage="html"
              value={editorValue}
              onChange={handleEditorChange}
            />
            <div className="d-flex justify-content-between gap-lg">
              <Button disabled={isTesting} className="btn btn-secondary " onClick={handleTest}>Test</Button>
              <Button disabled={isSaving} className="btn btn-primary" onClick={handleSave}>Save</Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
