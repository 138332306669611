import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Dialog } from '@progress/kendo-react-dialogs';

import tabsData from './importTabsData';
import { QuickImport } from '../QuickImport/QuickImport';
import usePrevious from '../customHooks/usePrevious';
import { InProgressOverlay } from '../InProgressOverlay/InProgressOverlay';
import { AdvancedImport } from '../../Analysis/Import/components/shared/AdvancedImport/AdvancedImport';

export const ImportModal = ({ onUpdateData, projectDetails }) => {
  const { token } = useSelector((state) => state.tokenStateReducer);
  const dispatch = useDispatch()
  const { isQuickImportModalShown, quickImportStatusData } = useSelector(state => state.quickImportStatusReducer);
  const { isAdvancedImportModalShown, advancedImportStatusData } = useSelector(state => state.advancedImportStatusReducer);
  const [dataMetaFile, setDataMetaFile] = useState(null)
  const [caseFile, setCaseFile] = useState(null)
  const [selectedTab, setSelectedTab] = useState(0)
  const [formData, setFormData] = useState({ name: '', description: '', fileType: tabsData[selectedTab].title })
  const [showCollapse, setShowCollapse] = useState(false)
  const [automaticMergeOptions, setAutomaticMergeOptions] = useState({ createGridsAutomatically: false, createMultiplesAutomatically: false, maxUniqueValuesForSingleChoice: 20 })

  const prevIsQuickImportModalShown = usePrevious(isQuickImportModalShown)
  const prevIsAdvancedImportModalShown = usePrevious(isAdvancedImportModalShown)

  useEffect(() => {
    if (isQuickImportModalShown && isQuickImportModalShown !== prevIsQuickImportModalShown && prevIsAdvancedImportModalShown !== true) {
      if (caseFile || dataMetaFile || formData.name || formData.description) {
        setCaseFile(null)
        setDataMetaFile(null)
        setFormData({ ...formData, name: '', description: '' })
      }
    }
  }, [isQuickImportModalShown, prevIsQuickImportModalShown, caseFile, dataMetaFile, formData, prevIsAdvancedImportModalShown])

  const onAdvancedImportOptionsChangeHandler = (e) => {
    if (e.target.name === "maxUniqueValuesForSingleChoice") {
      setAutomaticMergeOptions({
        ...automaticMergeOptions,
        [e.target.name]: Number.parseInt(e.target.value)
      })
    } else {
      setAutomaticMergeOptions({
        ...automaticMergeOptions,
        [e.target.name]: !e.target.props.checked
      })
    }
  }

  const onCloseModal = () => {
    dispatch({ type: 'DISPLAY_ADVANCED_IMPORT_MODAL', payload: false })
    dispatch({ type: 'DISPLAY_QUICK_IMPORT_MODAL', payload: false })
  }

  const onUpdateCollapse = () => {
    setShowCollapse(!showCollapse)
  }

  if (isQuickImportModalShown || isAdvancedImportModalShown) {
    return (
      <Dialog minWidth={600} title={"Select file to import"} onClose={onCloseModal} className="import-dialog">
        {
          quickImportStatusData.isInProgress || advancedImportStatusData.isInProgress ?
            <InProgressOverlay
              type="overlay"
              theme="primary"
              message={`Your dataset is currently ${quickImportStatusData.isInProgress ? quickImportStatusData.progressStatus : advancedImportStatusData.progressStatus}`} />
            : null
        }
        {
          isQuickImportModalShown &&
          <QuickImport
            tabsData={tabsData}
            caseFile={caseFile}
            formData={formData}
            updateData={onUpdateData}
            selectedTab={selectedTab}
            dataMetaFile={dataMetaFile}
            showCollapse={showCollapse}
            projectDocuments={projectDetails.documents}
            projectDetails={projectDetails.details}
            automaticMergeOptions={automaticMergeOptions}
            isAdvancedImportModalShown={isAdvancedImportModalShown}
            setCaseFile={setCaseFile}
            setFormData={setFormData}
            setSelectedTab={setSelectedTab}
            setDataMetaFile={setDataMetaFile}
            onUpdateCollapse={onUpdateCollapse}
            onAdvancedImportOptionsChangeHandler={onAdvancedImportOptionsChangeHandler}
            onHide={() => { dispatch({ type: 'DISPLAY_QUICK_IMPORT_MODAL', payload: false }) }}
          />
        }
        {
          isAdvancedImportModalShown &&
          <AdvancedImport
            tabsData={tabsData}
            caseFile={caseFile}
            formData={formData}
            updateData={onUpdateData}
            selectedTab={selectedTab}
            dataMetaFile={dataMetaFile}
            showCollapse={showCollapse}
            name={projectDetails.details.name}
            projectdetails={projectDetails.details}
            projectDocuments={projectDetails.documents}
            automaticMergeOptions={automaticMergeOptions}
            isAdvancedImportModalShown={isAdvancedImportModalShown}
            setCaseFile={setCaseFile}
            setFormData={setFormData}
            setSelectedTab={setSelectedTab}
            setDataMetaFile={setDataMetaFile}
            onUpdateCollapse={onUpdateCollapse}
            onAdvancedImportOptionsChangeHandler={onAdvancedImportOptionsChangeHandler}
            onHide={() => { dispatch({ type: 'DISPLAY_ADVANCED_IMPORT_MODAL', payload: false }) }}
          />
        }
      </Dialog >
    )
  }
  return null
}
