const initialShareFunctionalitiesValues = {
  analysisTypes: {
    topline: false,
    crossTable: false,
    chart: false,
    dig: false,
    qTable: false,
    data: false,
    script: false,
    report: false,
  },
  analysisActions: {
    exportToWord: false,
    exportToExcel: false,
    exportToPpt: false,
    createWeightSet: false,
    createQuestions: false,
  },
  projectActions: {
    exportDataset: false,
    deleteDataset: false,
    editDataset: false,
  },
};

export default initialShareFunctionalitiesValues;
