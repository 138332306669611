import type React from "react";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "@progress/kendo-react-tooltip";

import { Icon } from "../../shared/Icon/Icon";
import { Breadcrumb } from "../../shared/Breadcrumb/Breadcrumb";
import FolderIcon from "../../../assets/img/library-folder.svg"
import DropdownMenu from "../../shared/DropdownMenu/DropdownMenu";
import { SimpleSwitch } from "../../shared/SimpleSwitch/SimpleSwitch";
import { useDebounce } from "../../shared/customHooks/useDebounce";
import { PinboardAppMenuItems, returnMenuPages } from "../pinboardHelpers";
import type { Pinboard, PinboardPage } from "../../../interfaces/pinboardInterfaces/pinboardInterfaces";
import { returnTableIcon } from "../../shared/helpers/project/returnTableIcon/returnTableIcon";

import { fetchDelete, fetchGetJson, fetchPostJson } from "../../../services/services";
import { FullHeightSpinner } from "../../shared/FullHeightSpinner/FullHeightSpinner";
import type { RootState } from "../../../store/reducers/rootReducer";

interface AppNavBarProps {

  pinboardData: Pinboard
  showAppMenu: boolean
  setShowAppMenu: (value: TODO) => void
  setShowDeletePinboardModal: (value: boolean) => void
  loadingPinboard: boolean
  setShowEditPinboardModal: (value: boolean) => void
}

export const PinboardAppNavBar = ({ pinboardData, showAppMenu, setShowAppMenu, setShowDeletePinboardModal, loadingPinboard, setShowEditPinboardModal, }: AppNavBarProps) => {
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const dispatch = useDispatch();
  const history = useHistory();
  const params: { name: string } = useParams();
  const currentProjectId = params.name;
  const recentData = useSelector((theState: RootState) => (theState.recentDataReducer))
  const [isPublishing, setIsPublishing] = useState(false);
  const shareUrl = `http://${window.location.href.split("/")[2]}/public/pinboards/${pinboardData.publishData?.path}`

  useEffect(() => {
    // If the user hasn't opened the Homepage before opening Analysis, recentData will be empty
    // This useEffect calls backend for recentData.recentProjects if that's the case
    const recentProjectsIndex = PinboardAppMenuItems.findIndex((el: TODO) => el.title === "Recent projects")
    if (PinboardAppMenuItems[recentProjectsIndex].subItems.length < 1) {
      if (recentData.projectsLoaded === false) {
        fetchGetJson("sta/statistics/recentlymodifiedprojects", token)
          .then((res: TODO) => {
            if (res && !res.error) {
              dispatch({ type: "SET_RECENT_PROJECTS_DATA", payload: res })
              if (res.length > 0) {
                res.forEach((item: TODO) => {
                  if (PinboardAppMenuItems[recentProjectsIndex].subItems.length < 5) {
                    const updatedItem = {
                      title: item.name,
                      action: "recentProjectSubitem",
                      originalData: { ...item },
                      icon: <img src={FolderIcon} className="mr-1" style={{ transform: "translate(-3px,-3px)" }} alt="Folder icon" />,
                    }
                    PinboardAppMenuItems[recentProjectsIndex].subItems.push(updatedItem)
                  }
                })
              } else {
                PinboardAppMenuItems[recentProjectsIndex].subItems.push({ title: "No data found", action: "none" })
              }
            }
          })
      } else {
        recentData.recentProjects.forEach((item: TODO) => {
          if (PinboardAppMenuItems[recentProjectsIndex].subItems.length < 5) {
            const updatedItem = {
              title: item.name,
              action: "recentProjectSubitem",
              originalData: { ...item },
              icon: <img src={FolderIcon} className="mr-1" style={{ transform: "translate(-3px,-3px)" }} alt="Folder icon" />,
            }
            PinboardAppMenuItems[recentProjectsIndex].subItems.push(updatedItem)
          }
        })
      }
    }
  }, [token, dispatch, recentData.projectsLoaded, recentData.recentProjects])

  useEffect(() => {
    // Same as above but for recentData.recentDocuments
    const recentActivityIndex = PinboardAppMenuItems.findIndex((el: TODO) => el.title === "Recent activity")
    if (PinboardAppMenuItems[recentActivityIndex].subItems.length < 1) {
      if (recentData.documentsLoaded === false) {
        fetchGetJson("sta/statistics/recentlymodifiedprojectitems", token)
          .then((res: TODO) => {
            if (res && !res.error) {
              dispatch({ type: "SET_RECENT_DOCUMENTS_DATA", payload: res })
              if (res.length > 0) {
                if (PinboardAppMenuItems[recentActivityIndex].subItems.length < 5) {
                  res.forEach((item: TODO) => {
                    const updatedItem = {
                      title: item.name,
                      action: "recentActivitySubitem",
                      originalData: { ...item },
                      icon: <i className={`${returnTableIcon(item.type + "s")} mr-2`} style={{ width: "18px" }} />,
                    }
                    PinboardAppMenuItems[recentActivityIndex].subItems.push(updatedItem)
                  })
                }
              } else {
                PinboardAppMenuItems[recentActivityIndex].subItems.push({ title: "No data found", action: "none" })
              }
            }
          })
      } else {
        recentData.recentDocuments.forEach((item: TODO) => {
          if (PinboardAppMenuItems[recentActivityIndex].subItems.length < 5) {
            const updatedItem = {
              title: item.name,
              action: "recentActivitySubitem",
              originalData: { ...item },
              icon: <i className={`${returnTableIcon(item.type + "s")} mr-2`} style={{ width: "18px" }} />,
            }
            PinboardAppMenuItems[recentActivityIndex].subItems.push(updatedItem)
          }
        })
      }
    }
  }, [token, dispatch, recentData.documentsLoaded, recentData.recentDocuments])

  const appMenuItemClick = (item: TODO) => {
    switch (item.action) {
      case "goToProject":
        history.push({ pathname: `/${history.location.pathname.split("/")[1]}/${currentProjectId}` })
        break;
      case "goToHome":
        history.push({ pathname: "/" })
        break;
      case "delete":
        setShowDeletePinboardModal(true)
        break;
      case "editPinboard":
        setShowEditPinboardModal(true)
        break;
      case "recentProjectSubitem":
        history.push({ pathname: `/projects/${item.originalData.id}` });
        break;
      case "recentActivitySubitem":
        if (item.originalData.type === "dataset") {
          window.open(`/projects/${item.originalData.projectId}/${item.originalData.id}/analyze/${item.originalData.type}s`, "_self")
        } else if (item.originalData.type === "survey") {
          window.open(`/projects/${item.originalData.projectId}/${item.originalData.id}/survey-builder`, "_self")
        } else if (item.originalData.type === "sample") {
          window.open(`/projects/${item.originalData.projectId}/${item.originalData.id}/panel`, "_self")
        } else if (item.originalData.type === "workflow") {
          window.open(`/projects/${item.originalData.projectId}/${item.originalData.id}/workflow`, "_self")
        } else if (item.originalData.type === "pinboard") {
          window.open(`/projects/${item.originalData.projectId}/${item.originalData.id}/pinboards`, "_self")
        } else {
          window.open(`/projects/${item.originalData.id}`, "_self");
        }
        break;
      default: {
        dispatch({ type: "SHOW_ERROR_NOTIFICATION", payload: { msg: `${item.title} needs to be implemented` } });
        break;
      }
    }
    setShowAppMenu(false)
  }

  const unpublishPinboard = useDebounce(() => {
    fetchDelete(`pb/projects/${pinboardData.projectId}/pinboards/${pinboardData.pinboardId}/shareLink`, token)
      .then((response: TODO) => {
        if (response.status === 200) {
          setIsPublishing(false)
          dispatch({ type: "UPDATE_PINBOARD_PUBLISH_DATA", payload: { ...pinboardData.publishData, published: false } });
          dispatch({ type: "SHOW_ACTION_NOTIFICATION", payload: { msg: "Pinboard unpublished successfully" } });
          return
        }
        setIsPublishing(false)
        return response.json()
      })
      .then((res: TODO) => {
        if (res) {
          dispatch({ type: "SHOW_ERROR_NOTIFICATION", payload: { msg: res.error ? res.error : res.message } });
        }
      })
  }, 1500)

  const publishPinboard = useDebounce(() => {
    fetchPostJson(`pb/projects/${pinboardData.projectId}/pinboards/${pinboardData.pinboardId}/shareLink`, token, {})
      .then((res: TODO) => {
        if (res.error || res.message) {
          setIsPublishing(false)
          dispatch({ type: "SHOW_ERROR_NOTIFICATION", payload: { msg: res.error ? res.error : res.message } });
        } else {
          setIsPublishing(false)
          dispatch({ type: "UPDATE_PINBOARD_PUBLISH_DATA", payload: { ...res, published: true } });
          dispatch({ type: "SHOW_ACTION_NOTIFICATION", payload: { msg: "Pinboard published successfully" } });
          return
        }
      })
  }, 1500)

  return (
    <div className="pinboards-header border-bottom">
      <div className="nav-menu">
        <DropdownMenu
          menuButton={<span className="d-flex align-items-center"><Icon className="menu-walr mr-2" type="walr" /> <span className="menu-label">Pinboard Designer</span><Icon className="menu-chevron" type="chevron-mini-down-alt" /></span>}
          items={PinboardAppMenuItems}
          onClickHandler={appMenuItemClick}
          onOpenMenu={() => setShowAppMenu((prevState: TODO) => !prevState)}
          showMenu={showAppMenu}
        />
      </div>
      {history.location.pathname !== "/" ?
        <Breadcrumb
          routePath={history.location.pathname}
          activeRouteTitle={"pinboards"}
        />
        : null}
      <div className="nav-cta d-flex flex-row">
        <button type="button" className="btn btn-secondary" style={{ maxWidth: "80px", maxHeight: "36px" }} onClick={() => window.open(`${history.location.pathname}`, "_blank")}>Preview</button>
        <div className="btn-group" auto-close="disabled">
          <button type="button" className="btn btn-primary ml-2 mr-1 pl-2 text-white" disabled={loadingPinboard} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <Icon type="share-arrow" fill={"#FFF"} />
            Share
          </button>
          <div className="dropdown-menu dropdown-menu-right border-0 p-0">
            <div className="p-3 border-bottom strong">Share pinboard</div>
            {!isPublishing ?
              <div className="d-flex justify-content-between align-items-center p-3 border-bottom"
                onClick={() => {
                  setIsPublishing(true);
                  if (pinboardData.publishData?.published){
                    unpublishPinboard()
                  } else {
                    publishPinboard()
                  }
                }}>
                <div className="d-flex align-items-center mr-5">
                  <Icon type="globe-major" className="mr-2" />
                  <span>Publish to the web</span>
                </div>
                <SimpleSwitch
                  checked={pinboardData.publishData?.published ? pinboardData.publishData?.published : false}
                  disabled={isPublishing}
                  onChange={() => {
                    setIsPublishing(true);
                    if (pinboardData.publishData?.published) {
                      unpublishPinboard()
                    } else {
                      publishPinboard()
                    }
                  }}
                />
              </div> :
              <div className="d-flex p-3 border-bottom">
                <FullHeightSpinner />
              </div>
            }
            <div className="d-flex flex-column p-3">
              <div>
                <div className="d-flex align-items-center mb-3">
                  <Icon type="link" className="mr-2" />
                  <span>Pinboard URL</span>
                </div>
                <div>
                  <div className="d-flex flex-column">
                    <input className="sidebar-input form-control mb-2" disabled={true} type="text" value={pinboardData.publishData?.published && shareUrl ? shareUrl : ""} placeholder="http://" />
                    {pinboardData.publishData?.published &&
                      <button type="button" className="btn btn-shadow" onClick={() => { dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: "Pinboard link copied to clipboard" } }); navigator.clipboard.writeText(shareUrl) }}>
                        Copy link to clipboard
                      </button>}
                  </div>
                  {pinboardData.publishData?.published && <small className="d-flex justify-content-center text-muted w-100 mt-3">Link expires: {new Date(pinboardData.publishData?.expires).toLocaleString()}</small>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}

interface MainNavBarProps {
  token: string
  pinboardData: TODO
  showSidebar: { show: boolean, type: string }
  setShowSidebar: (obj: { show: boolean, type: string }) => void
  showMainMenu: boolean
  setShowMainMenu: (value: TODO) => void
  loadingPinboard: boolean,
  setLoadingPinboard: (value: boolean) => void
  savingLayout: boolean
}

export const PinboardMainNavBar: React.FC<MainNavBarProps> = ({ token, pinboardData, showSidebar, setShowSidebar, showMainMenu, setShowMainMenu, loadingPinboard, setLoadingPinboard, savingLayout, }) => {
  const dispatch = useDispatch();
  const pinboardMainMenuItems = returnMenuPages(pinboardData.pages);

  const sidebarClickHandler = (type: string) => {
    if (showSidebar.show === false) {
      setShowSidebar({ show: true, type: type });
    } else if (showSidebar.show === true && showSidebar.type !== type) {
      setShowSidebar({ show: true, type: type });
    } else {
      setShowSidebar({ show: false, type: type });
    }
  }

  const pageSelectHandler = (menuItem: TODO) => {
    if (pinboardData.activePage.id && menuItem.action && pinboardData.activePage.id !== menuItem.action) {
      setLoadingPinboard(true)
      fetchGetJson(`pb/projects/${pinboardData.projectId}/pinboards/${pinboardData.pinboardId}/pages/${menuItem.action}`, token)
        .then((res: TODO) => {
          if (res.error || res.message) {
            dispatch({ type: "SHOW_ERROR_NOTIFICATION", payload: { msg: res.error ? res.error : res.message } })
          } else {
            dispatch({ type: "SET_ACTIVE_PINBOARD_PAGE", payload: { page: res, reload: true } })
          }
        })
    }
  }

  return (
    <div className="pinboards-menu pinboards-header" >
      <div className="pinboards-icons col-lg-4 d-flex justify-content-start p-0">
        <Tooltip openDelay={100} position="auto" anchorElement={"target"}>
          <button type="button" className={`${showSidebar.show && showSidebar.type === "add" ? "selected" : ""}`} onClick={() => sidebarClickHandler("add")} disabled={loadingPinboard} title="Add element"><Icon type="add-xl" /></button>
        </Tooltip>
        <Tooltip openDelay={100} position="bottom" anchorElement={"target"}>
          <button type="button" className={`${showSidebar.show && showSidebar.type === "pages" ? "selected" : ""}`} onClick={() => sidebarClickHandler("pages")} disabled={loadingPinboard} title="Boards"><Icon type="page" /></button>
        </Tooltip>
        {/* ---------------------- HIDDEN UNTIL PROPERLY IMPLEMENTED ---------------------- */}
        {/* <Tooltip openDelay={100} position="bottom" anchorElement={"target"}>
            <button className={`${showSidebar.show && showSidebar.type === "filter" ? "selected" : ""}`} onClick={() => sidebarClickHandler("filter")} title="Filter"><Icon type="filter" /></button>
          </Tooltip> */}
        <Tooltip openDelay={100} position="bottom" anchorElement={"target"}>
          <button type="button" className={`${showSidebar.show && showSidebar.type === "settings" ? "selected" : ""}`} onClick={() => sidebarClickHandler("settings")} disabled={loadingPinboard} title="Settings"><Icon type="settings" /></button>
        </Tooltip>
      </div>
      <div className="pinboards-main-menu col-lg-4 d-flex justify-content-center align-items-center p-0">
        <DropdownMenu
          menuButton={<span className="d-flex align-items-center justify-content-between flex-grow-1"><span className="menu-label text-truncate d-flex justify-content-start">{pinboardData.activePage?.title ? pinboardData.activePage.title : "No board selected"}</span><Icon className="menu-chevron" type="chevron-mini-down-alt" /></span>}
          items={pinboardMainMenuItems}
          onClickHandler={pageSelectHandler}
          onOpenMenu={() => setShowMainMenu((prevState: TODO) => !prevState)}
          showMenu={showMainMenu} />
      </div>
      <div className="align-items-center d-flex col-lg-4 justify-content-end p-0">
        <div>
          {savingLayout && <div className="saving"><Icon type="cloud" className="mr-1" />Saving</div>}
        </div>
      </div>
    </div >
  )
}

interface PreviewNavBarProps {
  token: string,
  pinboardData: TODO
  fetchGetPreviewPage: (pinboardData: TODO, pageId: string) => void
  showMainMenu: boolean
  setShowMainMenu: (value: TODO) => void
  setLoadingPinboard: (value: boolean) => void
  isPreview: string
}

export const PinboardPreviewNavBar: React.FC<PreviewNavBarProps> = ({ token, pinboardData, fetchGetPreviewPage, showMainMenu, setShowMainMenu, setLoadingPinboard, isPreview }) => {
  const dispatch = useDispatch();
  const pinboardMainMenuItems = returnMenuPages(pinboardData.pages);

  const pageSelectHandler = (menuItem: TODO) => {
    if (pinboardData.activePage.id !== menuItem.action) {
      setLoadingPinboard(true)

      if (isPreview === "public") {
        fetchGetPreviewPage({ ...pinboardData, pages: pinboardData.originalBackendPages }, menuItem.action)
      } else {
        fetchGetJson(`pb/projects/${pinboardData.projectId}/pinboards/${pinboardData.pinboardId}/pages/${menuItem.action}`, token)
          .then((res: TODO) => {
            if (res.error || res.message) {
              dispatch({ type: "SHOW_ERROR_NOTIFICATION", payload: { msg: res.error ? res.error : res.message } })
            } else {
              dispatch({ type: "SET_ACTIVE_PINBOARD_PAGE", payload: { page: res, reload: true } })
            }
          })
      }

    }
  }

  const pageNavHandler = (next: boolean) => {
    const currentPageIndex = pinboardData.originalBackendPages.findIndex((el: PinboardPage) => el.id === pinboardData.activePage.id)
    if (next) {
      if (currentPageIndex + 1 < pinboardData.originalBackendPages.length) {
        pageSelectHandler({ action: pinboardData.originalBackendPages[currentPageIndex + 1].id })
      }
    } else {
      if (currentPageIndex !== 0) {
        pageSelectHandler({ action: pinboardData.originalBackendPages[currentPageIndex - 1].id })
      }
    }
  }

  return (
    <div className="pinboards-header pinboard-preview d-flex justify-content-between">
      <div className="col-4 d-flex justify-content-start align-items-center pl-3">
        {pinboardData.previewTitle &&
          <Tooltip openDelay={100} position="auto" anchorElement={"target"}>
            <span className="d-flex align-items-center title" title={pinboardData.previewDescription}>
              {pinboardData.previewTitle}
            </span>
          </Tooltip>
        }
      </div>
      <div className="pinboards-main-menu col-4 d-flex justify-content-center align-items-center p-0">
        <DropdownMenu
          menuButton={<span className="d-flex align-items-center justify-content-between flex-grow-1"><span className="menu-label text-truncate d-flex justify-content-start">{pinboardData.activePage?.title ? pinboardData.activePage.title : "No board selected"}</span><Icon className="menu-chevron" type="chevron-mini-down-alt" /></span>}
          items={pinboardMainMenuItems}
          onClickHandler={pageSelectHandler}
          onOpenMenu={() => setShowMainMenu((prevState: TODO) => !prevState)}
          showMenu={showMainMenu} />
      </div>
      <div className="d-flex align-items-center justify-content-end col-4 pr-3">
        <Tooltip openDelay={100} position="bottom" anchorElement={"target"}>
          <button type="button" title="Previous" className="btn btn-transparent p-1" onClick={() => pageNavHandler(false)}>
            <Icon type="chevron-left" className="pe-none" />
          </button>
        </Tooltip>
        <Tooltip openDelay={100} position="bottom" anchorElement={"target"}>
          <button type="button" title="Next" className="btn btn-transparent p-1" onClick={() => pageNavHandler(true)}>
            <Icon type="chevron-right" className="pe-none" />
          </button>
        </Tooltip>
      </div>
    </div>
  )
}
