const QUESTION_TYPES = [
  {
    text: 'Single',
    id: 'n',
  },
  {
    text: 'Single grid',
    id: 'rn',
  },
  {
    text: 'Multi',
    id: 'm',
  },
  {
    text: 'Multi grid',
    id: 'rm',
  },
  {
    text: 'Numeric',
    id: 'f',
  },
  {
    text: 'Duration',
    id: 'h',
  },
];

export default QUESTION_TYPES;
