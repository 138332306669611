const TAB_CONTENT_EXPORT_OPTIONS = [
  {
    actionName: 'Export to Word',
    action: 'word',
    extension: 'docx',
    icon: 'fas fa-file-word',
  },
  {
    actionName: 'Export to Excel',
    action: 'excel',
    extension: 'xlsx',
    icon: 'fas fa-file-excel',
  },
  {
    actionName: 'Export to PowerPoint',
    action: 'ppt',
    extension: 'pptx',
    icon: 'fas fa-file-powerpoint',
  },
];

export default TAB_CONTENT_EXPORT_OPTIONS;
