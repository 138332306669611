import { useRef, useState } from "react";
import { process } from '@progress/kendo-data-query';
import { DropDownButton } from "@progress/kendo-react-buttons";
import { DateRangePicker, type SelectionRange } from "@progress/kendo-react-dateinputs";

import documentTypeIcons from './iconByDocumentType';
import { returnFilterData } from "../../../../shared/helpers/project/returnFilterData/returnFilterData";
import { sortProjectDocuments } from "../../../../shared/helpers/sortProjectDocuments/sortProjectDocuments";
import { returnSortDirectionIcon } from "../../../../shared/helpers/returnSortDirectionIcon/returnSortDirectionIcon";
import { returnUpdatedSortDirection } from "../../../../shared/helpers/returnUpdatedSortDirection/returnUpdatedSortDirection";

type Props = {
  originalDatasetsState: { type: string }[]
  datasetsState: { projects: TODO[] }
  setDatasetsState: (data: TODO) => void
  sortType: { sortBy: string, direction: string }
  setSortType: (data: { sortBy: string, direction: string }) => void
}

const iconByDocumentType: { [type: string]: string } = documentTypeIcons;

export const ProjectDocumentsFilteringAndSorting = ({ originalDatasetsState, datasetsState, setDatasetsState, sortType, setSortType }: Props) => {
  const [filterByType, setFilterByType] = useState("Document name");
  const [dateRange, setDateRange] = useState<SelectionRange>({ start: null, end: null });
  const [selectedDocumentType, setSelectedDocumentType] = useState<string[]>([]);

  const searchInput = useRef<HTMLInputElement | null>(null);
  const availableDocumentTypes: string[] = [...new Set(originalDatasetsState.map((item: { type: string }) => item.type).sort())];

  const onFilterChangeHandler = (e: { target: { value: TODO } }, type: 'clear' | 'documentType' | 'dateCreated' | 'search' | 'createdBy') => {
    const filterData = returnFilterData(type, null, [], e, originalDatasetsState, e.target.value, null, searchInput.current?.value);
    setDatasetsState(filterData);
  }

  const clearFilterInputs = () => {
    if (filterByType === "Document name" || filterByType === "Created by") {
      if (searchInput?.current) {
        searchInput.current.value = '';
      }
      setSelectedDocumentType([])
    } else {
      setDateRange({ start: null, end: null })
      setSelectedDocumentType([])
    }
    onFilterChangeHandler({ target: { value: "" } }, "clear")
  }

  // Extra function needed before calling onFilterChangeHandler
  const docTypeFilterHandler = (type: string) => {
    const updatedDocTypes = [...selectedDocumentType]
    if (updatedDocTypes.includes(type)) {
      if (updatedDocTypes.length === 1) {
        clearFilterInputs();
      } else {
        const typeIndex = updatedDocTypes.indexOf(type)
        updatedDocTypes.splice(typeIndex, 1)
        setSelectedDocumentType(updatedDocTypes)
        onFilterChangeHandler({ target: { value: updatedDocTypes } }, "documentType")
      }
    } else {
      updatedDocTypes.push(type)
      setSelectedDocumentType(updatedDocTypes)
      onFilterChangeHandler({ target: { value: updatedDocTypes } }, "documentType")
    }
  }

  const updateSortType = (sortBy: 'name' | 'date') => {
    const updatedDirectionData = returnUpdatedSortDirection(sortType, sortBy)
    const updatedSortProjectDocuments = sortProjectDocuments(datasetsState.projects, updatedDirectionData.updatedDirection, sortBy)
    setSortType({
      sortBy: sortBy,
      direction: updatedDirectionData.updatedDirectionIcon
    })
    setDatasetsState({
      result: process(updatedSortProjectDocuments.projectsDocuments.documents.slice(0), { take: 20, skip: 0 }),
      dataState: { take: 20, skip: 0 },
      projects: updatedSortProjectDocuments.projectsDocuments.documents
    })
  }

  return (
    <div className="d-flex justify-content-between">
      <div className="btn-group" role="group" aria-label="Document filtering">
        {
          availableDocumentTypes.map((availableType) => {
            return (
              <button
                type='button'
                key={availableType}
                onClick={() => docTypeFilterHandler(availableType)}
                className={`btn ${selectedDocumentType.includes(availableType) ? 'btn-secondary' : 'btn-outline-secondary'} text-dark shadow-none`}>
                <i className={`${iconByDocumentType[availableType]} mr-2 text-dark`} />
                {availableType === 'workflows' ? 'Data operations' : availableType[0].toUpperCase() + availableType.substring(1)}
              </button>
            )
          })
        }
      </div>

      <div className={`d-flex justify-content-end gap-md ${filterByType === "Date filter" ? "align-items-end" : "align-items-center"}`}>
        {/* Search/FilterTypes/FilterDateRange */}
        {
          filterByType === "Date filter" ?
            <div className='d-flex'>
              <DateRangePicker
                value={dateRange} // @ts-ignore
                startDateInputSettings={{ className: "h-32 border-secondary" }} // @ts-ignore
                endDateInputSettings={{ className: "h-32 border-secondary" }}
                onChange={(e) => { setDateRange(e.value); onFilterChangeHandler(e, "dateCreated"); }} />
            </div>
            :
            <input
              type="text"
              ref={searchInput}
              name='Document name search'
              className="form-control form-control-sm w-auto shadow-none h-32"
              placeholder={filterByType === "Document name" ? "Document name" : "Created by"}
              onChange={(e) => onFilterChangeHandler(e, filterByType === "Document name" ? "search" : "createdBy")}
            />
        }
        {/* Filter buttons */}
        <div className="btn-group" role="group" aria-label="Document filtering">
          <button type='button' className="btn btn-outline-secondary text-dark shadow-none" onClick={() => clearFilterInputs()} title="Clear filter">
            <i className="fas fa-times" />
          </button>
          <DropDownButton
            iconClass="fas fa-filter"
            className="filter-dropdown btn btn-outline-secondary text-dark px-1 shadow-none"
            items={["Document name", "Created by", "Date filter"]}
            onItemClick={(e) => { setFilterByType(e.item); clearFilterInputs() }}
          />
        </div>
        {/* Projects sorting */}
        <div className="btn-group" role="group" aria-label="Document sorting">
          <button type='button' onClick={() => updateSortType('name')} className="btn btn-outline-secondary text-dark shadow-none">
            <span>Name</span>
            <i className={`fa ml-2 ${returnSortDirectionIcon('name', sortType)}`} />
          </button>
          <button type='button' onClick={() => updateSortType('date')} className="btn btn-outline-secondary text-dark shadow-none">
            <span>Date</span>
            <i className={`fa ml-2 ${returnSortDirectionIcon('date', sortType)}`} />
          </button>
        </div>
      </div>
    </div>
  )
}
