interface CheckedQuestion {
  title: string;
  values: {
    value:
      | {
          text: string;
        }[]
      | { text: string };
  };
}

export const returnQuestionsWithReplacedText = (
  checkedQuestions: CheckedQuestion[],
  isCaseSensitive: boolean,
  isDichotomyA: boolean,
  findValue: string,
  replaceValue: string
) => {
  const updatedCheckedQuestions = [...checkedQuestions];

  if (!isCaseSensitive) {
    if (isDichotomyA) {
      // If it Dichotomy A, change question title
      updatedCheckedQuestions
        .filter(ques =>
          ques.title.toLowerCase().includes(findValue.toLowerCase())
        )
        .forEach(element => {
          const replace = `${findValue}`;
          const searchRegExp = new RegExp(replace, 'gi');
          element.title = element.title.replace(searchRegExp, replaceValue);
        });
    } else {
      // Otherwise change question value's text
      updatedCheckedQuestions.forEach(checkedQues => {
        if (Array.isArray(checkedQues.values.value)) {
          checkedQues.values.value
            .filter(ques =>
              ques.text.toLowerCase().includes(findValue.toLowerCase())
            )
            .forEach(element => {
              const replace = `${findValue}`;
              const searchRegExp = new RegExp(replace, 'gi');
              element.text = element.text.replace(searchRegExp, replaceValue);
            });
        } else {
          if (
            checkedQues.values.value.text
              .toLowerCase()
              .includes(findValue.toLowerCase())
          ) {
            const replace = `${findValue}`;
            const searchRegExp = new RegExp(replace, 'gi');
            checkedQues.values.value.text =
              checkedQues.values.value.text.replace(searchRegExp, replaceValue);
          }
        }
      });
    }
  } else {
    if (isDichotomyA) {
      updatedCheckedQuestions
        .filter(ques => ques.title.includes(findValue))
        .forEach(element => {
          element.title = element.title.replace(findValue, replaceValue);
        });
    } else {
      updatedCheckedQuestions.forEach(checkedQues => {
        if (Array.isArray(checkedQues.values.value)) {
          checkedQues.values.value
            .filter(ques =>
              ques.text.toLowerCase().includes(findValue.toLowerCase())
            )
            .forEach(element => {
              element.text = element.text.replace(findValue, replaceValue);
            });
        } else {
          if (
            checkedQues.values.value.text
              .toLowerCase()
              .includes(findValue.toLowerCase())
          ) {
            checkedQues.values.value.text =
              checkedQues.values.value.text.replace(findValue, replaceValue);
          }
        }
      });
    }
  }

  return updatedCheckedQuestions;
};
