import type React from 'react'
import { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Tooltip } from '@progress/kendo-react-tooltip'
import { useHistory, useParams } from 'react-router-dom'

import { editCpi, editPanelType, editPII, editLiveUrl, editTestUrl, editTargetCompletes, editEndDate, editAsap, editName, editSurveyType, editCountry, editLanguage, editLOI, editRate, editStartDate, editTargetType, editClientCpi, setAudience, editCurrencyType } from '../../../store/reducers/audienceAccessReducer/audienceAccessReducer'
import type { RootState } from '../../../store/reducers/rootReducer'
import { Metrics } from './Metrics/Metrics'
import { SetupDetails } from './SetupDetails/SetupDetails'
import { Timeline } from './Timeline/Timeline'
import type { EditAudience, NewAudience } from '../../../interfaces/auddineceAccessInterfaces/auddineceAccessInterfaces'
import { UpdateMarketModal } from '../shared/UpdateMarketModal'
import { Icon } from '../../shared/Icon/Icon';
import { fetchPostResOrJson as newAudiencePostRequest, fetchPutResOrJson as editAudiencePutRequest } from '../../../services/services'

interface Props {
  setDidMount: (val: boolean) => void
}

export const AudienceDetails = ({ setDidMount }: Props) => {
  const data = useSelector((state: RootState) => state.audienceDataReducer);
  const [error, setError] = useState({})
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [showUpdateMarketModal, setShowUpdateMarketModa] = useState<boolean>(false)
  const [updatedMarket, setUpdatedMarket] = useState<{ name: string, isoCode: string } | null>(null)
  const dispatch = useDispatch()
  const formRef = useRef<TODO>()
  const params: { id: string, name: string } = useParams()
  const history = useHistory()
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  let editObj: EditAudience = {
    name: "",
    surveyType: "",
    country: {
      name: "",
      isoCode: ""
    },
    language: "",
    quantity: "",
    incidenceRate: "",
    lengthOfInterview: "",
    liveUrl: "",
    testUrl: "",
    containsPersonalData: false,
    completeAsap: "",
    panelType: "",
    targetType: "",
    cpi: 0,
    clientCPI: 0,
    currency: "",
    screening: [],
    quotas: [],
    interlockedQuotas: [],
    launchDate: "",
    targetEndDate: "",
    etag: "",
  }
  let newObj: NewAudience = {
    name: "",
    surveyType: "AdHoc",
    country: {
      name: "",
      isoCode: ""
    },
    language: "",
    quantity: "",
    incidenceRate: "",
    lengthOfInterview: "",
    liveUrl: "",
    testUrl: "",
    containsPersonalData: false,
    screening: [],
    quotas: [],
    interlockedQuotas: [],
    launchDate: "",
    targetEndDate: "",
    completeAsap: false,
    panelType: "",
    targetType: "",
    cpi: 0,
    clientCPI: 0,
    currency: "GBP",
  }

  const isValidUrl = (urlString: string) => {
    try {
      return Boolean(new URL(urlString))
    } catch (e) {
      return false
    }
  }

  const handleEditedInputs = (e: React.ChangeEvent<HTMLInputElement> | TODO, inputFiledType: string) => {
    if (inputFiledType === "name") {
      dispatch(editName(e.target.value))
    } else if (inputFiledType === "surveyType") {
      dispatch(editSurveyType(e.target.textContent))
    } else if (inputFiledType === "market") {
      if (data.country && data.country.name !== "" && data.country.name !== e.name) {
        setShowUpdateMarketModa(true)
        setUpdatedMarket(e)
      } else {
        dispatch(editCountry(e))
      }
    } else if (inputFiledType === "language") {
      dispatch(editLanguage(e))
    } else if (inputFiledType === "cpi") {
      dispatch(editCpi(Number.parseFloat(e.target.value)))
    } else if (inputFiledType === "clientCpi") {
      dispatch(editClientCpi(Number.parseFloat(e.target.value)))
    } else if (inputFiledType === "currency") {
      const handleValue = e.includes("GBP") ? "GBP" : e.includes("USD") ? "USD" : "AUD"
      dispatch(editCurrencyType(handleValue))
    } else if (inputFiledType === "panelSource") {
      dispatch(editPanelType(e.target.value))
    } else if (inputFiledType === "pii") {
      const value = e.target.value !== "No"
      dispatch(editPII(value))
    } else if (inputFiledType === "liveUrl") {
      dispatch(editLiveUrl(e.target.value))
    } else if (inputFiledType === "testUrl") {
      dispatch(editTestUrl(e.target.value))
    } else if (inputFiledType === "quantity") {
      dispatch(editTargetCompletes(Number(e.target.value)))
    } else if (inputFiledType === "loi") {
      dispatch(editLOI(Number(e.target.value)))
    } else if (inputFiledType === "rate") {
      dispatch(editRate(Number(e.target.value)))
    } else if (inputFiledType === "startDate") {
      dispatch(editStartDate(e.target.value))
    } else if (inputFiledType === "endDate") {
      dispatch(editEndDate(e.target.value))
    } else if (inputFiledType === "asap") {
      dispatch(editAsap(!!e.target.value))
      if (!data.completeAsap) {
        dispatch(editEndDate(null))
      }
    } else if (inputFiledType === "targetType") {
      dispatch(editTargetType(e))
    }
  }

  const handleErrorNewObject = <T extends NewAudience | EditAudience>(obj: T) => {
    let emptyFieldError = {}

    if (obj.name === "") {
      emptyFieldError = Object.assign({ ...emptyFieldError, name: true })
    }
    if (obj.surveyType === "") {
      emptyFieldError = Object.assign({ ...emptyFieldError, surveyType: true })
    }
    if (obj.country === null) {
      emptyFieldError = Object.assign({ ...emptyFieldError, country: true })
    }
    if (obj.language === null) {
      emptyFieldError = Object.assign({ ...emptyFieldError, language: true })
    }
    if (obj.liveUrl !== "") {
      const validURL = isValidUrl(obj.liveUrl)
      if (!validURL) {
        emptyFieldError = Object.assign({ ...emptyFieldError, invalidLiveUrl: true });
      }
    }
    if (obj.testUrl !== "") {
      const validURL = isValidUrl(obj.testUrl)
      if (!validURL) {
        emptyFieldError = Object.assign({ ...emptyFieldError, invalidTestUrl: true });
      }
    }
    if (obj.cpi === 0) {
      emptyFieldError = Object.assign({ ...emptyFieldError, cpi: true })
    }
    if (!obj.quantity) {
      emptyFieldError = Object.assign({ ...emptyFieldError, quantity: true })
    }
    if (!obj.lengthOfInterview) {
      emptyFieldError = Object.assign({ ...emptyFieldError, lengthOfInterview: true })
    }
    if (!obj.incidenceRate) {
      emptyFieldError = Object.assign({ ...emptyFieldError, incidenceRate: true })
    }
    if (!obj.targetEndDate && !obj.completeAsap) {
      emptyFieldError = Object.assign({ ...emptyFieldError, targetEndDate: true })
    }

    if (Object.keys(emptyFieldError).length > 0) {
      setError({ ...emptyFieldError })
      return false
    }
    return true
  }

  const getData = () => ({ ...data })

  const handleSaveChanges = () => {
    if (formRef.current) {
      formRef.current = getData()
    }
    const data = formRef.current

    if (data.audienceStatus === 'New') {
      for (const [key, value] of Object.entries(data) as TODO) {
        if (newObj.hasOwnProperty(key)) {
          if (key === 'country' || key === 'language') {
            newObj = Object.assign({ ...newObj, [key]: value?.isoCode || value })
          } else {
            newObj = Object.assign({ ...newObj, [key]: value })
          }
        }
      }
      const isFormValid = handleErrorNewObject(newObj)
      if (isFormValid) {
        setIsLoading(true)
        // Checking if new audience is created without project
        if (window.location.pathname !== "/audiences/create") {
          newAudiencePostRequest(`au/a/projects/${params.name}/audiences`, token, newObj)
            .then(async (res: TODO) => {
              if (res.ok) {
                setIsLoading(false)
                const ids = await res.json()
                history.push({ pathname: `/projects/${ids.projectId}/audiences/${ids.audienceId}` })
                dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'Audience created successfully' } })
                setDidMount(false)
              } else {
                setIsLoading(false)
                dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.status !== 200 ? `Error creating Audience: ${res.detail}` : res.message } });
              }
            })
        } else {
          newAudiencePostRequest("au/a/audiences", token, newObj)
            .then(async (res: TODO) => {
              if (res.ok) {
                setIsLoading(false)
                const ids = await res.json()
                history.push({ pathname: `/projects/${ids.projectId}/audiences/${ids.audienceId}` })
                dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'Audience created successfully' } })
                setDidMount(false)
              } else {
                setIsLoading(false)
                dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.status !== 200 ? `Error creating Audience: ${res.detail}` : res.message } });
              }
            })
        }
      }
    } else {
      for (const [key, value] of Object.entries(data) as any) {
        if (editObj.hasOwnProperty(key)) {
          if (key === 'country' || key === 'language') {
            editObj = Object.assign({ ...editObj, [key]: value?.isoCode || value })
          } else {
            editObj = Object.assign({ ...editObj, [key]: value })
          }
        }
      }
      const isFormValid = handleErrorNewObject(editObj)
      if (isFormValid) {
        setIsLoading(true)
        editAudiencePutRequest(`au/a/projects/${params.name}/audiences/${params.id}`, token, editObj)
          .then(async (res: TODO) => {
            if (res.ok) {
              setIsLoading(false)
              const result = await res.json()
              dispatch(setAudience(result))
              dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'Audience updated successfully' } })
            } else {
              setIsLoading(false)
              dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.status !== 200 ? `Error editing Audience: ${res.detail}` : res.message } });
            }
          })
      }
    }
  }

  const onHide = () => {
    setShowUpdateMarketModa(false);
  };

  return (
    <>
      <div className='board-container d-flex justify-content-center flex-column w-100' >
        <form ref={formRef} className='d-flex flex-column gap-lg'>
          <SetupDetails
            handleEditedInputs={handleEditedInputs}
            error={error}
          />
          <Metrics
            handleEditedInputs={handleEditedInputs}
            error={error} />
          <Timeline
            handleEditedInputs={handleEditedInputs}
            error={error} />
        </form>
        <footer className='p-4 bg-white'>
          <div className='d-flex justify-content-end align-items-center board-container'>
            <Tooltip anchorElement="target" position="top">
              <span title='All fields marked with an asterisk (*) must be completed, as they are mandatory.' className='btn btn-shadow p-1 d-flex align-items-center mr-2'><Icon type='info-tooltip' className='pe-none' /> </span>
            </Tooltip>
            <button type='submit' disabled={data.audienceStatus === "Closed" || data.audienceStatus === "Reconciled"} onClick={handleSaveChanges} className='btn btn-primary h-32 strong ' >
              Save changes
              {
                isLoading &&
                <span className="spinner-border spinner-border-sm ml-1" role="status" aria-hidden="true" />
              }
            </button>
          </div>
        </footer>
      </div>

      {showUpdateMarketModal && <UpdateMarketModal updatedMarket={updatedMarket} onHide={onHide} />}
    </>
  )
}
