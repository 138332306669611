import React, { useEffect, useState, Fragment, useMemo } from 'react';
import { useParams } from 'react-router';
import { Switch } from '@progress/kendo-react-inputs';
import { useDispatch, useSelector } from 'react-redux';

import { Skip } from './Skip/Skip';
import Filter from './Filter/Filter';
import { Rotation } from './Rotation/Rotation';
import AnswerControl from './AnswerControl/AnswerControl';
import { Icon } from '../../../../../shared/Icon/Icon';
import { useDebounce } from '../../../../../shared/customHooks/useDebounce';
import { CustomErrorMessages } from './CustomErrorMessages/CustomErrorMessages';
import { SurveyFilterBuilder } from '../../SurveyFilterBuilder/SurveyFilterBuilder';
import { DropdownButton } from '../../../../../shared/DropdownButton/DropdownButton';
import { FullHeightSpinner } from '../../../../../shared/FullHeightSpinner/FullHeightSpinner';
import { InProgressOverlay } from '../../../../../shared/InProgressOverlay/InProgressOverlay';
import { returnElementOptions, returnSelectedElementData } from '../../helpers/returnDataHelpers/returnDataHelpers';
import { fetchGetJson as getSurveyStructure } from '../../../../../../services/services';
import { prepareLanguagesDropDown } from '../helpers';

export const LogicTabContent = ({ token, updateElements }) => {
  const dispatch = useDispatch()
  const { name: projectId, survey: surveyId } = useParams();
  const { theData } = useSelector(theState => theState.surveyInitialDataReducer);
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedTabCustomErrorMessages, setSelectedTabCustomErrorMessages] = useState(0);
  const [didMount, setDidMount] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [disabledQuestion, setDisabledQuestion] = useState(null);
  const [updateLanguage, setUpdateLanguage] = useState(null);
  const [showLogicFilterBuilder, setShowLogicFilterBuilder] = useState(false);

  const saveWithDebounce = useDebounce(() => dispatch({ type: 'SAVE_QUESTION' }), 1000);

  const localLogicData = JSON.parse(JSON.stringify(theData.logicStructureData));

  const languageList = useMemo(() => prepareLanguagesDropDown(theData.originalData?.languages), [theData.originalData.languages]);

  useEffect(() => {
    if (didMount) {
      setDidMount(false);
      if (!localLogicData && theData.selectedItem) {
        setIsLoading(true);
        getSurveyStructure(`su/projects/${projectId}/surveys/${surveyId}/structure`, token)
          .then(res => {
            setIsLoading(false);
            if (res.error || res.message) {
              dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error ? res.error : res.message } });
            } else {
              dispatch({ type: 'SET_LOGIC_STRUCTURE_DATA', payload: res })
            }
          })
      }
    }
  }, [didMount, projectId, surveyId, token, dispatch, localLogicData, theData.selectedItem])

  useEffect(() => {
    if (updateLanguage) {
      dispatch({ type: 'UPDATE_SURVEY_EDITING_LANGUAGE', payload: updateLanguage })
      setUpdateLanguage(null)
    }
  }, [updateLanguage, dispatch])

  useEffect(() => {
    if (theData.selectedItem && disabledQuestion === null) {
      setDisabledQuestion(theData.selectedItem.disabled)
    }
  }, [disabledQuestion, theData.selectedItem])

  const showFilterBuilder = () => {
    const element = theData.selectedItem.elements.filter(el => el.type === 'subq')[selectedTab]
    // The line below is used because 2 filter builders can be opened in the logic tab at the same time (One from logic, one from section options modal), so they need to be distinugished
    setShowLogicFilterBuilder(true)
    dispatch({
      type: 'SURVEY_DISPLAY_FILTER_BUILDER', payload: {
        display: true,
        filterData: localLogicData,
        combineFilterData: element.metadata?.filterData ? element.metadata.filterData : []
      }
    })
  }

  const updateElementData = (originalItem, debounce) => {
    const selectedItem = returnSelectedElementData(originalItem, theData.data.find(el => el.index === theData.selectedItem.section).elements.find(el => el.selected === true), theData.validationErrors, theData.data)
    const elementOptions = [{
      type: originalItem.type,
      expanded: true,
      published: !!originalItem.attributes?.['miext:id'],
      isDisabled: !!(originalItem.attributes?.['miext:disabled'] && originalItem.attributes['miext:disabled'] === "true"),
      index: null,
      items: returnElementOptions(originalItem, !!(originalItem.attributes?.['miext:disabled'] && originalItem.attributes['miext:disabled'] === "true"), originalItem.type, null)
    }]
    dispatch({ type: 'UPDATE_SURVEY_ELEMENT_DATA', payload: { item: selectedItem, originalItem: originalItem, elementOptions: elementOptions } })
    if (debounce === 'saveWithoutDebounce') {
      dispatch({ type: 'SAVE_QUESTION' })
    } else {
      saveWithDebounce()
    }
  }

  const onSaveFilter = (expression) => {
    const originalItem = JSON.parse(JSON.stringify(theData.selectedItem))
    originalItem.elements.filter(el => el.type === 'subq')[selectedTab].attributes = { ...originalItem.elements.filter(el => el.type === 'subq')[selectedTab].attributes, filter: expression }
    originalItem.elements.filter(el => el.type === 'subq')[selectedTab].metadata = theData.combineFilterData && theData.combineFilterData.length !== 0 ? { ...originalItem.elements.filter(el => el.type === 'subq')[selectedTab].metadata, filterData: theData.combineFilterData } : null
    dispatch({ type: 'ON_EXPAND_SUBQUESTION', payload: originalItem })
    saveWithDebounce()
    setShowLogicFilterBuilder(false)
    dispatch({ type: 'SURVEY_DISPLAY_FILTER_BUILDER', payload: { display: false, filterData: [], combineFilterData: [] } })
  }

  const returnDestinationData = () => {
    let data = [{ text: "None", id: "none" }, { text: "Next question", id: "nextques" }, { text: "Next section", id: "nextsection" }, { text: "Exit", id: "exit" }]
    const indexList = []
    if (localLogicData) {
      localLogicData.questions.forEach((el, key) => {
        if (el.qno.slice(0, -2) === theData.selectedItem.label) {
          indexList.push(key)
        }
      })
      localLogicData.questions.forEach((el, key) => {
        if (el.qno.slice(0, -2) !== theData.selectedItem.label && key > indexList[indexList.length - 1]) {
          data.push({ text: el.qno.slice(0, -2), id: el.qno.slice(0, -2) })
        }
      })
    }
    data = data.filter((item, i, a) => a.findIndex(el => (el.id === item.id)) === i)
    return data
  }

  const onSkipDestination = (e, index) => {
    const selectedItem = JSON.parse(JSON.stringify(theData.selectedItem))
    if (selectedItem.elements.find(el => el.type === 'rgroup').elements[index].attributes) {
      if (e.value.id !== "none") {
        selectedItem.elements.find(el => el.type === 'rgroup').elements[index].attributes =
        {
          ...selectedItem.elements.find(el => el.type === 'rgroup').elements[index].attributes,
          skip: e.value.id
        }
      }
      else {
        selectedItem.elements.find(el => el.type === 'rgroup').elements[index].attributes.skip = undefined
      }
    } else {
      if (e.value.id !== "none") {
        selectedItem.elements.find(el => el.type === 'rgroup').elements[index] = {
          ...selectedItem.elements.find(el => el.type === 'rgroup').elements[index],
          attributes: { skip: e.value.id }
        }
      }
    }
    dispatch({ type: 'ON_EXPAND_SUBQUESTION', payload: selectedItem })
    saveWithDebounce()
  }

  const onDisableQuestion = (value) => {
    const originalItem = theData.originalSelectedItem
    originalItem.attributes = { ...originalItem.attributes, 'miext:disabled': value }
    updateElementData(originalItem)
  }

  const onFilterInputChange = (value) => {
    const selectedItem = JSON.parse(JSON.stringify(theData.selectedItem))
    selectedItem.elements.filter(el => el.type === 'subq')[selectedTab].attributes.filter = value
    dispatch({ type: 'ON_EXPAND_SUBQUESTION', payload: selectedItem })
    saveWithDebounce()
  }

  const onLanguageChange = (e) => {
    dispatch({ type: 'LOAD_SURVEY_ELEMENT_DATA', payload: null })
    setUpdateLanguage(e.item.code)
  }

  const items = theData.selectedItem && !didMount ? returnDestinationData() : null

  let selectedLanguage = null
  if (theData?.originalData?.languages) {
    selectedLanguage = theData?.originalData?.languages?.find(item => item.code === theData.editingLanguage)
  }

  const subquestion = theData.selectedItem?.elements?.filter(el => el.type === 'subq')[theData.selectedSubquestion]

  return (
    <div className="edit-section h-100">
      {theData.displayFilterBuilder && showLogicFilterBuilder &&
        <SurveyFilterBuilder
          handleClose={() => { dispatch({ type: 'SURVEY_DISPLAY_FILTER_BUILDER', payload: { display: false, filterData: [], combineFilterData: [] } }); setShowLogicFilterBuilder(false) }}
          filterData={theData.filterData}
          surveyId={theData.selectedItem.id}
          combineFilterData={theData.combineFilterData}
          manualFilterExpression={theData.selectedItem.elements.filter(el => el.type === 'subq')[selectedTab].attributes.filter}
          option='filter'
          token={token}
          type='logic'
          onSaveFilter={onSaveFilter}
          selectedTab={selectedTab}
        />
      }
      {!didMount && !isLoading ?
        <div className="card w-100 h-100">
          {theData.loadingMessage && theData.loadingState &&
            <InProgressOverlay type="overlay" theme="primary" message={theData.loadingMessage} />
          }
          {theData.loadingState && !theData.loadingMessage ?
            <InProgressOverlay theme="primary" type={theData.loadingMessage ? "overlay" : "fullscreen"} message={theData.loadingMessage} />
            : theData.selectedItem && theData.selectedItem.type === 'ques' && localLogicData ?
              <div className="card-body d-flex flex-column h-100 p-0">
                <div className="d-flex card-header px-3 align-items-center justify-content-end h-48">
                  <div>
                    <div className="d-flex align-items-center">
                      <DropdownButton
                        icon="globe"
                        textField="name"
                        className="pl-1 btn-transparent"
                        hideChevron={true}
                        text={`${selectedLanguage?.name ?? ''} (${selectedLanguage?.code})`}
                        items={languageList}
                        onItemClick={(e) => onLanguageChange(e)}
                      />
                      {/* <Button className="btn btn-primary d-flex align-center ml-2" onClick={onSaveChanges} tabIndex={-1}>Save</Button> */}
                    </div>
                  </div>
                </div>
                <div className="overflow-auto p-5">
                  <div className="themes-panel logic-content">
                    <Fragment>
                      <div className='row d-flex justify-content-between m-4 content'>
                        <div className='w-25 px-0 pb-3'>
                          <h6 className='mb-3 mt-3'>Answer control</h6>
                          <p>Specify which answers or combination of answers are allowed.</p>
                        </div>
                        <div className='col-lg-7 pb-3 pr-3 pl-0 pt-5 logic-content-tab'>
                          <AnswerControl theData={theData} />
                        </div>
                      </div>
                      <div className='row d-flex justify-content-between pb-3 m-4 content'>
                        <div className='w-25 px-0 pb-3'>
                          <h6 className='mb-3 mt-3'>Randomisation</h6>
                          <p>Specify how the sequence of questions or rows should be displayed.</p>
                        </div>
                        <div className='col-lg-7 pb-3 pt-5 px-3'>
                          <Rotation />
                        </div>
                      </div>
                      <div className='row d-flex justify-content-between pb-3 m-4 content'>
                        <div className='w-25 px-0 pb-3'>
                          <h6 className='mb-3 mt-3'>Filter</h6>
                          <p>Specify whether a question (or another element), will be displayed or not.</p>
                        </div>
                        <div className='col-lg-7 pb-3 px-0 pt-5 mb-3 logic-content-tab'>
                          <Filter
                            theData={theData}
                            showFilterBuilder={showFilterBuilder}
                            selectedTab={selectedTab}
                            onTabSelect={(e) => setSelectedTab(e.selected)}
                            onChangeFilterInput={onFilterInputChange}
                          />
                        </div>
                      </div>
                      {
                        // Only show SKIP for single and multi elements
                        subquestion && (subquestion.attributes.type === 'n' || subquestion.attributes.type === 'm') &&
                        <div className='row d-flex justify-content-between m-4 content'>
                          <div className='w-25 px-0 pb-3'>
                            <h6 className='mb-3 mt-3'>Skip</h6>
                            <p>If this alternative (must be a single choice) is selected, the next question shown will be the target specified.</p>
                          </div>
                          <div className='col-lg-7 pb-3 px-0 pt-5'>
                            <Skip items={items} onSkipDestination={onSkipDestination} />
                          </div>
                        </div>
                      }
                      {theData.selectedItem.published &&
                        <div className='row d-flex justify-content-between m-4 content'>
                          <div title="Disable">
                            <div className="d-flex align-items-center">
                              <Switch className="mr-3" checked={disabledQuestion}
                                onChange={() => { setDisabledQuestion(!disabledQuestion); onDisableQuestion(disabledQuestion ? "false" : "true") }} size="small" />
                              <span>Disable question</span>
                            </div>
                          </div>
                        </div>}
                      <CustomErrorMessages
                        theData={theData}
                        selectedTab={selectedTabCustomErrorMessages}
                        setSelectedTab={(e) => setSelectedTabCustomErrorMessages(e.selected)}
                      />
                    </Fragment>
                  </div>
                </div>
              </div>
              :
              <div className="h-100 w-100 d-flex p-4" style={{ backgroundColor: "rgb(243, 244, 244)" }}>
                <div className="card-body d-flex flex-column h-100 bg-white align-items-center justify-content-center answer-layout">
                  <Icon type="logic" className='pe-none fill-primary select-placeholder' />
                  <h1 className="droppable-text strong mt-2 h4">Select an element to set logic</h1>
                </div>
              </div>
          }
        </div> :
        <FullHeightSpinner />}
    </div >
  )
}