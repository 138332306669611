import { Fragment, useEffect, useMemo, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { ProjectsHeader } from './ProjectsHeader/ProjectsHeader';
import type { Action, ItemAction, searchData } from './ProjectsTypes/projectTypes';
import { Icon } from '../../shared/Icon/Icon';
import type { RootState } from '../../../store/reducers/rootReducer';
import deleteProject from "../../shared/helpers/project/deleteProject/deleteProject";
import ProjectsCards from './ProjectsViews/ProjectsCards';
import ProjectsTable from './ProjectsViews/ProjectsTable';
import ConfirmActionModal from '../../shared/ConfirmActionModal/ConfirmActionModal';
import EditProjectModal from '../EditProjectModal/EditProjectModal';
import AddProjectModal from '../AddProjectModal/AddProjectModal';
import { fetchGetJson as getProjects } from '../../../services/services';

export const Projects = () => {
  document.title = "Projects – Walr";
  const { search } = useLocation();
  const { newProjects, showAddProjectModal, showEditProjectModal, shouldUpdateData, loadMoreToken } = useSelector((state: RootState) => state.projectsReducer);
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const userSettings = useSelector((state: RootState) => state.userSettingsReducer);
  const { user } = useAuth0();
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [itemAction, setItemAction] = useState<ItemAction>({ projectId: null, projectName: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const history = useHistory();

  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  const onProjectActionHandler = (action: Action, projectId: string, projectName: string) => {
    if (action === "edit") {
      dispatch({
        type: "UPDATE_SHOW_EDIT_PROJECT_MODAL",
        payload: { show: true, id: projectId },
      });
    } else if (action === "delete") {
      setShowModal(true);
      setItemAction({ projectId: projectId, projectName: projectName });
    }
  };

  const onSearchSubmit = ({ searchTerm, sort, sortOrder, deleteSearchTerm, deleteSortOrder }: searchData) => {
    dispatch({
      type: "SHOULD_FETCH_PROJECTS",
      payload: true,
    });

    if (!deleteSearchTerm && !deleteSortOrder) {
      if (searchTerm) {
        searchParams.set("searchTerm", `${searchTerm}`);
      }
      if (sort) {
        searchParams.set("sortBy", `${sort}`);
      }
      if (sortOrder) {
        searchParams.set("sortOrder", `${sortOrder}`);
      }
    }

    if (deleteSearchTerm) {
      searchParams.delete("searchTerm");
    }

    if (deleteSortOrder) {
      searchParams.delete("sortBy");
      searchParams.delete("sortOrder");
    }

    history.push({
      pathname: "/projects",
      search: searchParams.toString()
    });
  }

  useEffect(() => {
    if (!search.length) {
      dispatch({
        type: "SHOULD_FETCH_PROJECTS",
        payload: true,
      });
    }
    if (!shouldUpdateData) return;

    setIsLoading(true);
    getProjects(`${search ? `projectsoverview${search}` : "projectsoverview"}`, token)
      .then((res: TODO) => {
        if (res && !res.error && !res.message) {
          if (res.projects.length === 0) {
            dispatch({ type: "SET_LOAD_MORE_TOKEN", payload: "" });
          }
          dispatch({ type: "SET_LOAD_MORE_TOKEN", payload: res.continuationToken });
          dispatch({ type: "UPDATE_PROJECTS_DATA", payload: res.projects });

          setIsLoading(false);

        } else {
          setIsLoading(false);
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error ? res.error : res.message } })
        }
      })
  }, [token, dispatch, search, shouldUpdateData]);

  const onLoadMoreHandler = () => {
    setIsLoadingMore(true)
    getProjects(`projectsoverview?continuationToken=${loadMoreToken}`, token)
      .then((res: TODO) => {
        if (res && !res.error && !res.message) {
          if (res.projects.length === 0) {
            dispatch({ type: "SET_LOAD_MORE_TOKEN", payload: "" });
          }
          dispatch({ type: "SET_LOAD_MORE_TOKEN", payload: res.continuationToken });
          dispatch({ type: "UPDATE_PROJECTS_DATA", payload: [...newProjects, ...res.projects] });
          setIsLoadingMore(false);
        } else {
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error ? res.error : res.message } })
        }
      })
  }

  return (
    <Fragment>
      {showModal && (
        <ConfirmActionModal
          projectName={itemAction.projectName ? itemAction.projectName : ""}
          errorMessage={errorMessage}
          setError={(msg) => setErrorMessage(msg)}
          handleClose={() => setShowModal(false)}
          handleSubmit={() => deleteProject(itemAction.projectId, token)}
          type="project"
        />
      )}

      {showAddProjectModal && (
        <AddProjectModal
          userData={user}
          token={token}
          onHide={() => {
            dispatch({
              type: "UPDATE_SHOW_ADD_PROJECT_MODAL",
              payload: false,
            })
          }}
        />
      )}

      {showEditProjectModal.show && (
        <EditProjectModal
          userData={user}
          projectId={showEditProjectModal.projectId}
          token={token}
          onHide={() =>
            dispatch({
              type: "UPDATE_SHOW_EDIT_PROJECT_MODAL",
              payload: { show: false, id: null },
            })
          }
        />
      )}

      <section className="main projects-container gap-sm">
        <ProjectsHeader
          onSearchSubmit={onSearchSubmit}
          searchParams={searchParams}
        />

        <div className="container-fluid">
          <div className="row border-top px-6 pt-5">
            {userSettings.toggleView ?
              <ProjectsCards
                onProjectActionHandler={onProjectActionHandler}
                isLoading={isLoading}
              />
              :
              <ProjectsTable
                isLoading={isLoading}
                onProjectActionHandler={onProjectActionHandler}
                onSearchSubmit={onSearchSubmit}
                searchParams={searchParams}
              />
            }

          </div>
        </div>


        {!newProjects.length && !isLoading && (
          <div className="d-flex flex-column align-items-center justify-content-center gap-md my-3" style={{ height: "25svh" }}>
            <i className="fal fa-folder-open fa-2x text-muted" />
            <p className="text-muted m-0">
              No projects found
            </p>
          </div>
        )}

        {!isLoading && loadMoreToken &&
          <div className="d-flex justify-content-center w-100 border-top p-4 gap-sm">
            <button type='button' onClick={onLoadMoreHandler} className="btn btn-icon icon-l btn-shadow">
              {isLoadingMore ? <div className="spinner-border spinner-border-sm mr-1" role="status">
                <span className="sr-only">Loading...</span>
              </div> : <Icon type="refresh" className='mr-05'
              />}
              Load more projects
            </button>
          </div>
        }
      </section >
    </Fragment>
  )
}
