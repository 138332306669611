import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from '@progress/kendo-react-tooltip';

import { Icon } from "../../../shared/Icon/Icon";
import { useDebounce } from "../../../shared/customHooks/useDebounce";
import { FullHeightSpinner } from "../../../shared/FullHeightSpinner/FullHeightSpinner";

import { fetchPatchJson } from "../../../../services/services";

interface Props {
  token: string
  showSidebar: { show: boolean, type: string }
  setShowSidebar: (obj: { show: boolean, type: string }) => void
}

interface FormData {
  title: string
  description: string
  url: string
  protected: boolean
  password: string
}

const SidebarSettingsContent = ({ token, showSidebar, setShowSidebar }: Props) => {
  const dispatch = useDispatch();
  const pinboardData = useSelector((theState: any) => theState.pinboardStateReducer)

  const [originalProtectedValue, setOriginalProtectedValue] = useState(pinboardData.publishData.protected);
  const [loadingProtected, setLoadingProtected] = useState(false);
  const [formData, setFormData] = useState<FormData>({
    title: pinboardData.previewTitle ? pinboardData.previewTitle : "",
    description: pinboardData.previewDescription ? pinboardData.previewDescription : "",
    url: `http://${window.location.href.split("/")[2]}/public/pinboards/${pinboardData.publishData.path}`,
    protected: pinboardData.publishData.protected,
    password: "",
  })
  const [shareData, setShareData] = useState({ ...pinboardData.publishData })

  useEffect(() => {
    setShareData({ ...pinboardData.publishData })
  }, [pinboardData.publishData])

  const valueChangeHander = (value: string | boolean, type: any) => {
    const updatedFormData = { ...formData }

    if (type === "protected") {
      setLoadingProtected(true);
      const body = {
        "protected": false,
        "enabled": true,
        "_etag": shareData._etag
      }

      fetchPatchJson(`pb/projects/${pinboardData.projectId}/pinboards/${pinboardData.pinboardId}/shareLink`, token, body)
        .then((result: any) => result.json())
        .then((res: any) => {
          if (res.error || res.message) {
            dispatch({ type: "SHOW_ERROR_NOTIFICATION", payload: { msg: res.error ? res.error : res.message } });
            setLoadingProtected(false);
          } else {
            setShareData(res)
            setOriginalProtectedValue(res.protected)
            setLoadingProtected(false);
            setFormData({ ...updatedFormData, password: "", [type]: value })
            dispatch({ type: "SHOW_ACTION_NOTIFICATION", payload: { msg: "Password requirement has been removed" } });
          }
        })
    } else if (type === "password") {
      setFormData({ ...updatedFormData, password: value.toString(), protected: value ? true : false })
    } else {
      setFormData({ ...updatedFormData, [type]: value })
    }
  }

  const updatePinboard = useDebounce(() => {
    if ((formData.title && formData.title !== pinboardData.previewTitle) || (formData.description && formData.description !== pinboardData.previewDescription)) {
      const body = {
        "previewTitle": formData.title ? formData.title : null,
        "previewDescription": formData.description ? formData.description : null,
      }

      fetchPatchJson(`pb/projects/${pinboardData.projectId}/pinboards/${pinboardData.id}`, token, body)
        .then((res: any) => {
          if (res.error || res.message) {
            dispatch({ type: "SHOW_ERROR_NOTIFICATION", payload: { msg: res.error ? res.error : res.message } });
          } else {
            dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: "Pinboard settings have been successfully updated" } })
          }
        })
    }

    if (originalProtectedValue !== formData.protected) {
      const body = {
        "protected": formData.protected,
        "password": formData.password,
        "enabled": true,
        "_etag": shareData._etag
      }

      fetchPatchJson(`pb/projects/${pinboardData.projectId}/pinboards/${pinboardData.pinboardId}/shareLink`, token, body)
        .then((result: any) => result.json())
        .then((res: any) => {
          if (res.error || res.message) {
            dispatch({ type: "SHOW_ERROR_NOTIFICATION", payload: { msg: res.error ? res.error : res.message } });
            setLoadingProtected(false);
          } else {
            setShareData(res)
            setLoadingProtected(false);
            setOriginalProtectedValue(res.protected)
          }
        })
    }
  }, 1500)

  return (
    <Fragment>
      <div className="header border-bottom flex-shrink-0">
        <span className="ml-1">Settings</span>
        <Tooltip openDelay={100} position='bottom' anchorElement={'target'}>
          <button className="btn btn-transparent p-1" onClick={() => setShowSidebar({ show: false, type: showSidebar.type })} title="Close"><Icon type="close" className="pe-none" /></button>
        </Tooltip>
      </div>
      <article className="d-flex flex-column h-100 overflow-auto">
        <section className="settings-section">
          <div className="d-flex justify-content-between">
            <span className="d-flex strong">General</span>
          </div>
          <div>
            <div className="mb-2">Title</div>
            <input type="text" className="sidebar-input form-control" value={formData.title} onChange={(e) => valueChangeHander(e.target.value, "title")} placeholder="Enter title"></input>
          </div>
          <div>
            <div className="mb-2">Description</div>
            <textarea rows={5} className="sidebar-input form-control no-resize" value={formData.description} onChange={(e) => valueChangeHander(e.target.value, "description")} placeholder="Enter description" ></textarea>
          </div>
        </section>
        <hr className="w-100 m-0"></hr>
        <section className="settings-section">
          <div className="d-flex justify-content-between">
            <span className="d-flex strong">Restrict access</span>
          </div>
          {!loadingProtected ?
            originalProtectedValue ?
              <div className="d-flex flex-column">
                <span className="w-100 p-2 mb-2 d-flex justify-content-center align-items-center" style={{ backgroundColor: "#3365DE33" }}><Icon type="lock"></Icon> Password has been set</span>
                <button className="btn btn-shadow mb-2 w-100" disabled={!pinboardData.publishData.published} onClick={() => valueChangeHander(false, "protected")}>Remove password</button>
                {pinboardData.publishData.published ?
                  <span className="d-flex text-muted small w-100">Keep your pinboard private and only allow authorised users to access it.</span> :
                  <span className="d-flex text-muted small w-100">Pinboard needs to be published before you can change restriction settings.</span>}
              </div> :
              <Fragment>
                <span>New password</span>
                <input type="text" disabled={!pinboardData.publishData.published} placeholder={"Enter new password"} className="sidebar-input form-control my-2" value={formData.password} onChange={(e) => valueChangeHander(e.target.value, "password")}></input>
                {pinboardData.publishData.published ?
                  <span className="d-flex text-muted small w-100">Keep your pinboard private and only allow authorised users to access it.</span> :
                  <span className="d-flex text-muted small w-100">Pinboard needs to be published before you can change restriction settings.</span>}
              </Fragment> :
            <FullHeightSpinner />
          }
        </section>
      </article>
      <footer className="d-flex self-align-end flex-shrink-0">
        <button className="btn btn-secondary w-50 justify-content-center rounded-0 p-2" onClick={() => setShowSidebar({ show: false, type: showSidebar.type })}>Cancel</button>
        <button className="btn btn-primary w-50 justify-content-center rounded-0 p-2"
          onClick={() => {
            if (originalProtectedValue !== formData.protected) {
              setLoadingProtected(true);
            }
            updatePinboard()
          }}>Save</button>
      </footer>
    </Fragment >
  )
}

export default SidebarSettingsContent;
