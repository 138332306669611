const PROJECT_DOCUMENTS_MENU_ITEM_ACTIONS = {
  project: [
    {
      text: 'Edit',
      icon: 'fa far fa-pen',
      type: 'project',
    },
    {
      text: 'Delete',
      icon: 'fa far fa-trash-alt',
      type: 'project',
    },
  ],
  document: {
    datasetsv2: [
      {
        text: 'Analyze',
        icon: 'fa far fa-magic',
        type: 'document',
      },
      {
        text: 'Edit',
        icon: 'fa far fa-pen',
        type: 'document',
      },
      {
        text: 'Delete',
        icon: 'fa far fa-trash-alt',
        type: 'document',
      },
    ],
    datasets: [
      {
        text: 'Analyze',
        icon: 'fa far fa-magic',
        type: 'document',
      },
      {
        text: 'Export',
        icon: 'fa far fa-file-export',
        type: 'document',
      },
      {
        text: 'Edit',
        icon: 'fa far fa-pen',
        type: 'document',
      },
      {
        text: 'Edit data',
        icon: 'fa far fa-pen',
        type: 'surveys',
      },
      {
        text: 'Delete',
        icon: 'fa far fa-trash-alt',
        type: 'document',
      },
    ],
    surveys: [
      {
        text: 'Open',
        icon: 'fa far fa-external-link-alt',
        type: 'document',
      },
      {
        text: 'Edit',
        icon: 'fa far fa-pen',
        type: 'document',
      },
      {
        text: 'Copy',
        icon: 'fa far fa-clone',
        type: 'surveys',
      },
      {
        text: 'Delete',
        icon: 'fa far fa-trash-alt',
        type: 'surveys',
      },
    ],
    publishedSurveys: [
      {
        text: 'Open',
        icon: 'fa far fa-external-link-alt',
        type: 'document',
      },
      {
        text: 'Analyze',
        icon: 'fa far fa-magic',
        type: 'document',
      },
      {
        text: 'Export',
        icon: 'fa far fa-file-export',
        type: 'document',
      },
      {
        text: 'Edit',
        icon: 'fa far fa-pen',
        type: 'document',
      },
      {
        text: 'Copy',
        icon: 'fa far fa-clone',
        type: 'surveys',
      },
      {
        text: 'Delete',
        icon: 'fa far fa-trash-alt',
        type: 'surveys',
      },
      {
        text: 'Edit data',
        icon: 'fa far fa-pen',
        type: 'surveys',
      },
      {
        text: 'Status report',
        icon: 'fa far fa-file-export',
        type: 'surveys',
      },
      {
        text: 'Create dataset',
        icon: 'fa far fa-file-export',
        type: 'document',
      },
    ],
    pinboards: [
      {
        text: 'Open',
        icon: 'fa far fa-external-link-alt',
        type: 'document',
      },
      {
        text: 'Edit',
        icon: 'fa far fa-pen',
        type: 'document',
      },
      {
        text: 'Delete',
        icon: 'fa far fa-trash-alt',
        type: 'document',
      },
    ],
    workflows: [
      {
        text: 'Open',
        icon: 'fa far fa-external-link-alt',
        type: 'workflows',
      },
      {
        text: 'Edit',
        icon: 'fa far fa-pen',
        type: 'workflows',
      },
      {
        text: 'Delete',
        icon: 'fa far fa-trash-alt',
        type: 'workflows',
      },
    ],
    files: [
      {
        text: 'Edit',
        icon: 'fa far fa-pen',
        type: 'document',
      },
      {
        text: 'Download',
        icon: 'fa far fa-download',
        type: 'document',
      },
      {
        text: 'Delete',
        icon: 'fa far fa-trash-alt',
        type: 'document',
      },
    ],
    samples: [
      {
        text: 'Open',
        icon: 'fa far fa-external-link-alt',
        type: 'samples',
      },
      {
        text: 'Edit',
        icon: 'fa far fa-pen',
        type: 'samples',
      },
      {
        text: 'Delete',
        icon: 'fa far fa-trash-alt',
        type: 'samples',
      },
    ],
    audiences: [
      {
        text: 'Clone',
        icon: 'fa far fa-external-link-alt',
        type: 'samples',
      },
    ],
  },
  dataOperations: [
    {
      text: 'Duplicate',
      icon: 'fa far fa-external-link-alt',
      type: 'workflows',
    },
    {
      text: 'Enable',
      icon: 'fa far fa-pen',
      type: 'workflows',
    },
    {
      text: 'Disable',
      icon: 'fa far fa-pen',
      type: 'workflows',
    },
    {
      text: 'Delete',
      icon: 'fa far fa-trash-alt',
      type: 'workflows',
    },
  ],
};

export default PROJECT_DOCUMENTS_MENU_ITEM_ACTIONS;
