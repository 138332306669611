import {
  fetchGetJson,
  fetchPutResOrJson,
} from './../../../../services/services';

export const downloadInboxItemHandler = (
  filePath: string,
  dispatch: any,
  item: any,
  token: string
) => {
  if (item.type === 'GatekeeperExport') {
    fetchGetJson(item.link.slice(1), token).then((res: any) => {
      if (res && !res.error && !res.message) {
        const link = document.createElement('a');
        link.href = res.url;
        link.download = res.url.substring(filePath.lastIndexOf('/') + 1);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    });
  } else if (item.type === 'Reconciliation') {
    fetchGetJson(item.link, token).then((res: any) => {
      if (res && !res.error && !res.message) {
        const link = document.createElement('a');
        link.href = res.url;
        link.download = res.url.substring(filePath.lastIndexOf('/') + 1);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    });
  } else {
    const link = document.createElement('a');
    link.href = filePath;
    link.download = filePath.substring(filePath.lastIndexOf('/') + 1);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  if (!item.isRead) {
    fetchPutResOrJson(`inboxes/items/${item.id}`, token, { isRead: true }).then(
      (res: any) => {
        if (res.status === 200) {
          dispatch({
            type: 'SHOW_ACTION_NOTIFICATION',
            payload: { msg: 'The file has been exported successfuly.' },
          });
        }
      }
    );
  } else {
    dispatch({
      type: 'SHOW_ACTION_NOTIFICATION',
      payload: { msg: 'The file has been exported successfuly.' },
    });
  }
};
