const IMPORT_OPTIONS = [
  {
    title: 'Spss',
    icon: 'fas fa-file',
  },
  {
    title: 'Excel',
    icon: 'fas fa-file-excel',
  },
  {
    title: 'Triple S',
    icon: 'fas fa-file-alt',
  },
  {
    title: 'Miprox',
    icon: 'fas fa-file-code',
  },
];

export default IMPORT_OPTIONS;
