/// <reference types="vite/client" />
import { useState } from "react";

import headerThemes from "./headerThemes";
import type { User } from "../../interfaces/userInterface/userInterface";
import { returnGreetingText } from "./dashboardHelpers/dashboardHelpers";
import { DropdownButton } from "../shared/DropdownButton/DropdownButton";
import { DashboardUserData } from "./DashboardUserData/DashboardUserData";
import { DashboardCountData } from "./DashboardCountData/DashboardCountData";

interface Props {
  user: User;
}

export const Dashboard = ({ user }: Props) => {
  document.title = "Home – Walr";
  const savedTheme = localStorage.getItem("dashboardHeaderTheme");
  const [activeTheme, setActiveTheme] = useState<string>(savedTheme ? savedTheme : headerThemes.delight[0]);

  const greeting: string = returnGreetingText();

  const onHeaderThemeItemClick = (header: string) => {
    setActiveTheme(header);
    localStorage.setItem("dashboardHeaderTheme", header);
  };

  const returnItem = (item: TODO) => {
    if (item.text === "editTheme") {
      return (
        <div className="d-flex flex-column position-relative">
          <p className="edit-theme-title border-bottom">Choose a theme</p>
          <p className="mb-2 px-4 text-muted">Delight</p>
          <div className="d-flex gap-md px-4 mb-3">
            {headerThemes.delight.map((header, key) => (
              <div
                key={key}
                onClick={() => onHeaderThemeItemClick(header)}
                className={`edit-item ${header} ${header === activeTheme ? "active-edit-item" : "cursor-pointer"}`}
              />
            ))}
          </div>
          <p className="mb-2 px-4 text-muted">Dataverse</p>
          <div className="d-flex gap-md px-4 mb-3">
            {headerThemes.dataverse.map((header, key) => (
              <div
                key={key}
                onClick={() => onHeaderThemeItemClick(header)}
                className={`edit-item ${header} ${header === activeTheme ? "active-edit-item" : "cursor-pointer"}`}
              />
            ))}
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <section className="dashboard main w-100 gap-xl">
      <div className={`dashboard-theme ${activeTheme}`}>
        <div className={"container"}>
          <div className="row">
            <div className="dashboard-header">
              <DropdownButton
                hideChevron
                text="Theme"
                className="btn-edit"
                icon="dashboard-edit"
                items={[{ text: "editTheme" }]}
                renderItem={returnItem}
              />
                <h1 className="name" data-v={__APP_VERSION__}>
                  Good {greeting}, {localStorage.getItem('ImpersonateName') ? localStorage.getItem('ImpersonateName') : user.given_name}
                </h1>
              <h2 className="sub">
                Here's what's happening in your Walr account.
              </h2>
              <DashboardCountData />
            </div>
          </div>
        </div>
      </div>
      <DashboardUserData user={user} />
    </section>
  );
};
