import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

import exportDocumentInputs from './exportDocumentInputs';
import { ErrorMessage } from '../../../../shared/ErrorMessage/ErrorMessage';
import { ExportDocumentTabContent } from './ExportDocumentTabContent/ExportDocumentTabContent';
import { returnExportDocumentBody } from '../../../../shared/helpers/returnExportDocumentBody/returnExportDocumentBody';
import type { ExportDocumentFormData } from '../../../../../interfaces/projectDetailsInterfaces/projectDetailsInterfaces';
import { fetchPostResOrJson as queueExportDocument } from '../../../../../services/services';

interface Props {
  token: string
  onHide: () => void
  itemAction: {
    id: string
    type: string
    projectId: string
  }
}

export const ExportProjectDocumentModal = ({ token, itemAction, onHide }: Props) => {
  const dispatch = useDispatch()
  const exportDocumentInputsCopy: TODO = exportDocumentInputs
  const [exportType, setExportType] = useState<number>(0)
  const [showErrorMessage, setShowErrorMessage] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [formData, setFormData] = useState<ExportDocumentFormData>({
    useIdAsLabel: true,
    includeLogin: true,
    formatType: "Default",
    useMultiValues: true,
    language: null
  })

  useEffect(() => {
    if (exportType !== 0) {
      setFormData(formData => ({
        ...formData,
        useMultiValues: false
      }))
    } else {
      setFormData(formData => ({
        ...formData,
        useMultiValues: true
      }))
    }
  }, [exportType])

  const onChangeHandler = (e: TODO) => {
    setFormData({
      ...formData,
      [e.target.props.id]: e.target.value
    })
  }

  const onQueueExportHandler = () => {
    setIsLoading(true);
    const body = returnExportDocumentBody(exportType, formData)
    queueExportDocument(`projects/${itemAction.projectId}/${itemAction.type}/${itemAction.id}/export`, token, body)
      .then((res: TODO) => {
        setIsLoading(false);
        if (res.status === 202) {
          dispatch({ type: 'SHOW_PENDING_NOTIFICATION', payload: { msg: 'The document has been queued for export' } })
          onHide()
        } else {
          setShowErrorMessage(res.error)
        }
      })
  }

  const selectExportType = (value: number) => {
    setExportType(value)
  }

  return (
    <Dialog className="import-dialog" minWidth={700} title={"Export document"} onClose={onHide}>

      <ExportDocumentTabContent
        exportType={exportType}
        exportDocumentInputsCopy={exportDocumentInputsCopy}
        formData={formData}
        onChangeHandler={onChangeHandler}
        selectExportType={(key) => selectExportType(key)} />

      {
        showErrorMessage &&
        <div className="mx-3">
          <ErrorMessage
            type="alert"
            errorMessage={"Something went wrong"} />
        </div>
      }

      <DialogActionsBar>
        <button type='button' className="k-button k-button-solid-base" onClick={onHide}>Close</button>
        <button type='button' disabled={isLoading} className="k-button k-button-solid-primary" onClick={onQueueExportHandler}>Queue export</button>
      </DialogActionsBar>
    </Dialog >
  )
}
