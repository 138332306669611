import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import TextareaAutosize from 'react-textarea-autosize';
import { Tooltip } from '@progress/kendo-react-tooltip';

import { useDebounce } from '../../../../../../shared/customHooks/useDebounce';
import { SurveyFilterBuilder } from '../../../SurveyFilterBuilder/SurveyFilterBuilder';
import { DropdownButton } from '../../../../../../shared/DropdownButton/DropdownButton';
import SurveyQuillEditor from '../../../../../../shared/SurveyQuillEditor/SurveyQuillEditor';
import { InProgressOverlay } from '../../../../../../shared/InProgressOverlay/InProgressOverlay';
import { ValidationErrorMessage } from '../../../helpers/ValidationErrorMesage/ValidationErrorMessage';
import { returnReversedIndex } from '../../../../../../shared/SurveyQuillEditor/SurveyQuillEditorHelpers';
import { TextFilterDropdownButton } from '../../../helpers/TextFilterDropdownButton/TextFilterDropdownButton';
import { Icon } from '../../../../../../shared/Icon/Icon';
import { PreviewTabContent } from '../../PreviewTabContent/PreviewTabContent';
import { cleanHtmlEntities } from '../../../../../../shared/helpers/cleanHtmlEntities/cleanHtmlEntities';
import { fetchGetJson } from '../../../../../../../services/services';
import { prepareLanguagesDropDown } from '../../helpers';

export const EditTabInfoAndQuotastopElementContent = ({
  activeRef, onDropdownItemClick, selectedLanguage, onLanguageChange, onSaveChanges, labelInvalid, setLabelInvalid, quotaStopLabel, setQuotaStopLabel
}) => {
  const params = useParams()
  const dispatch = useDispatch()
  const { token } = useSelector((state) => state.tokenStateReducer);
  const { theData } = useSelector((theState) => (theState.surveyInitialDataReducer))
  const [selectedTab, setSelectedTab] = useState('edit')
  const [didMount, setDidMount] = useState(true)
  const [showFilterBuilder, setShowFilterBuilder] = useState({ display: false, filterData: [], primaryIndex: null })
  const editTabToolbarItems = [
    { text: 'Paste pad', icon: "survey-paste-pad" },
    { text: 'Insert JavaScript', icon: "survey-js" },
    { text: 'XML Scripting', icon: "survey-xml" },
    { text: 'Insert CSS', icon: "survey-css" },
    { text: 'Edit tags', icon: "tags" },
  ]

  const saveWithDebounce = useDebounce(() => dispatch({ type: 'SAVE_QUESTION' }), 1000);
  const languageList = useMemo(() => prepareLanguagesDropDown(theData.originalData?.languages), [theData.originalData.languages]);

  useEffect(() => {
    if (didMount) {
      if (theData.selectedItem?.type === "quotastop") {
        setDidMount(false)
        let allElementsBeforeSelected = []
        theData.originalData.elements.forEach((section) => {
          allElementsBeforeSelected = [...allElementsBeforeSelected, ...section.elements]
        })
        const selectedItemIndex = allElementsBeforeSelected.findIndex(el => el.referenceId === theData.selectedItem.id)               // Filter returns elements which are above(lower index) selected item
        const availableQuotas = allElementsBeforeSelected.filter((el, index) => index < selectedItemIndex && el.type === 'quota')     // Filter returns elements which are above(lower index) selected item and are of type quota                                                  
        dispatch({ type: "UPDATE_QUOTASTOP_REFERENCE_QUOTA", mode: "availableQuotas", payload: availableQuotas })                     // Sets available quotas to theData.selectedItem.referenceQuotas.availableQuotas, needed in the dropdown as data

        if (theData.selectedItem.attributes?.quota) {
          const availableQuotas = theData.originalData.elements[theData.selectedItem.section].elements.filter(el => el.type === "quota");
          const currentlySetQuota = availableQuotas.find(el => el.referenceDisplayLabel === theData.selectedItem.attributes.quota);
          dispatch({ type: "UPDATE_QUOTASTOP_REFERENCE_QUOTA", mode: "setQuota", payload: currentlySetQuota })    // Sets currently set quota to theData.selectedItem.referenceQuotas.setQuota, needed in the dropdown as value
        } else {
          dispatch({ type: "UPDATE_QUOTASTOP_REFERENCE_QUOTA", payload: {} })                                     // Sets currently set quota to theData.selectedItem.referenceQuotas.setQuota to empty, needed in the dropdown as value
        }
        if (theData.selectedItem.attributes?.id) {
          setQuotaStopLabel(theData.selectedItem.attributes.id);
        } else {
          setQuotaStopLabel("");
        }
      }
    }
  }, [didMount, theData.selectedItem?.type])

  const findSelectedText = (textData) => {
    if (theData.dropdownValues.length > 0) {
      const textMatch = theData.dropdownValues.find(item => item.name === 'textType').enumValues.find(item => item.name === textData.attributes.textType)
      if (textMatch) {
        return textMatch.text
      }
      return ' '
    }
    return ' '
  }

  const onAddDeleteInfoTextHandler = (action, itemIndex) => {
    const updatedSelectedItem = JSON.parse(JSON.stringify(theData.selectedItem))
    if (action === 'add') {
      updatedSelectedItem.elements.push({
        type: "text",
        disabled: false,
        published: false,
        attributes: {},
        textValues: {
          [theData.editingLanguage]: ""
        }
      })
    } else if (action === 'delete') {
      updatedSelectedItem.elements.splice(itemIndex, 1)
    }
    dispatch({ type: 'ON_EXPAND_SUBQUESTION', payload: updatedSelectedItem })
    saveWithDebounce()
  }

  const onTextTypeChange = (item, textIndex) => {
    if (item.text === 'Add text filter') {
      showFilterBuilderHandler('filter', 'infoText', textIndex)
    } else {
      const updatedSelectedItem = JSON.parse(JSON.stringify(theData.selectedItem))
      const attributesCopy = { ...updatedSelectedItem.elements[textIndex].attributes }
      updatedSelectedItem.elements[textIndex].attributes = { ...attributesCopy, textType: item.name }
      dispatch({ type: 'ON_EXPAND_SUBQUESTION', payload: updatedSelectedItem })
      dispatch({ type: 'SAVE_QUESTION' })
    }
  }

  const onTextChangeHandler = (value, textIndex) => {
    const updatedSelectedItem = JSON.parse(JSON.stringify(theData.selectedItem))
    updatedSelectedItem.elements[textIndex].textValues[theData.editingLanguage] = cleanHtmlEntities(value)
    dispatch({ type: 'ON_EXPAND_SUBQUESTION', payload: updatedSelectedItem })
    saveWithDebounce()
  }

  const onQuotaStopLabelChange = (e) => {
    const updatedSelectedItem = JSON.parse(JSON.stringify(theData.selectedItem))
    const attributesCopy = { ...updatedSelectedItem.attributes }
    updatedSelectedItem.attributes = { ...attributesCopy, id: e.target.value }
    setQuotaStopLabel(e.target.value)
    dispatch({ type: 'ON_EXPAND_SUBQUESTION', payload: updatedSelectedItem })
    saveWithDebounce()
  }

  const onQuotaReferenceChange = ({ item }) => {
    const updatedSelectedItem = JSON.parse(JSON.stringify(theData.selectedItem))
    const referenceQuotasCopy = updatedSelectedItem.referenceQuotas ? updatedSelectedItem.referenceQuotas : {}
    const referenceAttributesCopy = updatedSelectedItem.attributes ? updatedSelectedItem.attributes : {}
    updatedSelectedItem.attributes = { ...referenceAttributesCopy, quota: item.referenceDisplayLabel }
    updatedSelectedItem.referenceQuotas = { ...referenceQuotasCopy, setQuota: item }
    dispatch({ type: 'ON_EXPAND_SUBQUESTION', payload: updatedSelectedItem })
    saveWithDebounce()
  }

  const showFilterBuilderHandler = (option, type, primaryIndex, secondaryIndex, expression) => {
    const element = JSON.parse(JSON.stringify(theData.originalSelectedItem))
    fetchGetJson(`su/projects/${params.name}/surveys/${params.survey}/structure`, token)
      .then((res) => {
        if (res && !res.error && !res.message) {
          setShowFilterBuilder({
            display: true,
            filterData: res,
            type: type,
            primaryIndex: primaryIndex,
            secondaryIndex: secondaryIndex,
            filterBuilderOptionId: option,
            manualFilterExpression: expression,
            combineFilterData: element.metadata?.filterData ? element.metadata.filterData : []
          })
        } else {
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: `${res.error ? res.error : res.message}` } })
        }
      })
  }

  const onSaveTextFilter = (expression) => {
    const updatedSelectedItem = JSON.parse(JSON.stringify(theData.selectedItem))
    updatedSelectedItem.elements[showFilterBuilder.primaryIndex].attributes.filter = expression
    dispatch({ type: 'ON_EXPAND_SUBQUESTION', payload: updatedSelectedItem })
    dispatch({ type: 'SAVE_QUESTION' })
    setShowFilterBuilder({ display: false, filterData: [], primaryIndex: null, secondaryIndex: null, manualFilterExpression: null })
  }

  const onChangeInfoLabel = (value) => {
    const updatedSelectedItem = JSON.parse(JSON.stringify(theData.selectedItem))
    updatedSelectedItem.attributes.id = value
    dispatch({ type: 'ON_EXPAND_SUBQUESTION', payload: updatedSelectedItem })
    saveWithDebounce()
  }

  return (
    <React.Fragment>
      {
        showFilterBuilder.display &&
        <SurveyFilterBuilder
          handleClose={() => setShowFilterBuilder({ display: false, filterData: [], primaryIndex: null, manualFilterExpression: null })}
          filterData={showFilterBuilder.filterData}
          surveyId={theData.originalData.surveyId}
          manualFilterExpression={showFilterBuilder.manualFilterExpression}
          combineFilterData={[]}
          option='filter'
          token={token}
          type="infoText"
          onSaveFilter={onSaveTextFilter}
        />
      }
      <div className="card w-100 h-100" style={{ backgroundColor: "rgb(243, 244, 244)" }}>
        {theData.loadingMessage && theData.loadingState &&
          <InProgressOverlay type="overlay" theme="primary" message={theData.loadingMessage} />
        }
        <div className="card-header d-flex flex-column pr-3 pl-4 h-48 rounded-0">
          <div className="d-flex justify-content-between flex-fill h-100">
            <div className="d-flex align-items-center">
              <div className="d-flex align-items-center survey-toolbar">
                {editTabToolbarItems.map((actionItem, index) => {
                  return (
                    <Tooltip anchorElement="target" position="top" key={index}>
                      <button type='button' className={`btn btn-transparent p-05 mr-2 ${(actionItem.icon === "survey-logic" && theData.showLogic) ? "active" : null}`} title={actionItem.text} onClick={() => onDropdownItemClick({ item: actionItem })}>
                        <Icon type={actionItem.icon} className='pe-none' />
                      </button>
                    </Tooltip>
                  )
                })}
                <div className='d-flex h-24 w-1 bg-light mr-2' />
                <DropdownButton
                  className="p-05 btn-transparent"
                  hideChevron={true}
                  menuPosition="left"
                  onItemClick={onDropdownItemClick}
                  items={[{ text: theData.htmlMode ? 'Turn rich text on' : 'Turn rich text off' }, { text: theData.showLogic ? 'Turn logic off' : 'Turn logic on' }]}
                />
              </div>
              <div className="d-flex align-items-center">
                <div className="d-flex align-items-center flex-grow-1">
                  {theData.optionsType && theData.optionsType.type === 'info' && theData.displayOptions === true ?
                    <div ref={activeRef} className="edit-dot pulse mr-2" /> : null}
                </div>
                <div className="d-flex align-items-center flex-wrap">
                  {theData.selectedItem.tags?.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1).map((el, key) =>
                    <small className="badge-tags mr-1" key={key}>{el.name}</small>
                  )}
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <DropdownButton
                icon="globe"
                textField="label"
                className="icon-l btn-transparent"
                hideChevron={true}
                text={`${selectedLanguage?.name ?? ''} (${selectedLanguage?.code})`}
                items={languageList}
                onItemClick={(e) => onLanguageChange(e)} />
            </div>
          </div>
        </div>
        <div className="card-body d-flex flex-column h-100 overflow-auto bg-white answer-layout p-0 m-4">
          {theData.selectedItem.errorMessage !== "" &&
            <ValidationErrorMessage errorMessage={theData.selectedItem.errorMessage} />}
          <div className="card-header d-flex justify-content-end align-items-center py-0 pl-2 pr-3">
            <div className="d-flex align-items-center flex-grow-1 h-100">
              <input
                name='surveyQuestionTitle'
                type="text"
                style={{ minWidth: '150px' }}
                className='form-control survey-question-title'
                placeholder={theData.selectedItem.type === "info" ? "Information" : "Quota stop"}
                value={theData.selectedItem.attributes["id"]}
                onChange={(e) => onChangeInfoLabel(e.target.value)}
              />
            </div>
            <ul className="nav nav-pills flex-nowrap content-tabs ml-3 tabs-48">
              <li onClick={() => setSelectedTab('edit')} className="nav-item"><span className={`nav-link ${selectedTab === 'edit' ? 'active' : ''}`}>Edit</span></li>
              <li onClick={() => setSelectedTab('preview')} className="nav-item"><span className={`nav-link ${selectedTab === 'preview' ? 'active' : ''}`}>Preview</span></li>
            </ul>
          </div >
          {
            selectedTab === 'edit' ?
              <React.Fragment>
                <div className="h-100 w-100 py-5 px-7 position-relative d-flex flex-column">
                  <div className="d-flex flex-row align-items-start">
                    {theData.selectedItem.attributes?.filter && theData.showLogic ?
                      <div className="badge badge-primary strong p-2 mr-2 mb-2">
                        <i className="fas fa-filter fa-sm mr-2" />
                        Filter={theData.selectedItem.attributes.filter}
                      </div>
                      : null
                    }
                  </div>
                  {theData.selectedItem.elements.find(el => el.type === 'text') && theData.selectedItem.elements.find(el => el.type === 'text').errorMessage !== "" &&
                    <ValidationErrorMessage errorMessage={theData.selectedItem.elements.find(el => el.type === 'text').errorMessage} />}

                  {
                    theData.selectedItem.elements.filter(el => el.type === 'text').map((text, textIndex) => (
                      <div key={textIndex} className="d-flex survey-selectedSubQuestion w-100 border-bottom">
                        <div className="d-flex justify-content-between flex-grow-1 inline-editor">
                          <div className="d-flex align-items-center w-100">
                            {!theData.htmlMode ?
                              <SurveyQuillEditor
                                token={token}
                                value={text.textValues[theData.editingLanguage]}
                                setValue={(e) => onTextChangeHandler(e, textIndex)}
                                orderNumber={returnReversedIndex(theData.selectedItem.elements.filter(el => el.type === 'text').length, textIndex)}
                                selectedItemId={theData.selectedItem.id}
                                placeholder={text.attributes?.textType === 'do' ? 'Enter instruction text...' : text.attributes?.textType === 'say' ? 'Enter supplementary text...' : 'Enter text...'} />
                              :
                              <TextareaAutosize
                                cacheMeasurements
                                style={{ resize: 'none' }}
                                placeholder={text.attributes?.textType === 'do' ? 'Enter instruction text...' : text.attributes?.textType === 'say' ? 'Enter supplementary text...' : 'Enter text...'}
                                className='form-control border-0 shadow-none question-text'
                                value={text.textValues[theData.editingLanguage]}
                                onChange={(e) => onTextChangeHandler(e.target.value, textIndex)}
                              />
                            }
                          </div >
                          <div className="d-flex align-items-center">
                            {
                              theData.dropdownValues.length > 0 &&
                              <DropdownButton
                                items={[...theData.dropdownValues.find(item => item.name === 'textType').enumValues, { text: 'separator' }, { text: 'Add text filter', idx: 3 }]}
                                className={`btn-secondary p-0 mr-2 ${findSelectedText(text) === ' ' ? '' : 'pl-2'}`}
                                text={findSelectedText(text)}
                                onItemClick={(e) => onTextTypeChange(e.item, textIndex)}
                              />
                            }
                            <Tooltip className='answer-layout-table-tooltip' anchorElement="target" position="top">
                              <button
                                type='button'
                                title={text.attributes?.filter ? `${text.attributes.filter}` : "Add filter"}
                                onClick={() => showFilterBuilderHandler('filter', "infoText", textIndex, undefined, text.attributes?.filter)}
                                className={`btn ${text.attributes?.filter ? "btn-survey-light-blue text-survey-dark-blue" : "btn-secondary"} py-1 px-2 filter`}>
                                F
                              </button>
                            </Tooltip>
                            <button type='button' onClick={() => onAddDeleteInfoTextHandler(textIndex === 0 ? 'add' : 'delete', textIndex)} className="btn btn-secondary p-0 ml-2 border-0">
                              <span title={'Add text'}><Icon className='pe-none' type={`${textIndex === 0 ? 'add' : 'minus'}`} /></span>
                            </button>
                          </div>
                        </div >
                      </div >
                    ))
                  }

                  {
                    theData.selectedItem.type === "quotastop" &&
                    <div className="d-flex flex-column mt-3">
                      <div className='survey-option mb-3' >
                        <span className='medium'>Quota reference</span>
                        <DropdownButton
                          items={theData.selectedItem.referenceQuotas?.availableQuotas}
                          textField="referenceDisplayLabel"
                          className='btn-block'
                          parentClass='form-control p-0 mt-1'
                          text={theData.selectedItem.referenceQuotas?.setQuota ? theData.selectedItem.referenceQuotas?.setQuota.referenceDisplayLabel : ' '}
                          onItemClick={onQuotaReferenceChange}
                        />
                      </div>
                      <form onSubmit={(e) => { e.preventDefault(); onSaveChanges() }} className="d-flex flex-column">
                        <label htmlFor="quotaStopLabel" className="mb-1 medium">Quota stop label</label>
                        <div className="d-flex">
                          <input
                            name="quotaStopLabel"
                            className="w-100 form-control"
                            pattern="((?=.*[a-z])|(?=.*[A-Z]))([a-zA-Z0-9_])\w+"
                            autoComplete="off"
                            title="Labels can only contain characters a-z, A-Z, 0-9, _ and and has to contain one uppercase or lowercase letter"
                            value={quotaStopLabel}
                            onChange={onQuotaStopLabelChange} />
                        </div>
                      </form>
                    </div>
                  }
                  {
                    theData.selectedItem.type === "quotastop" && labelInvalid &&
                    <div className="d-flex mt-3 align-items-center alert alert-danger alert-dismissible fade show" role="alert">
                      <i className="fas fa-2x fa-exclamation-triangle text-danger pr-3" />
                      <strong className="text-center w-100">
                        <p className="m-0">{labelInvalid}</p>
                      </strong>
                      <button type="button" className="close" onClick={() => setLabelInvalid("")} aria-label="Close">
                        <span>&times;</span>
                      </button>
                    </div>
                  }
                </div >
              </React.Fragment >
              :
              <PreviewTabContent
                token={token}
                type='edit'
                onShowScript={() => { }}
              />
          }

        </div >
      </div >
    </React.Fragment >
  )
}