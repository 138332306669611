const DASHBOARD_KNOWLEDGE_BASE_DATA = [
  {
    title: 'General',
    icon: 'dashboard-help-walr',
    url: 'https://support.walr.com/support/solutions/101000179292',
    description: 'User guides and introduction related to the Walr Platform',
  },
  {
    title: 'Survey Builder',
    icon: 'dashboard-help-survey',
    url: 'https://support.walr.com/support/solutions/101000193206',
    description: 'User guides related to the Walr Survey Builder solution',
  },
  {
    title: 'Data, Analysis & Reporting',
    icon: 'dashboard-help-data',
    url: 'https://support.walr.com/support/solutions/101000193209',
    description:
      "User guides related to Walr's Analysis and Reporting solutions",
  },
  {
    title: 'Distribution',
    icon: 'dashboard-help-distribution',
    url: 'https://support.walr.com/support/solutions/101000193210',
    description: 'User guides related to the Walr Distribution solution',
  },
];

export default DASHBOARD_KNOWLEDGE_BASE_DATA;
