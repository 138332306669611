const defaultOptionValues = [
  {
    topline: {
      TopDecimalAverage: 1,
      TopDecimalPercentage: 1,
      CategoricalBaseAsked: true,
      CategoricalBaseAnswered: true,
      QuantitativeBaseAsked: true,
      QuantitativeBaseAnswered: true,
      TopCatValNonWeightedNumber: true,
      TopCatValAnsweredPercent: true,
      TopQuaValNonWeightedNumber: true,
      TopQuaValAnsweredAvg: true,
    },
  },
  {
    crosstab: {
      XtCategoricalBase: 2,
      XtCatValColPercent: true,
      XtChi2Arrows: 0,
      XtChiSquareAuto: false,
      XtDecimalAccumulated: 1,
      XtDecimalAverage: 1,
      XtDecimalChi2: 1,
      XtDecimalQuantDeriv: 1,
      XtIncludeTotalColumn: true,
      XtPctSignFilt: true,
      XtPctSignHori: true,
      XtPctSignHoriSum: true,
      XtPctSignVert: true,
      XtPctSignVertSum: true,
      XtQno: 1,
      XtQuantitativeBase: 2,
      XtQuaValAverage: true,
    },
  },
  {
    chart: {
      GCategoricalBase: 2,
      GChartSeriesDoc: 1,
      GChartType: 0,
      GChartValues: true,
      GDecimalAverage: 1,
      GDecimalPercentage: 0,
      GIncludeTotalColumn: false,
      GQnoDisplay: 1,
      GQuantitativeBase: 2,
      GSeriesIn: 0,
      GStacked: false,
      GTitleInChart: false,
      GChartHidePctSign: false,
      GChartRevereseCategories: false,
      GChartRevereseSeries: false,
    },
  },
  {
    dig: {
      DigDecimalChi2: 1,
      DigDecimalPercentage: 1,
      DigLimitChi2: 4,
      DigAddress: true,
      DigDescription: true,
      DigHori: false,
      DigVert: true,
      DigNHori: false,
      DigNVert: true,
      DigSize: true,
      DigChi2D: true,
      PctSign: 'hori vert nhori nvert size chi2d',
      DigSortRollSign: 1,
    },
  },
  {
    qt: {
      QtCategoricalBase: 2,
      QtCatValColPercent: true,
      QtDecimalAccumulated: 1,
      QtDecimalAverage: 1,
      QtDecimalPercentage: 0,
      QtDecimalPopulation: 0,
      QtDecimalQuantDeriv: 1,
      QtDecimalSymbol: 0,
      QtPctSignFilt: true,
      QtPctSignHori: true,
      QtPctSignHoriSum: true,
      QtPctSignVert: true,
      QtPctSignVertSum: true,
      QtQno: 1,
      QtQuantitativeBase: 2,
      QtQuaValAutoCode: false,
      QtQuaValStandDev: false,
      QtQuaValAverage: true,
    },
  },
  {
    data: {
      ShowLabels: true,
      HeaderType: 0,
    },
  },
];

export default defaultOptionValues;
