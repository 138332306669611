import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom';

import { Icon } from '../shared/Icon/Icon';
import { Breadcrumb } from '../shared/Breadcrumb/Breadcrumb';
import usePrevious from '../shared/customHooks/usePrevious';
import { ErrorMessage } from '../shared/ErrorMessage/ErrorMessage';
import { SurveyTabContent } from './SurveyTabContent/SurveyTabContent';
import LanguagesList from "../shared/LanguagesList/LanguagesList"
import { FullHeightSpinner } from '../shared/FullHeightSpinner/FullHeightSpinner';
import { SurveyHeaderActions } from './SurveyHeaderActions/SurveyHeaderActions';
import { returnSurveyDesignData } from './SurveyTabContent/SurveyDesignTabContent/helpers/returnDataHelpers/returnDataHelpers';
import { returnEmptyAndDuplicatedCodes } from './SurveyTabContent/SurveyDesignTabContent/helpers/returnEmptyAndDuplicatedCodes/returnEmptyAndDuplicatedCodes';
import { fetchGetJson as getSurveyIndex, fetchGetJson as getSurveyPublishedStatus, fetchPutJson } from '../../services/services';

const tabs = [
  { title: 'Overview', id: 'overview' },
  { title: 'Build', id: 'design' },
  { title: 'Test', id: 'test' },
  { title: 'Launch', id: 'collect' }
]

export const SurveyBuilder = ({ user, token, ...props }) => {
  document.title = "Survey Builder – Walr"
  const dispatch = useDispatch();
  const location = useLocation()
  const params = useParams()

  const { theData, shouldSaveQuestion, shouldSaveIndex } = useSelector(theState => (theState.surveyInitialDataReducer))
  const [isFinishedFetching, setIsFinishedFetching] = useState(false)
  const [tabSelected, setTabSelected] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const locationItems = location.pathname.split('/')
  const activeRouteTitle = locationItems[locationItems.length - 1]
  const projectId = params.name
  const surveyId = params.survey
  const prevShouldSaveQuestion = usePrevious(shouldSaveQuestion)
  const prevShouldSaveIndex = usePrevious(shouldSaveIndex)

  useEffect(() => {
    getSurveyIndex(`su/projects/${projectId}/surveys/${surveyId}/index`, token)
      .then(res => {
        if (res && !res.error && !res.message) {
          const originalData = JSON.parse(JSON.stringify(res))
          originalData.languages.forEach(lang => { // Add language info, a compensation for backend
            const langInfo = LanguagesList.find(el => el.code === lang.code);
            if (langInfo) { lang.name = langInfo.name; lang.native = langInfo.native; }
          });

          const data = returnSurveyDesignData(res);
          const htmlMode = JSON.parse(localStorage.getItem("SURVEY_HTML_MODE"))
          dispatch({ type: 'SET_SURVEY_INITIAL_DATA', payload: { data: data, originalData: originalData, htmlMode: htmlMode } })
          if (res.themeSettings?.id) {
            dispatch({ type: 'SET_THEME_ID', payload: res.themeSettings.id })
          } else {
            dispatch({ type: 'SET_THEME_ID', payload: null })
          }

          // Get published status for the survey after getting the index
          getSurveyPublishedStatus(`su/projects/${projectId}/surveys/${surveyId}`, token)
            .then(surveyRes => {
              if (surveyRes && !surveyRes.error && !surveyRes.message) {
                setIsFinishedFetching(true)
                dispatch({
                  type: 'SET_SURVEY_PUBLISH_DATA', payload: {
                    surveyStatus: surveyRes.surveyStatus,
                    versions: surveyRes.publishedVersions === null ? [] : surveyRes.publishedVersions,
                    dcV2: surveyRes.dataCollectionVersion
                  }
                });

                if (surveyRes.publishedVersions === null || surveyRes.publishedVersions.length === 0) {
                  setTabSelected(1)
                } else {
                  setTabSelected(0)
                }
              } else {
                setErrorMessage(surveyRes.error ? surveyRes.error : surveyRes.message)
              }
            })
        } else {
          setErrorMessage(res.error ? res.error : res.message)
        }
      })
  }, [token, dispatch, projectId, surveyId])

  const handleSelect = (selected) => {
    setTabSelected(selected)
  }

  // Save question
  useEffect(() => {
    if (shouldSaveQuestion && shouldSaveQuestion !== prevShouldSaveQuestion) {
      dispatch({ type: 'SET_SHOULD_SAVE_QUESTION', payload: false })

      if (theData.selectedItem) {
        const { hasEmptyCodes, hasDuplicateCodes } = returnEmptyAndDuplicatedCodes(theData)

        if (!hasDuplicateCodes && !hasEmptyCodes) {
          if (theData.selectedItem.type !== "quota") {
            dispatch({ type: 'SET_AUTOSAVE_STATUS', payload: 'saving' })
            const body = { ...theData.selectedItem, elements: theData.selectedItem.elements, attributes: theData.selectedItem.attributes, }
            body._etag = theData.originalSelectedItem._etag
            fetchPutJson(`su/projects/${projectId}/surveys/${surveyId}/elements/${theData.originalSelectedItem.id}`, token, body)
              .then((res) => {
                if (res.error || res.message) {
                  dispatch({ type: 'SET_AUTOSAVE_STATUS', payload: 'error' })
                  if (res.code === 412) {
                    dispatch({ type: 'SHOW_ETAG_ERROR_NOTIFICATION', payload: { msg: res.error ? res.error : res.message } })
                  } else {
                    dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error ? res.error : res.message } });
                  }
                } else {
                  if (res.indexElement && res.definition) {
                    const updatedOriginalData = JSON.parse(JSON.stringify(theData.originalData))
                    const updatedData = JSON.parse(JSON.stringify(theData.data))
                    const oldIndexItemId = updatedOriginalData.elements[theData.selectedItem.section].elements.findIndex(el => el.referenceId === res.indexElement.referenceId)
                    const oldDataItemId = updatedData[theData.selectedItem.section].elements.findIndex(el => el.id === res.indexElement.referenceId)
                    const oldDataItem = updatedData[theData.selectedItem.section].elements.find(el => el.id === res.indexElement.referenceId)
                    updatedOriginalData.elements[theData.selectedItem.section].elements[oldIndexItemId] = res.indexElement
                    updatedData[theData.selectedItem.section].elements[oldDataItemId] = { ...oldDataItem, id: res.indexElement.referenceId, label: res.indexElement.referenceDisplayLabel, text: res.indexElement.referenceDisplayTexts?.[theData.editingLanguage] ? res.indexElement.referenceDisplayTexts[theData.editingLanguage] : "", type: res.indexElement.type, referenceQuesTypes: res.indexElement.referenceQuesTypes, hasLogic: res.indexElement.hasLogic }
                    dispatch({ type: 'UPDATE_SURVEY_DATA', payload: { data: updatedData, originalData: updatedOriginalData } })
                    setTimeout(() => {
                      dispatch({ type: 'SET_AUTOSAVE_STATUS', payload: '' })
                    }, 1500)
                  }
                }
              })
          }
        } else {
          if (hasEmptyCodes) {
            dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: 'Some of the statements/answers have an empty code value' } })
          } else if (hasDuplicateCodes) {
            dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: 'Some of the statements/answers do not have unique code values' } })
          }
        }
      }
    }
  }, [shouldSaveQuestion, prevShouldSaveQuestion, theData, token, dispatch, projectId, surveyId])

  // Save index
  useEffect(() => {
    if (shouldSaveIndex && shouldSaveIndex !== prevShouldSaveIndex) {
      dispatch({ type: 'SET_SHOULD_SAVE_INDEX', payload: false })
      if (theData.originalData) {
        fetchPutJson(`su/projects/${projectId}/surveys/${surveyId}/index`, token, theData.originalData)
          .then(res => {
            if (res.error || res.message) {
              dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error ? res.error : res.message } });
            } else {
              let originalData = res;
              let data = returnSurveyDesignData(res);
              dispatch({ type: 'UPDATE_SURVEY_DATA', payload: { data: data, originalData: originalData } });
              setTimeout(() => {
                dispatch({ type: 'SET_AUTOSAVE_STATUS', payload: 'hide' })
              }, 3000)
            }
          })
      }
    }
  }, [shouldSaveIndex, prevShouldSaveIndex])

  if (isFinishedFetching) {
    return (
      <section className="main survey-container p-0">
        <div className="app-header">
          <Link
            to={{
              pathname: `${location.pathname
                .split('/')
                .slice(0, location.pathname.length - (location.pathname.length - (2)))
                .join('/')}/${location.pathname.split('/')[2]}`,
            }}
            className='d-flex back-to-project justify-content-center align-items-center w-56 border-right cursor-pointer'>

            <div className='walr'><Icon className='walr pe-none' type="walr" /></div>
            <div className='exit'><Icon className='exit pe-none' type="exit" /></div>
            <span className='tooltip'>Back to project</span>
          </Link>
          <Breadcrumb
            routePath={props.location.pathname}
            activeRouteTitle={activeRouteTitle}
          />
          <ul className="nav nav-pills app-tabs flex-grow-1 justify-content-center">
            {
              tabs.map((el, id) => (
                <li onClick={() => handleSelect(id)} key={id} className="nav-item">
                  <span className={`nav-link ${tabSelected === id && 'active'}`}>{el.title}</span>
                </li>
              ))
            }
          </ul>
          <SurveyHeaderActions
            onFixValidationErrors={() => { handleSelect(1); dispatch({ type: "SET_SHOW_ERROR_LOG", payload: true }) }}
          />
        </div>
        <SurveyTabContent
          tabType={tabSelected !== false ? tabs[tabSelected].id : "0"}
          token={token}
          user={user}
          onFixValidationErrors={() => { handleSelect(1); dispatch({ type: "SET_SHOW_ERROR_LOG", payload: true }) }}
        />
      </section>
    );
  }
  return (
    <section className="main survey-container p-0">
      {
        errorMessage &&
        <ErrorMessage
          type="modal"
          errorMessage={errorMessage}
          onHide={() => setErrorMessage("")}
        />
      }
      <FullHeightSpinner />
    </section>
  )
}
