import type React from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { Dialog, DialogActionsBar, Window } from "@progress/kendo-react-dialogs";

import { reopenUncategorisedAnswers } from "../CodingToolHelpers";
import { ErrorMessage } from "../../../../../../../../shared/ErrorMessage/ErrorMessage";
import { useDebounce } from "../../../../../../../../shared/customHooks/useDebounce";
import { fetchPatch } from "../../../../../../../../../services/services";
import type { RootState } from "../../../../../../../../../store/reducers/rootReducer";

interface ModifyProps {
  type: string
  dispatch: TODO
  editMode: boolean
  existingValues?: { code?: string, text?: string, }
  onHide: () => void
  onConfirm: (obj: { code?: string, text?: string }, keepModalOpen?: boolean) => void
}

export const CodingModifyModal: React.FC<ModifyProps> = ({ type, editMode, existingValues, onHide, onConfirm, dispatch }) => {
  const [inputValues, setInputValues] = useState({ code: "1", text: "" });
  const [windowPosition, setWindowPosition] = useState({ top: 10, left: 10 });
  const savePositionWithDebounce = useDebounce(() => localStorage.setItem('categoryWindowPosition', JSON.stringify(windowPosition)), 1000);

  const initialWindowPosition = localStorage.getItem('categoryWindowPosition') ? JSON.parse(localStorage.getItem('categoryWindowPosition') as string) : { top: 10, left: 10 }

  useMemo(() => {
    setInputValues({
      code: existingValues?.code ? existingValues.code : "1",
      text: existingValues?.text ? existingValues.text : ""
    })
  }, [existingValues])

  const onConfirmHandler = useCallback((inputValues: { code: string, text: string }, keepModalOpen?: boolean) => {
    if (!inputValues.text) {
      dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: "Text cannot be empty" } })
    } else if (!inputValues.code && type !== "definition") {
      dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: "Please input a code" } })
    } else {
      onConfirm(inputValues, keepModalOpen);
    }
  }, [type, dispatch, onConfirm])

  useEffect(() => {
    const handleEnterKeyPress = (e: TODO) => {
      if (!editMode && type === "category") {
        if ((e.ctrlKey && e.key === "Enter") || (e.metaKey && e.key === "Enter")) {
          dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: "Category created" } })
          setInputValues({ code: inputValues.code + 1, text: "" })
          onConfirmHandler(inputValues, true)
        } else if (e.key === "Enter") {
          onConfirmHandler(inputValues, false)
        }
      }
    }

    document.addEventListener("keydown", handleEnterKeyPress);
    return () => document.removeEventListener("keydown", handleEnterKeyPress);
  }, [inputValues, onConfirmHandler, dispatch, editMode, type])

  return (
    <Window
      title={`${editMode ? `Edit ${type}` : type === "merge" ? "Merge categories" : `Add ${type}`}`}
      onClose={onHide}
      resizable={false}
      minimizeButton={() => <span className="d-none" />}
      maximizeButton={() => <span className="d-none" />}
      className="coding-tool-category-modal"
      initialTop={initialWindowPosition.top}
      initialLeft={initialWindowPosition.left}
      onMove={({ top, left }: TODO) => { setWindowPosition({ top: top, left: left }); savePositionWithDebounce() }}
      style={{ height: 'auto' }}>
      <div className="p-3">
        <div className="d-flex flex-column">
          {type !== "definition" &&
            <div className="d-flex flex-column mb-3">
              <div className="mb-1">Code</div>
              <input
                min={0}
                type="number"
                className="form-control"
                value={inputValues.code}
                onChange={(e) => setInputValues({ ...inputValues, code: e.target.value })}
              />
            </div>
          }
          <div className="d-flex flex-column">
            <div className="mb-1">{`${type !== "definition" ? "Name" : "Text"}`}</div>
            {
              type === "definition" ?
                <textarea className="form-control"
                  rows={4}
                  autoFocus={true}
                  value={inputValues.text}
                  onChange={(e) => setInputValues({ ...inputValues, text: e.target.value })}
                  placeholder={"Enter definition text"}>
                </textarea>
                :
                <input
                  autoFocus={true}
                  value={inputValues.text}
                  placeholder={"Enter code name"}
                  className="form-control"
                  onChange={(e) => setInputValues({ ...inputValues, text: e.target.value })}
                />
            }
            {
              !editMode && type === "definition" &&
              <span className="w-100 text-muted medium mt-2">You can include several definitions by entering them on separate lines</span>
            }
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-end align-items-center border-top p-3">
        <button type="button" className="btn btn-secondary mr-2" onClick={onHide}>Cancel</button>
        <Tooltip openDelay={100} position='top' anchorElement={'target'}>
          <button
            type="button"
            title="Enter ↵"
            className="btn btn-primary mr-2"
            onClick={() => onConfirmHandler(inputValues, false)}>{editMode ? "Update" : type === "merge" ? "Merge" : "Save and close"}
          </button>
        </Tooltip>
        {
          !editMode && type === "category" &&
          <Tooltip openDelay={100} position='top' anchorElement={'target'}>
            <button
              type="button"
              title="Ctrl/Cmd + Enter ↵"
              className="btn btn-primary"
              onClick={() => onConfirmHandler(inputValues, true)}>Save
            </button>
          </Tooltip>
        }
      </div>
    </Window>
  )
}

interface CategoryProps {
  codeMapData: TODO
  onHide: () => void
  onConfirm: (array: number[]) => void
}

export const CodingCategoryModal: React.FC<CategoryProps> = ({ onHide, onConfirm, codeMapData }) => {
  const [inputValue, setInputValues] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showExistingCodesWarningModal, setShowExistingCodesWarningModal] = useState<{ show: boolean, codes: number[], nonExistingCodes: number[] }>({ show: false, codes: [], nonExistingCodes: [] });
  const [windowPosition, setWindowPosition] = useState({ top: 10, left: 10 });
  const savePositionWithDebounce = useDebounce(() => localStorage.setItem('categoryWindowPosition', JSON.stringify(windowPosition)), 1000);

  const initialWindowPosition = localStorage.getItem('categoryWindowPosition') ? JSON.parse(localStorage.getItem('categoryWindowPosition') as string) : { top: 10, left: 10 }

  const valueChangeHandler = (e: TODO) => {
    if (/^[0-9,\b]+$/.test(e.target.value)) {
      setInputValues(e.target.value)
    } else if (e.target.value === "") {
      setInputValues("")
    }
  }

  const onConfirmHandler = (e: TODO) => {
    e.preventDefault();
    if (inputValue.includes(",")) {
      // If multiple values
      const separatedNumbers = inputValue.split(",")
      const parsedArray = separatedNumbers.map(el => Number.parseInt(el))

      if (parsedArray.includes(Number.NaN)) {
        setErrorMessage("Only whole numbers, separated by commas are allowed")
      } else {
        const nonExistingCatCodes: number[] = [];
        const availableCatCodes = codeMapData.categories.map((el: TODO) => el.code)

        parsedArray.forEach((value: TODO) => {
          if (!availableCatCodes.includes(value)) {
            nonExistingCatCodes.push(value)
          }
        })

        if (nonExistingCatCodes.length > 0) {
          setShowExistingCodesWarningModal({ show: true, codes: parsedArray, nonExistingCodes: nonExistingCatCodes })
        } else {
          // If all criterea for multiple values have been met
          const cleanArray = parsedArray.filter((el, index) => parsedArray.indexOf(el) === index) // Removes duplicates
          onConfirm(cleanArray)
        }
      }
    } else if (!inputValue) {
      setErrorMessage("Only whole numbers, separated by commas are allowed")
    } else {
      // If single value
      const parsedValue = Number.parseInt(inputValue);
      const availableCatCodes = codeMapData.categories.map((el: TODO) => el.code)
      if (availableCatCodes.includes(parsedValue)) {
        // If all criterea for single value have been met
        onConfirm([parsedValue]);
      } else {
        setShowExistingCodesWarningModal({ show: true, codes: [parsedValue], nonExistingCodes: [parsedValue] })
      }
    }
  }

  const addAnswerToExistingAndNonExistingCodes = () => {
    const { codes } = showExistingCodesWarningModal;
    if (codes.length > 1) {
      const cleanArray = codes.filter((el, index) => codes.indexOf(el) === index) // Removes duplicates
      onConfirm(cleanArray)
    } else {
      onConfirm(codes)
    }
  }

  return (
    <Window
      title={"Code as"}
      onClose={onHide}
      resizable={false}
      minimizeButton={() => <span className="d-none" />}
      maximizeButton={() => <span className="d-none" />}
      className="coding-tool-category-modal"
      initialTop={initialWindowPosition.top}
      initialLeft={initialWindowPosition.left}
      onMove={({ top, left }: TODO) => { setWindowPosition({ top: top, left: left }); savePositionWithDebounce() }}
      style={{ height: 'auto' }}>
      <form onSubmit={onConfirmHandler}>
        {
          showExistingCodesWarningModal.show &&
          <Dialog width={400} title="Create new code frames" onClose={() => setShowExistingCodesWarningModal({ show: false, codes: [], nonExistingCodes: [] })}>
            <div className="p-5">
              <p className="m-0">The following codes you entered do not exist:</p>
              <ul className="list-group list-group-horizontal flex-wrap my-2">
                {
                  showExistingCodesWarningModal.nonExistingCodes.map((code: number, key: number) => (
                    <li key={key} className="list-group-item bg-light-survey strong py-1">{code}</li>
                  ))
                }
              </ul>
              <p className="m-0">Would you like to proceed and create new code frames for them?</p>
            </div>
            <DialogActionsBar>
              <button type="button" className="k-button btn btn-secondary" onClick={() => setShowExistingCodesWarningModal({ show: false, codes: [], nonExistingCodes: [] })}>Cancel</button>
              <button type="button" onClick={() => addAnswerToExistingAndNonExistingCodes()} className="k-button btn btn-primary">Proceed</button>
            </DialogActionsBar>
          </Dialog>
        }
        <div className="p-3">
          <label className="mb-1">Enter code:</label>
          <input
            type="text"
            autoFocus={true}
            value={inputValue}
            className="form-control"
            onChange={(e: TODO) => valueChangeHandler(e)}
          />
        </div>
        {
          errorMessage &&
          <ErrorMessage type="alert" errorMessage={errorMessage} onHide={() => setErrorMessage("")} />
        }
        <div className="d-flex justify-content-end align-items-center border-top p-3">
          <button
            type="button"
            className="k-button btn btn-secondary mr-2"
            onClick={onHide}>Cancel
          </button>
          <button
            type="submit"
            className="k-button btn btn-primary">Submit
          </button>
        </div>
      </form>
    </Window>
  )
}

interface deletedAnswersProps {
  codeMapData: TODO
  fetchData: {
    projectId: string
    workflowId: string
    toolId: string
    codeMapId: string
  }
  onHide: () => void
  token: string
  dispatch: TODO
}

export const DeletedAnswersModal: React.FC<deletedAnswersProps> = ({ codeMapData, fetchData, onHide, token, dispatch }) => {
  const [selectedAnswers, setSelectedAnswers] = useState<string[]>([])

  const onConfirmHandler = () => {
    reopenUncategorisedAnswers({ ...fetchData, token: token }, selectedAnswers, codeMapData, onHide, dispatch)
  }

  const selectAnswerHandler = (answer: TODO, isSelected: boolean) => {
    const updatedAnswers = [...selectedAnswers];

    if (!isSelected) {
      updatedAnswers.push(answer.id);
    } else {
      updatedAnswers.splice(updatedAnswers.findIndex(el => answer.id === el), 1)
    }

    setSelectedAnswers(updatedAnswers);
  }

  return <Dialog width={"500px"} height={"500px"} title="Reopen deleted answers" onClose={onHide}>
    <div className="d-flex flex-column w-100">
      {codeMapData.deletedAnswers.map((answer: TODO) => {
        const isSelected = selectedAnswers.includes(answer.id)
        return <div key={answer.id} onClick={() => selectAnswerHandler(answer, isSelected)} className={`coding-left-item mb-2 ${isSelected ? "answer-selected" : ""}`}>
          <div className="d-flex align-items-center coding-left-container" title={answer.t}>
            <div className="item">
              <span className="d-flex align-items-center">
                <span className="title">{answer.t}</span>
                <span className="count ml-2 stronger">{answer.c}</span>
              </span>
            </div>
          </div>
        </div>
      })}
    </div>
    <DialogActionsBar >
      <button type="button" className="btn btn-secondary mr-2" onClick={onHide}>Cancel</button>
      <button type="button" className="btn btn-primary" disabled={selectedAnswers.length < 1} onClick={() => onConfirmHandler()}>Reopen</button>
    </DialogActionsBar>
  </Dialog>
}

interface DeleteCatProps {
  onHide: () => void
  onConfirm: () => void
  categoryName: string
  isDeletingCategory: boolean
}

export const DeleteCategoryModal: React.FC<DeleteCatProps> = ({ onHide, onConfirm, categoryName, isDeletingCategory }) => {
  return <Dialog title="Delete category" width={"35%"} onClose={onHide}>
    <div className="d-flex w-100">
      Delete&nbsp;<span className="strong">{categoryName}</span>?
    </div>
    <DialogActionsBar>
      <button type="button" className="btn btn-secondary mr-2" onClick={onHide}>Cancel</button>
      <button type="button" disabled={isDeletingCategory} className="btn btn-danger" onClick={() => onConfirm()}>Delete</button>
    </DialogActionsBar>
  </Dialog>
}

interface SplitAnswerProps {
  onHide: () => void
  onConfirm: (obj: { splitTexts: string[] }) => void
  value: string
}

export const SplitAnswerModal: React.FC<SplitAnswerProps> = ({ onHide, onConfirm, value }) => {
  const [userValue, setUserValue] = useState(value)
  const originalValue = JSON.parse(JSON.stringify(value))
  const [errorMessage, setErrorMessage] = useState("")

  const onConfirmHandler = () => {
    const updatedValue = userValue;
    if (updatedValue.split("\n").length < 2) {
      setErrorMessage("Please split the texts into multiple rows to make new answers");
    } else {
      setErrorMessage("");
      const splitAnswers = updatedValue.split("\n").map(row => row.trim())
      const body = splitAnswers.map(el => {
        if (el[el.length - 1] === ",") {
          return el.slice(0, -1)
        }
        return el
      })

      onConfirm({ splitTexts: body });
    }
  }

  return <Dialog title="Split answer" width={"40%"} onClose={onHide}>
    <div className="d-flex flex-column w-100 p-3">
      <span>Split answer: <div className="strong">{originalValue}</div></span>
      <span className="text-muted small">Separate text into new rows to make new answers</span>
      <textarea
        className="form-control"
        rows={5}
        value={userValue}
        onChange={e => setUserValue(e.target.value)} />
      {errorMessage && <ErrorMessage type="alert" errorMessage={errorMessage} onHide={() => setErrorMessage("")} />}
    </div>
    <DialogActionsBar>
      <button type="button" className="btn btn-secondary" onClick={onHide}>Cancel</button>
      <button type="button" className="btn btn-primary" disabled={userValue.length < 1} onClick={() => onConfirmHandler()}>Split</button>
    </DialogActionsBar>
  </Dialog >
}

interface PasteCategoriesWindowProps {
  onHide: () => void
  codeMapData: TODO
  fetchData: {
    projectId: string
    workflowId: string
    toolId: string
    codeMapId: string
  }
}

export const PasteCategoriesWindow: React.FC<PasteCategoriesWindowProps> = ({ onHide, fetchData, codeMapData }) => {
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const dispatch = useDispatch();
  const [textareaValue, setTextareaValue] = useState<string>("")
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [windowPosition, setWindowPosition] = useState({ top: 10, left: 10 });
  const savePositionWithDebounce = useDebounce(() => localStorage.setItem('categoryWindowPosition', JSON.stringify(windowPosition)), 1000);

  const initialWindowPosition = localStorage.getItem('categoryWindowPosition') ? JSON.parse(localStorage.getItem('categoryWindowPosition') as string) : { top: 10, left: 10 }

  const { projectId, workflowId, toolId, codeMapId } = fetchData;

  const onPasteCategoriesHandler = (shouldCloseModal: boolean) => {
    const categoryNames = textareaValue.split('\n');
    if (categoryNames.length > 0) {
      setIsLoading(true)
      fetchPatch(`projects/${projectId}/workflows/${workflowId}/tools/${toolId}/code-maps/${codeMapId}/create-categories`, token, categoryNames)
        .then((res: TODO) => res.json())
        .then((resData: TODO) => {
          setIsLoading(false)
          if (resData && !resData.error && !resData.message) {
            const updatedCodeMapData = JSON.parse(JSON.stringify(codeMapData))
            updatedCodeMapData.categories = updatedCodeMapData.categories.concat(resData);
            dispatch({ type: "UPDATE_SELECTED_CODEMAP", payload: updatedCodeMapData })
            if (shouldCloseModal) {
              onHide();
            }
          } else {
            dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: resData.message ? resData.message : resData.error } })
          }
        })
    }
  }

  return (
    <Window
      title={"Paste categories"}
      onClose={onHide}
      resizable={false}
      minimizeButton={() => <span className="d-none" />}
      maximizeButton={() => <span className="d-none" />}
      className="coding-tool-category-modal"
      initialTop={initialWindowPosition.top}
      initialLeft={initialWindowPosition.left}
      onMove={({ top, left }: TODO) => { setWindowPosition({ top: top, left: left }); savePositionWithDebounce() }}
      style={{ height: 'auto' }}>
      <div className="p-3">
        <label className="mb-2">Enter category names, one per line:</label>
        <textarea value={textareaValue} onChange={(e) => setTextareaValue(e.target.value)} className="form-control" rows={10} />
      </div>
      <div className="d-flex justify-content-end align-items-center border-top p-3">
        <button
          type="button"
          className="k-button btn btn-secondary mr-2"
          onClick={onHide}>Cancel
        </button>
        <button
          type="button"
          disabled={isLoading || !textareaValue}
          className="k-button btn btn-primary mr-2"
          onClick={() => onPasteCategoriesHandler(true)}>Save and close
        </button>
        <button
          type="button"
          disabled={isLoading || !textareaValue}
          className="k-button btn btn-primary"
          onClick={() => onPasteCategoriesHandler(false)}>Save
        </button>
      </div>
    </Window>
  )
}
