const DOCUMENT_TYPE_ICONS = {
  audiences: 'fas fa-users',
  datasets: 'fas fa-database',
  surveys: 'fas fa-poll-h',
  workflows: 'fas fa-cogs',
  samples: 'fas fa-file-alt',
  files: 'fas fa-file',
  pinboards: 'fas fa-solid fa-table',
};

export default DOCUMENT_TYPE_ICONS;
