const BACKOFFICE_USER_ACTIONS = [
  {
    text: 'Edit user',
    disabledMsg: 'Only backoffice admins can edit users',
  },
  {
    text: 'Log in as user',
    disabledMsg: 'Only support agents can log in as other users',
  },
  {
    text: 'Delete user',
    disabledMsg: 'Only backoffice owners can delete users',
  },
];

export default BACKOFFICE_USER_ACTIONS;
