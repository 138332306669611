import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DropDownButton } from "@progress/kendo-react-buttons";

import { Avatar } from "../../../shared/Avatar/Avatar";
import type { RootState } from "../../../../store/reducers/rootReducer";
import projectAddActions from '../../../shared/Header/projectAddActions';
import type { User } from "../../../../interfaces/userInterface/userInterface";
import { createNewAudience } from "../../../../store/reducers/audienceAccessReducer/audienceAccessReducer";
import type { ShareDetails } from "../../../../interfaces/projectDetailsInterfaces/shareDetailsInterfaces";
import type { ProjectDetails as ProjectDetailsInterface, ProjectTag } from "../../../../interfaces/projectDetailsInterfaces/projectDetailsInterfaces";

type Props = {
  projectDetails: ProjectDetailsInterface
  shareDetails: ShareDetails
  user: User
  projectModalsStateHandler: (modalType: string, value: TODO) => void
  setShowRemoveSharingModal: (value: TODO) => void
}

type ProjectAddActions = {
  text: string
  icon?: string
  disabled?: boolean
  id: "survey" | "surveyItem" | "file" | "pinboard" | "panel" | "workflow" | "audiences"
}

export const ProjectDetailsHeader = ({ projectDetails, shareDetails, user, projectModalsStateHandler, setShowRemoveSharingModal }: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { isLegacyCustomer } = useSelector((theState: RootState) => theState.userSettingsReducer);

  const isProjectShared = !!shareDetails?.isShared;
  const isProjectSharedWith = !!shareDetails?.sharedWithDetails;

  const shareActionButtons = [
    {
      "actionName": "Setup sharing",
      "action": "share",
      "icon": "far fa-share-square"
    },
    {
      "actionName": "Remove sharing",
      "action": "removeSharing",
      "disabled": !isProjectShared,
      "icon": "far fa-trash-alt"
    }
  ]

  const returnProjectActions = () => {
    let projectActions = projectAddActions as unknown as ProjectAddActions[];

    if (!user?.enterpriseSettings?.hasPanelAccess) {
      projectActions = projectActions.filter(el => el.id !== 'panel');
    }
    if (!user?.enterpriseSettings?.hasToolsAccess) {
      projectActions = projectActions.filter(el => el.id !== 'workflow');
    }
    if (!user?.enterpriseSettings?.hasSurveyAccess) {
      projectActions = projectActions.filter(el => el.id !== 'survey' && el.id !== 'surveyItem');
    }
    if (!user?.enterpriseSettings?.hasPinboardAccess) {
      projectActions = projectActions.filter(el => el.id !== 'pinboard');
    }
    if (!user?.enterpriseSettings?.hasAudienceAccess) {
      projectActions = projectActions.filter(el => el.id !== 'audiences');
    }

    return projectActions;
  }

  const itemRender = (props: { item: TODO }) => {
    return (
      <div className={`d-flex ${props.item.id !== 'surveyItem' ? 'align-items-center justify-content-between' : 'px-3'}`}>
        {props.item.icon && <span className={`k-icon k-i-${props.item.icon}`} />}
        <span className="ml-2">{props.item.text}</span>
      </div>
    );
  };

  const selectAction = (e: { item: { text: string } }) => {
    switch (e.item.text) {
      case ("From scratch"):
        projectModalsStateHandler("showAddSurveyModal", true);
        break;
      case ("From dataset"):
        projectModalsStateHandler("showAddSurveyFromDatasetModal", true);
        break;
      case ("From existing project"):
        projectModalsStateHandler("showAddSurveyFromProjectModal", { survey: null, show: true });
        break;
      case ("Pinboard"):
        projectModalsStateHandler("showAddPinboardModal", true);
        break;
      case ("File"):
        projectModalsStateHandler("showImportFileModal", true);
        break;
      case ("Sample"):
        projectModalsStateHandler("showAddSampleModal", true);
        break;
      case ("Data Operation"):
        projectModalsStateHandler("showAddWorkflowModal", true);
        break;
      case ("Audience"): {
        const location = history.location.pathname;
        dispatch(createNewAudience());
        history.push({ pathname: `${location}/audiences/` });
        break;
      }
      default:
        break;
    }
  }

  const onShareItemClickHandler = (e: { item: { action: string } }) => {
    if (e.item.action === 'share') {
      projectModalsStateHandler("showShareProjectModal", { show: true, step: 'access' });
    } else if (e.item.action === 'removeSharing') {
      setShowRemoveSharingModal(true);
    }
  }

  const onImportHandler = async () => {
    // if (isLegacyCustomer && (user.role === 'admin' || user.role === 'owner')) {
    // The commented line above prevents from regular users to import from archive, might be needed in the future
    if (isLegacyCustomer) {
      dispatch({ type: 'DISPLAY_IMPORT_OPTIONS_MODAL', payload: true });
    } else {
      dispatch({ type: 'DISPLAY_QUICK_IMPORT_MODAL', payload: true });
    }
  };

  return (
    <div className="d-flex flex-column gap-lg border-bottom p-5 mb-5 bg-lightest">
      <div className="d-flex align-items-center justify-content-between flex-wrap gap-lg">
        <div className="d-flex align-items-center">
          <Avatar
            rounded="small"
            class="avatar-lg m-0"
            image={projectDetails.details?.iconUrl}
            backgroundColor={projectDetails.details?.iconBackgroundColor}
            icon={shareDetails.isShared ? "far fa-lg fa-share-alt" : "far fa-lg fa-folder"}
            color={projectDetails.details?.iconBackgroundColor ? '' : "primary"}
          />
          <div className="d-flex flex-column justify-content-center gap-xs ml-5">
            <p className="heading-dashboard text-primary h4 strong">
              {projectDetails.details?.name}
            </p>
            {
              projectDetails?.details &&
              <p className="m-0 line-clamp-2 text-muted">
                {projectDetails?.details?.description}
              </p>
            }
          </div>
        </div>
        <div className="d-flex flex-wrap gap-md">
          <button type='button' className="btn btn-primary" onClick={onImportHandler}>
            <i className="fas fa-cloud-upload mr-2" />
            Import
          </button>
          <DropDownButton
            text="Add"
            id="project-create"
            icon="fa fa-fw far fa-plus-circle"
            buttonClass="btn btn-shadow border-0"
            itemRender={itemRender}
            onItemClick={selectAction}
            items={returnProjectActions()}
            disabled={shareDetails?.sharedWithDetails?.accessMode === 'readonly'}
            popupSettings={{ animate: false, popupClass: "create-dropdown-button py-2" }}
          />
          <button type='button' className="btn btn-shadow" onClick={() => dispatch({ type: "UPDATE_SHOW_EDIT_PROJECT_MODAL", payload: { show: true, id: projectDetails.details?.id } })}>
            <i className="far fa-lg fa-pen mr-2" />
            Edit
          </button>
          <DropDownButton
            text='Sharing'
            icon="fa fa-fw far fa-share-alt"
            className="analyze-actions-button"
            buttonClass="btn btn-shadow border-0"
            items={shareActionButtons}
            disabled={!!isProjectSharedWith}
            onItemClick={onShareItemClickHandler}
            popupSettings={{ popupClass: 'actions-dropdown' }}
            itemRender={(props: { item: { icon: string, disabled?: boolean, actionName: string } }) => (
              <button type='button' disabled={props.item.disabled} className="btn bg-transparent p-0 d-flex justify-content-between align-items-center">
                <i className={`${props.item.icon} mr-3`} />
                <span>{props.item.actionName}</span>
              </button>
            )}
          />
        </div>
      </div>
      <div className='d-flex align-items-center gap-md'>
        {
          projectDetails?.details?.tags?.map((tag: ProjectTag) => (
            <span
              key={tag.id}
              style={{ lineHeight: 'unset' }}
              className="badge badge-light px-2 py-1">{tag.name}
            </span>
          ))
        }
      </div>
    </div>
  )
}
