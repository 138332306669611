const EXPORT_DOCUMENT_INPUTS = {
  SAV: {
    color: 'primary',
    inputs: [
      {
        text: "Use question ID's as labels",
        id: 'useIdAsLabel',
      },
      {
        text: 'Include log in export',
        id: 'includeLogin',
      },
      {
        text: 'Use 01 Multi Values',
        id: 'useMultiValues',
      },
    ],
  },
  'TRIPLES-S': {
    color: 'panel',
    inputs: [
      {
        text: "Use question ID's as labels",
        id: 'useIdAsLabel',
      },
      {
        text: 'Include log in export',
        id: 'includeLogin',
      },
    ],
  },
  MIPROX: {
    color: 'analysis',
    inputs: [],
  },
};

export default EXPORT_DOCUMENT_INPUTS;
