//@ts-ignore
import { v4 as uuid } from 'uuid';

export const returnAuditLogsNestedList = (data: any) => {
  const items: any = []
  data.forEach((el: any) => {
    if (el.item.parent) {
      const item = ({
        expanded: false,
        ...el,
        ...el.item,
        userName: el.user ? el.user.name : null,
        userId: el.user ? el.user.id : null,
        items: returnAuditLogsNestedList([{ item: { ...el.item.parent, action: null } }])
      })
      delete item["item"]
      item.type = item.type.charAt(0).toUpperCase() + item.type.slice(1)
      item.itemId = uuid();
      items.push(item)
    } else {
      const item = ({
        ...el,
        ...el.item,
        userName: el.user ? el.user.name : null,
        userId: el.user ? el.user.id : null,
      })
      delete item["item"]
      item.type = item.type.charAt(0).toUpperCase() + item.type.slice(1)
      item.itemId = uuid();
      items.push(item)
    }
  })
  return items
}
