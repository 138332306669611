import { Icon } from "../Icon/Icon";
import { returnQuestionIcon } from "../../Analysis/Analyze/components/shared/helpers/returnQuestionIcon/returnQuestionIcon";

export const referenceItemsRender = (question: TODO, onClick: (question: TODO) => void) => {
  if (question) {
    const icon = returnQuestionIcon(question.type)
    return (
      <li className="d-flex py-2 px-3 align-items-center " onClick={() => onClick(question)}>
        <Icon type={icon} className="question-icon" />
        <div className="d-flex flex-column mx-3">
          {question.qno ?
            <div>
              <span role="heading" className="badge badge-pill badge-light-blue text-white mr-2 align-self-start">
                {question.qno.slice(0, -2)}
              </span>
              <span className="strong" role="heading">
                {question.text}
              </span>
            </div>
            :
            <span className="strong" role="heading">{question.text.slice(0, -2)} </span>
          }
          <span>{question["ques"]}</span>
        </div>
      </li>
    )
  } else {
    return null
  }
}

export const referenceValueRender = (question: TODO) => {
  if (question) {
    const icon = returnQuestionIcon(question.type)
    return (
      <div className="d-flex py-2 px-3 align-items-center flex-grow-1" >
        <Icon type={icon} className="question-icon" />
        <div className="d-flex flex-column mx-3 w-100">
          {question.qno ?
            <div className="d-flex align-items-center">
              <span role="heading" className="badge badge-pill badge-light-blue text-white mr-2">
                {question.qno.slice(0, -2)}
              </span>
              <span className="strong text-truncate" role="heading">
                {question.text}
              </span>
            </div>
            :
            <span className="strong text-truncate" role="heading">{question.text.slice(0, -2)} </span>
          }
          <span className="text-truncate">{question["ques"]}</span>

        </div>
      </div>
    )
  } else return null
}

export const referencesFilterHandler = (event: TODO, questions: TODO, setFilteredRefQuestions: (questions: TODO) => void) => {
  const searchedQuestions: TODO = [];
  const oldQuestions = [...questions];
  if (event.filter.value) {
    oldQuestions.forEach(question => {
      if (question.qno) {
        if (question.qno.toLowerCase().includes(event.filter.value.toLowerCase())) {
          searchedQuestions.push(question);
        }
      } else {
        if (question.text.toLowerCase().includes(event.filter.value.toLowerCase())) {
          searchedQuestions.push(question);
        }
      }
    });
    setFilteredRefQuestions([...searchedQuestions]);
  } else {
    setFilteredRefQuestions([...questions]);
  }

}
