import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux";

import type { RootState } from "../../../store/reducers/rootReducer";
import type { User } from "../../../interfaces/userInterface/userInterface";
import defaultOptions from '../../Analysis/Analyze/components/dashboard/main/analysis/defaultOptionValues';
import sortOptionsGroups from "../../Analysis/Analyze/components/shared/helpers/sortOptionGroups/sortOptionsGroups";
import returnInitialOptions from "../../Analysis/Analyze/components/shared/helpers/returnInitialOptions/returnInitialOptions";
import returnDigOptionsInitialValues from "../../Analysis/Analyze/components/shared/helpers/returnDigOptionsInitialValues/returnDigOptionsInitialValues";
import returnChartOptionsInitialValues from "../../Analysis/Analyze/components/shared/helpers/returnChartOptionsInitialValues/returnChartOptionsInitialValues";
import returnQTableOptionsInitialValues from "../../Analysis/Analyze/components/shared/helpers/returnQTableOptionsInitialValues/returnQTableOptionsInitialValues";
import returnRawDataOptionsInitialValues from "../../Analysis/Analyze/components/shared/helpers/returnRawDataOptionsInitialValues/returnRawDataOptionsInitialValues";
import returnToplineOptionsInitialValues from "../../Analysis/Analyze/components/shared/helpers/returnToplineOptionsInitialValues/returnToplineOptionsInitialValues";
import returnCrosstabOptionsInitialValues from "../../Analysis/Analyze/components/shared/helpers/returnCrosstabOptionsInitialValues/returnCrosstabOptionsInitialValues";
import { fetchGetJson as getAnalysisOptions } from "../../../services/services"

export const useFetchAnalysisOptions = (user: User, optionsData: TODO, prevDidMount: TODO, activeTabs: TODO, tabSelected: number, setOptionsData: (val: TODO) => void, isSessionLoaded: boolean,) => {
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);

  const dispatch = useDispatch()

  useEffect(() => {
    if (optionsData.didMount && optionsData.didMount !== prevDidMount) {
      setOptionsData({
        ...optionsData,
        didMount: false
      })
      getAnalysisOptions("/resources/analysis-options", token)
        .then((res: TODO) => {
          if (res && (res.message || res.error)) {
            dispatch({ type: 'UPDATE_ERROR_MESSAGE', payload: { msg: res.message ? res.message : res.error, type: 'modal' } })
          } else {
            if (activeTabs?.[tabSelected]) {
              let groups = []
              let data = {}
              if (tabSelected !== 6 && tabSelected !== 7 && tabSelected !== 8) {
                groups = sortOptionsGroups(res.analysisOptions.find((el: TODO) => el.analysisType === activeTabs[tabSelected].id).groups, user)
                data = res.analysisOptions.find((el: TODO) => el.analysisType === activeTabs[tabSelected].id).props
              }

              setOptionsData({
                ...optionsData,
                allOptions: res.analysisOptions,
                exportOptions: res.exportOptions,
                groups: groups,
                data: data,
                dropdownValues: res.analysisEnumerations,
                didFetchAttributes: true,
                shouldUpdateOptions: true,
                didMount: false
              })

              if (!isSessionLoaded) {
                const toplineValues = returnToplineOptionsInitialValues(res.analysisOptions.find((el: TODO) => el.analysisType === 'Topline').props, user, res.analysisOptions.find((el: TODO) => el.analysisType === 'Topline').groups)
                const toplineDefaultOptions = returnInitialOptions(defaultOptions[0].topline, user, res.analysisOptions.find((el: TODO) => el.analysisType === 'Topline').groups, res.analysisOptions.find((el: TODO) => el.analysisType === 'Topline').props)
                dispatch({ type: 'SET_INITIAL_TOPLINE_OPTIONS', payload: { questionOptions: toplineValues, newQuestionOptions: toplineDefaultOptions } })

                const crosstabValues = returnCrosstabOptionsInitialValues(res.analysisOptions.find((el: TODO) => el.analysisType === 'Crosstab').props, user, res.analysisOptions.find((el: TODO) => el.analysisType === 'Crosstab').groups)
                const crosstabDefaultOptions = returnInitialOptions(defaultOptions[1].crosstab, user, res.analysisOptions.find((el: TODO) => el.analysisType === 'Crosstab').groups, res.analysisOptions.find((el: TODO) => el.analysisType === 'Crosstab').props)
                dispatch({ type: "SET_INITIAL_XT_OPTIONS", payload: { questionOptions: crosstabValues, newQuestionOptions: crosstabDefaultOptions } })

                const qTableValues = returnQTableOptionsInitialValues(res.analysisOptions.find((el: TODO) => el.analysisType === 'Qt').props, user, res.analysisOptions.find((el: TODO) => el.analysisType === 'Qt').groups)
                const qtDefaultOptions = returnInitialOptions(defaultOptions[4].qt, user, res.analysisOptions.find((el: TODO) => el.analysisType === 'Qt').groups, res.analysisOptions.find((el: TODO) => el.analysisType === 'Qt').props)
                dispatch({ type: 'SET_INITIAL_QTABLE_OPTIONS', payload: { questionOptions: qTableValues, newQuestionOptions: qtDefaultOptions } })

                const rawDataValues = returnRawDataOptionsInitialValues(res.analysisOptions.find((el: TODO) => el.analysisType === 'Data').props, user, res.analysisOptions.find((el: TODO) => el.analysisType === 'Data').groups)
                const dataDefaultOptions = returnInitialOptions(defaultOptions[5].data, user, res.analysisOptions.find((el: TODO) => el.analysisType === 'Data').groups, res.analysisOptions.find((el: TODO) => el.analysisType === 'Data').props)
                dispatch({ type: 'SET_INITIAL_RAW_DATA_OPTIONS', payload: { questionOptions: rawDataValues, newQuestionOptions: dataDefaultOptions } })

                const digValues = returnDigOptionsInitialValues(res.analysisOptions.find((el: TODO) => el.analysisType === 'Dig').props, user, res.analysisOptions.find((el: TODO) => el.analysisType === 'Dig').groups)
                const digDefaultOptions = returnInitialOptions(defaultOptions[3].dig, user, res.analysisOptions.find((el: TODO) => el.analysisType === 'Dig').groups, res.analysisOptions.find((el: TODO) => el.analysisType === 'Dig').props)
                dispatch({ type: 'SET_INITIAL_DIG_OPTIONS', payload: { questionOptions: digValues, newQuestionOptions: digDefaultOptions } })

                const chartValues = returnChartOptionsInitialValues(res.analysisOptions.find((el: TODO) => el.analysisType === 'Chart').props, user, res.analysisOptions.find((el: TODO) => el.analysisType === 'Chart').groups)
                const chartDefaultOptions = returnInitialOptions(defaultOptions[2].chart, user, res.analysisOptions.find((el: TODO) => el.analysisType === 'Chart').groups, res.analysisOptions.find((el: TODO) => el.analysisType === 'Chart').props)
                dispatch({ type: 'SET_INITIAL_CHART_OPTIONS', payload: { questionOptions: chartValues, newQuestionOptions: chartDefaultOptions } })

              }
            }
          }
        })
    }
  }, [prevDidMount, optionsData.didMount])
}
