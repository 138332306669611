const PROJECT_ADD_ACTIONS = [
  {
    text: 'Survey',
    icon: 'fa-fw fas fa-poll-h',
    disabled: true,
    id: 'survey',
  },
  {
    text: 'From scratch',
    id: 'surveyItem',
  },
  {
    text: 'From dataset',
    id: 'surveyItem',
  },
  {
    text: 'From existing project',
    id: 'surveyItem',
  },
  {
    text: 'File',
    icon: 'fa-fw fas fa-file',
    id: 'file',
  },
  {
    text: 'Pinboard',
    icon: 'fa-fw fas fa-solid fa-table',
    id: 'pinboard',
  },
  {
    text: 'Sample',
    icon: 'fa-fw fas fa-file-alt',
    id: 'panel',
  },
  {
    text: 'Data Operation',
    icon: 'fa-fw fas fa-cogs',
    id: 'workflow',
  },
  {
    text: 'Audience',
    icon: 'fa-fw fas fa-users',
    id: 'audiences',
  },
];

export default PROJECT_ADD_ACTIONS;
