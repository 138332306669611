const THEMES = {
  delight: [
    'delight-teal',
    'delight-cerise',
    'delight-cyan',
    'delight-yellow',
    'delight-purple',
    'delight-navy',
  ],
  dataverse: [
    'dataverse-teal',
    'dataverse-cerise',
    'dataverse-cyan',
    'dataverse-yellow',
    'dataverse-purple',
    'dataverse-navy',
  ],
};

export default THEMES;
