const ANALYSIS_TABS = [
  {
    title: 'Topline',
    icon: 'analysis-table-topline',
    id: 'Topline',
    dispatchType: 'UPDATE_INITIAL_TOPLINE_STATE',
  },
  {
    title: 'Cross Table',
    icon: 'analysis-table-crosstable',
    id: 'Crosstab',
    dispatchType: 'UPDATE_INITIAL_STATE',
  },
  {
    title: 'Chart',
    icon: 'analysis-table-chart',
    id: 'Chart',
    dispatchType: 'UPDATE_INITIAL_CHART_STATE',
  },
  {
    title: 'Dig',
    icon: 'analysis-table-dig',
    id: 'Dig',
    dispatchType: 'UPDATE_INITIAL_DIG_STATE',
  },
  {
    title: 'Q Table',
    icon: 'analysis-table-qtable',
    id: 'Qt',
    dispatchType: 'UPDATE_INITIAL_QTABLE_STATE',
  },
  {
    title: 'Data',
    icon: 'analysis-table-data',
    id: 'Data',
    dispatchType: 'UPDATE_INITIAL_RAW_DATA_STATE',
  },
  {
    title: 'Report',
    icon: 'analysis-table-report',
    id: 'Report',
    dispatchType: 'UPDATE_INITIAL_REPORT_DATA_STATE',
  },
  {
    title: 'Script',
    icon: 'analysis-table-script',
    id: 'Script',
    dispatchType: 'UPDATE_INITIAL_SCRIPT_STATE',
  },
  {
    title: 'Reports',
    icon: 'analysis-table-report',
    id: 'reports',
    dispatchType: '',
  },
];

export default ANALYSIS_TABS;
