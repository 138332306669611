const ANALYSIS_MENU_ITEMS = [
  {
    title: 'Go to Project',
    action: 'goToProject',
  },
  {
    title: 'separator',
    action: 'separator',
  },
  {
    title: 'Edit',
    action: 'editDataset',
  },
  {
    title: 'View',
    details: 'Compact view',
    action: 'compactView',
    expanded: false,
    subItems: [
      {
        title: 'Dataset',
        action: 'compactDataset',
        type: 'switch',
        switchValue: false,
      },
      {
        title: 'Rows',
        action: 'compactRows',
        type: 'switch',
        switchValue: false,
      },
      {
        title: 'Columns',
        action: 'compactColumns',
        type: 'switch',
        switchValue: false,
      },
    ],
  },
  {
    title: 'Export',
    action: 'exportDataset',
  },
  {
    title: 'XML script',
    action: 'openXml',
  },
  {
    title: 'separator',
    action: 'separator',
  },
  {
    title: 'Recent projects',
    action: 'recentProjects',
    expanded: false,
    subItems: [],
  },
  {
    title: 'Recent activity',
    action: 'recentActivity',
    expanded: false,
    subItems: [],
  },
  {
    title: 'separator',
    action: 'separator',
  },
  {
    title: 'Go to Home',
    action: 'goToHome',
  },
  {
    title: 'separator',
    action: 'separator',
  },
  {
    title: 'Delete',
    action: 'delete',
  },
];

export default ANALYSIS_MENU_ITEMS;
