const SURVEY_LANGUAGES = [
  {
    text: 'English',
    value: 'en',
  },
  {
    text: 'German',
    value: 'de',
  },
  {
    text: 'French',
    value: 'fr',
  },
  {
    text: 'Swedish',
    value: 'sv',
  },
  {
    text: 'Norwegian',
    value: 'no',
  },
  {
    text: 'Finnish',
    value: 'fi',
  },
  {
    text: 'Danish',
    value: 'da',
  },
  {
    text: 'Turkish',
    value: 'tr',
  },
  {
    text: 'Dutch',
    value: 'nl',
  },
  {
    text: 'Bulgarian',
    value: 'bg',
  },
  {
    text: 'Japanese',
    value: 'jp',
  },
  {
    text: 'Portuguese',
    value: 'pt',
  },
  {
    text: 'Russian',
    value: 'ru',
  },
  {
    text: 'Spanish',
    value: 'es',
  },
  {
    text: 'Polish',
    value: 'po',
  },
  {
    text: 'Estonian',
    value: 'ee',
  },
  {
    text: 'Latvian',
    value: 'lv',
  },
  {
    text: 'Lithuanian',
    value: 'lt',
  },
];

export default SURVEY_LANGUAGES;
