type SelectedFileType = 'Triple S' | 'Spss' | 'Miprox' | 'Excel';

export const returnAcceptedFileType = (selectedFileType: SelectedFileType) => {
  switch (selectedFileType) {
    case 'Triple S':
      return '.sss, .xml';
    case 'Spss':
      return '.sav, .SAV';
    case 'Miprox':
      return '.miprox';
    case 'Excel':
      return '.xlsx, .xls';
  }
};

export const returnAcceptedFileExtension = (fileType: SelectedFileType) => {
  switch (fileType) {
    case 'Spss':
      return 'spss';
    case 'Excel':
      return 'excel';
    case 'Miprox':
      return 'miprox';
    case 'Triple S':
      return 'triple-s';
  }
};
