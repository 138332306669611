import { useEffect, useState, Fragment } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

import { BaseDropDownList } from "../../../../../shared/Inputs";
import tabs from '../../dashboard/main/analysis/tabsData';
import { SessionCard } from './SessionCard/SessionCard';
import DeleteSessionModal from './DeleteSessionModal/DeleteSessionModal';
import { InProgressOverlay } from '../../../../../shared/InProgressOverlay/InProgressOverlay';
import { returnSessionStateType } from '../../../../../shared/helpers/returnSessionStateType/returnSessionStateType';
import { fetchGetJson, fetchGetJson as getProjectsData, fetchGetJson as getProjectDocuments, fetchGetJson as loadAllSessions } from '../../../../../../services/services';

export default function LoadSessionModal({ onHide, setTabSelected, activeTabs }) {
  const { token } = useSelector((state) => state.tokenStateReducer);
  const params = useParams();
  const dispatch = useDispatch()
  const [allDocuments, setAllDocuments] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [deleteSessionModal, setDeleteSessionModal] = useState({ show: false, id: null })
  const [selectionData, setSelectionData] = useState({
    projects: [],
    documents: [],
    selectedProject: null,
    selectedDocument: null,
    selectedSession: null
  })
  const { selectedSession, selectedDocument, selectedProject, projects, documents } = selectionData

  const datasetId = params.dataset;
  const projectId = params.name;

  useEffect(() => {
    if (projectId && datasetId) {
      getProjectsData("projects", token)
        .then(projectsData => {
          if (Array.isArray(projectsData)) {
            getProjectDocuments(`projects/${projectId}/documents`, token)
              .then(documentsData => {
                if (documentsData) {
                  const newDocuments = documentsData.datasets.concat(
                    documentsData.surveys.filter(item => item.publishedVersions?.length > 0), documentsData.datasetsv2
                  ).map(item => {
                    return { name: item.name, id: item.id }
                  })
                  setSelectionData({
                    selectedProject: projectsData.find(item => item.id === projectId),
                    selectedDocument: documentsData.datasets.find(item => item.id === datasetId),
                    projects: projectsData.map(item => { return { name: item.name, id: item.id } }),
                    documents: newDocuments
                  })
                  newDocuments.forEach((document) => {
                    loadAllSessions(`projects/${projectId}/sessions/${document.id}`, token)
                      .then(res => {
                        if (res) {
                          document.sessions = res
                          if (res.length !== 0) {
                            setAllDocuments((oldArray) => [...oldArray, document])
                          }
                        }
                        setIsLoading(false)
                      })
                  })
                }
              })
          }
        })
    }
  }, [datasetId, projectId, token])

  const onSelectHandler = () => {
    loadAllSessions(`projects/${selectedProject.id}/sessions/${selectedDocument ? selectedDocument.id : datasetId}/${selectedSession.id}/data`, token)
      .then(data => {
        if (!data.error) {
          fetchGetJson(`projects/${projectId}/sessions/${selectedSession.parentId}/${selectedSession.id}/last-modified`, token)
            .then(res => {
              if (!res.error) {
                if (data.selectedTab === 8) {
                  setTabSelected(8)
                } else {
                  const activeTab = activeTabs.find(activeTab => activeTab.id === tabs[data.selectedTab].id)
                  if (activeTab) {
                    setTabSelected(activeTabs.indexOf(activeTab))
                  } else {
                    setTabSelected(0)
                  }
                }
                const stateType = returnSessionStateType(data.selectedTab)
                if (stateType) {
                  data[stateType].updateTable = true
                }
                dispatch({ type: 'UPDATE_SELECTED_SESSION', payload: { name: selectedSession.name, id: selectedSession.id, lastSaved: res.lastModifiedUtc } })
                dispatch({ type: "UPDATE_INITIAL_TOPLINE_STATE", payload: data.toplineState })
                dispatch({ type: "UPDATE_INITIAL_STATE", payload: data.xtState })
                dispatch({ type: "UPDATE_INITIAL_CHART_STATE", payload: data.chartState })
                dispatch({ type: "UPDATE_INITIAL_DIG_STATE", payload: data.digState })
                dispatch({ type: "UPDATE_INITIAL_QTABLE_STATE", payload: data.qTableState })
                dispatch({ type: "UPDATE_INITIAL_RAW_DATA_STATE", payload: data.dataState })
                dispatch({ type: "UPDATE_INITIAL_REPORT_DATA_STATE", payload: data.reportState })
                if (data.analysisScriptingState?.data) {
                  dispatch({ type: "LOAD_ANALYSIS_SCRIPTING_STATE", payload: data.analysisScriptingState })
                } else {
                  dispatch({ type: "SET_INITIAL_ANALYSIS_SCRIPTING_DATA" })
                }
                onHide()
              }
            })
        }
      })
  }
  const showDeleteSessionModal = (e, sessionId) => {
    e.stopPropagation()
    setDeleteSessionModal({ ...deleteSessionModal, show: true, id: sessionId })
  }

  const onSelectProjectHandler = (e) => {
    setIsLoading(true)
    setAllDocuments([])
    getProjectDocuments(`projects/${e.value.id}/documents`, token)
      .then(res => {
        if (res) {
          const updatedDocuments = res.datasets.concat(
            res.surveys.filter(item => item.publishedVersions?.length > 0)
          ).map(item => {
            return { name: item.name, id: item.id }
          })
          if (updatedDocuments.length > 0) {
            onSelectDocumentHandler(
              { value: { name: updatedDocuments[0].name, id: updatedDocuments[0].id } },
              true,
              updatedDocuments,
              e.value)
          } else {
            setIsLoading(false)
            setSelectionData({ ...selectionData, selectedDocument: null, selectedSession: null, selectedProject: e.value, documents: updatedDocuments })
          }
        }
      })
  }

  const onSelectDocumentHandler = (e, autoSelectDataset, documents, newSelectedProject) => {
    setIsLoading(true)
    const updatedSelectedProject = autoSelectDataset ? newSelectedProject : selectedProject
    if (autoSelectDataset) {
      setSelectionData({ ...selectionData, selectedDocument: e.value, selectedSession: null, selectedProject: updatedSelectedProject, documents: documents })
    } else {
      setSelectionData({ ...selectionData, selectedDocument: e.value, selectedSession: null })
    }
    documents.map(document => {
      loadAllSessions(`projects/${updatedSelectedProject.id}/sessions/${document.id}`, token)
        .then(res => {
          if (res) {
            setIsLoading(false)
            document.sessions = res
            if (res.length !== 0) {
              setAllDocuments((oldArray) => [...oldArray, document])
            }
          }
        })
      return null
    })
  }

  return (
    <Dialog width={1000} height={'90%'} title="Load a session" onClose={onHide} className="import-dialog">
      {
        deleteSessionModal.show &&
        <DeleteSessionModal
          token={token}
          projectId={projectId}
          datasetId={datasetId}
          sessionId={deleteSessionModal.id}
          allDocuments={allDocuments}
          setAllDocuments={(data) => setAllDocuments(data)}
          onHide={() => setDeleteSessionModal({ ...deleteSessionModal, show: false })} />
      }
      {
        isLoading &&
        <InProgressOverlay
          type='overlay'
          theme="primary" />
      }
      {
        <div className="container-fluid p-4">
          <div className="row mb-4 pb-2">
            <div className="col-md-12">
              <p className="mb-2">Select a project:</p>
              <BaseDropDownList
                className="w-100"
                textField="name"
                dataItemKey="id"
                data={projects}
                value={selectedProject}
                onChange={onSelectProjectHandler} />
            </div>
          </div>
          {allDocuments && allDocuments.length !== 0 ? allDocuments.sort((a, b) => a.name.localeCompare(b.name)).map((document) => {
            return (
              <Fragment key={document.id}>
                <p className="ml-1 my-3 strong">{document.name}</p>
                <div className={`row ${document.sessions.length === 0 ? 'justify-content-center' : ''}`}>
                  {
                    document?.sessions?.map((session, id) => (
                      <Fragment key={session.id}>
                        <SessionCard
                          document={document}
                          id={id}
                          session={session}
                          selectionData={selectionData}
                          selectedSession={selectedSession}
                          setSelectionData={(data) => setSelectionData(data)}
                          showDeleteSessionModal={(e, id) => showDeleteSessionModal(e, id)}
                        />
                      </Fragment>
                    ))
                  }
                </div>
              </Fragment>
            )
          }) :
            <div className="row justify-content-center">
              <p className="m-0">No sessions found</p>
            </div>
          }
        </div>
      }
      <DialogActionsBar>
        <button type='button' className="k-button k-button-solid-base" onClick={onHide}>Close</button>
        <button type='button' onClick={onSelectHandler} disabled={selectedSession === null} className="k-button k-button-solid-primary">Select</button>
      </DialogActionsBar>
    </Dialog >
  )
}
