interface Language {
    name?: string;
    code: string;
}

const prepareLanguagesDropDown = (langList: Language[]): { label: string; name?: string; code: string }[] => {
    const langLabels = langList.map(item => {
        return {
            ...item,
            label: `${item.name ?? ''} (${item.code})`
        }
    });
    return langLabels;
};

export { prepareLanguagesDropDown };
