const TAB_CONTENT_HIERARCHY_ACTIONS = [
  {
    actionName: 'Add hierarchy',
    action: 'add',
    icon: 'far fa-plus-square',
  },
  {
    actionName: 'Edit hierarchy',
    action: 'edit',
    icon: 'far fa-edit',
  },
  {
    actionName: 'Clear hierarchy',
    action: 'clear',
    icon: 'far fa-minus-square',
  },
];

export default TAB_CONTENT_HIERARCHY_ACTIONS;
