const REPORT_ACTIONS = [
  {
    actionName: 'Delete all questions',
    action: 'deleteAll',
    icon: 'fas fa-trash-alt',
  },
  {
    actionName: 'Delete selected questions',
    action: 'deleteSelected',
    icon: 'far fa-trash-alt',
  },
  {
    actionName: 'Select All Rows',
    action: 'select rows',
    icon: 'fas fa-duotone fa-check-double',
  },
  {
    actionName: 'Deselect All Rows',
    action: 'deselect rows',
    icon: 'far fa-times-square',
  },
  {
    actionName: 'Enable selected questions',
    action: 'enable',
    extension: 'docx',
    icon: 'fas fa-toggle-on',
  },
  {
    actionName: 'Disable selected questions',
    action: 'disable',
    icon: 'fas fa-toggle-off',
  },
];

export default REPORT_ACTIONS;
